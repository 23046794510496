////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {
  CheckSetSource,
  AvtV1ReportCheckDto,
  ReportCheckResultCondition,
  ReportCheckType,
  AvtV1ReportDto,
  AvtV1ReportElementDataDto,
  AvtV1ReportFileDto,
  ReportHeadingDto,
  AvtV1ReportSectionDto
} from '../../clients/Classes';
import {ReportUI} from '../ReportUI';
import {Heading} from '../Heading';
import {Section} from '../Section';
import {Check, CheckResultType} from '../Check';
import {FileReport} from '../FileReport';
import {RevitElement} from '../RevitElement';
import {FileStructureTranslator} from './FileStructureTranslator';
import {AvtItemReportSummaryDto} from "../../clients/V2Classes";

export class ReportTranslator {
  static TranslateSummaryReport(apiReport: AvtItemReportSummaryDto): ReportUI {
    const apiSummary = apiReport.avtSummary!;
    const duration = (apiSummary.endTime!.getTime() - apiSummary.startTime!.getTime()) / 1000;
    const report = new ReportUI(
      apiSummary.summaryInfo!.id!,
      apiSummary.runDate!,
      duration,
      apiSummary.reportKey!,
      apiSummary.jobName!,
      apiSummary.jobRunId!
    );
    report.Models = [FileStructureTranslator.GetFile(apiReport.model!)];
    report.Files = [];

    report.TotalChecks = apiSummary.summaryInfo!.totalChecks!;
    report.PassingChecks = apiSummary.summaryInfo!.passChecks!;
    report.FailingChecks = apiSummary.summaryInfo!.failChecks!;
    report.ReportingChecks = apiSummary.summaryInfo!.countChecks!;
    report.NotRunChecks = apiSummary.summaryInfo!.skippedChecks!;
    report.ErrorChecks = apiSummary.summaryInfo!.errorChecks!;
    report.PassPercent = apiSummary.summaryInfo!.passPercent!;

    return report;
  }

  static GetFullReport(apiReport: AvtV1ReportDto): ReportUI {
    const uiReport = new ReportUI(apiReport.id!, apiReport.runDate!, (apiReport.endTime!.getTime() - apiReport.startTime!.getTime()) / 1000, apiReport.reportKey!, apiReport.jobName!, '');
    uiReport.ChecksetName = apiReport.checkSet?.name;
    uiReport.ChecksetPath = apiReport.checkSet!.source === CheckSetSource.S3
      ? `My Library->${apiReport.checkSet!.name}.xml`
      : apiReport.checkSet?.url;

    uiReport.Files = [];

    if (apiReport.fileResults != null) {
      for (const apiFile of apiReport.fileResults) {
        const file = this.TranslateFileResult(apiFile);
        uiReport.Files.push(file);
      }
    }

    if (apiReport.models != null) {
      uiReport.Models = apiReport.models.map(m => FileStructureTranslator.GetFile(m));
    }

    this.SetReportOverallValues(uiReport);

    return uiReport;
  }

  private static TranslateFileResult(apiFile: AvtV1ReportFileDto): FileReport {
    const fileReport = new FileReport(FileStructureTranslator.GetFile(apiFile.model!));
    fileReport.Headings = [];

    if (apiFile.headings != null) {
      for (const apiHeading of apiFile.headings) {
        const heading = this.TranslateHeading(apiHeading);
        fileReport.Headings.push(heading);
      }
    }

    return fileReport;
  }

  private static TranslateHeading(apiHeading: ReportHeadingDto): Heading {
    const heading = new Heading(apiHeading.id!, apiHeading.headerValue!);
    this.SetSummaryInfo(apiHeading, heading);

    heading.SubItems = [];
    if (apiHeading.sections != null) {
      for (const apiSection of apiHeading.sections) {
        const section = this.TranslateSection(apiSection);
        heading.SubItems.push(section);
      }
    }

    return heading;
  }

  private static TranslateSection(apiSection: AvtV1ReportSectionDto): Section {
    const section = new Section(apiSection.id!, apiSection.title!, apiSection.name!, apiSection.description!);
    this.SetSummaryInfo(apiSection, section);

    section.SubItems = [];
    if (apiSection.subSections != null) {
      for (const apiSubSection of apiSection.subSections) {
        const subsection = this.TranslateSection(apiSubSection);
        section.SubItems.push(subsection);
      }
    }
    if (apiSection.checks != null) {
      for (const apiCheck of apiSection.checks) {
        const check = this.TranslateCheck(apiCheck);
        section.SubItems.push(check);
      }
    }

    return section;
  }

  private static SetSummaryInfo(apiItem: AvtV1ReportSectionDto | ReportHeadingDto, item: Heading | Section): void {
    item.TotalChecks = apiItem.totalChecks!;
    item.PassingChecks = apiItem.passChecks!;
    item.FailingChecks = apiItem.failChecks!;
    item.PassPercent = apiItem.passPercent!;
    item.ReportingChecks = apiItem.countChecks!;
    item.NotRunChecks = apiItem.skippedChecks!;
    item.ErrorChecks = apiItem.errorChecks!;
  }

  private static TranslateCheck(apiCheck: AvtV1ReportCheckDto): Check {
    const check = new Check(apiCheck.id!, apiCheck.name!, apiCheck.description!);
    check.ShowCountColumn = apiCheck.checkResult!.showCountColumn!;

    if (!apiCheck.checkResult!.checkWasRun) {
      check.Result = CheckResultType.NotRun;
    } else if (apiCheck.checkResult!.errorMessage != null && apiCheck.checkResult!.errorMessage !== '') {
      check.Result = CheckResultType.Error;
    } else if (this.IsReportingType(apiCheck)) {
      check.Result = CheckResultType.Report;
    } else {
      check.Result = apiCheck.checkResult!.listItems != null && apiCheck.checkResult!.listItems.length > 0
        ? CheckResultType.Fail
        : CheckResultType.Pass;
    }
    check.Count = 0;
    if (apiCheck.checkResult!.listItems != null) {
      for (const listItem of apiCheck.checkResult!.listItems) {
        check.Count += listItem.itemsCount!;
      }
    }

    check.ResultMessage = apiCheck.checkResult!.resultCustomValue;
    check.FailureMessage = apiCheck.failureMessage;
    check.ErrorMessage = apiCheck.checkResult!.errorMessage;

    check.ShowTotalCount = apiCheck.resultCondition !== ReportCheckResultCondition.FailNoElements
      && apiCheck.checkType !== ReportCheckType.FileName
      && (apiCheck.checkResult!.resultCustomValue == null || apiCheck.checkResult!.resultCustomValue === '');

    check.ShowList = (apiCheck.resultCondition === ReportCheckResultCondition.CountAndList
        || apiCheck.resultCondition === ReportCheckResultCondition.FailMatchingElements)
      && apiCheck.checkResult!.listItems != null
      && apiCheck.checkResult!.listItems.length > 0;

    check.ShowValues = apiCheck.checkResult!.showValues!;

    check.Elements = [];

    if (apiCheck.checkResult!.listItems != null) {
      for (const item of apiCheck.checkResult!.listItems) {
        const element = this.TranslateElement(item);
        check.Elements.push(element);
      }
    }

    return check;
  }

  private static TranslateElement(apiElement: AvtV1ReportElementDataDto): RevitElement {
    const element = new RevitElement(apiElement.id!.toString(), apiElement.name!);
    element.Category = apiElement.categoryName;
    element.Family = apiElement.familyName;
    element.Type = apiElement.typeName;
    element.Value = apiElement.value;
    element.Count = apiElement.itemsCount!;

    return element;
  }

  private static IsReportingType(apiCheck: AvtV1ReportCheckDto): boolean {
    switch (apiCheck.checkType) {
      case ReportCheckType.Custom:
        return apiCheck.resultCondition === ReportCheckResultCondition.CountAndList
          || apiCheck.resultCondition === ReportCheckResultCondition.CountOnly;
      case ReportCheckType.FileName:
        return false;
      default:
        return true;
    }
  }

  private static SetReportOverallValues(report: ReportUI): void {
    let totalChecks = 0;
    let passChecks = 0;
    let failChecks = 0;
    let countChecks = 0;
    let skippedChecks = 0;
    let errorChecks = 0;

    for (const file of report.Files) {
      let totalChecksFile = 0;
      let passChecksFile = 0;
      let failChecksFile = 0;
      let countChecksFile = 0;
      let skippedChecksFile = 0;
      let errorChecksFile = 0;

      for (const heading of file.Headings) {
        totalChecksFile += heading.TotalChecks;
        passChecksFile += heading.PassingChecks;
        failChecksFile += heading.FailingChecks;
        countChecksFile += heading.ReportingChecks;
        skippedChecksFile += heading.NotRunChecks;
        errorChecksFile += heading.ErrorChecks;
      }

      file.TotalChecks = totalChecksFile;
      file.PassingChecks = passChecksFile;
      file.FailingChecks = failChecksFile;
      file.ReportingChecks = countChecksFile;
      file.NotRunChecks = skippedChecksFile;
      file.ErrorChecks = errorChecksFile;

      file.PassPercent = file.PassingChecks / (file.PassingChecks + file.FailingChecks);

      totalChecks += file.TotalChecks;
      passChecks += file.PassingChecks;
      failChecks += file.FailingChecks;
      countChecks += file.ReportingChecks;
      skippedChecks += file.NotRunChecks;
      errorChecks += file.ErrorChecks;
    }

    report.TotalChecks = totalChecks;
    report.PassingChecks = passChecks;
    report.FailingChecks = failChecks;
    report.ReportingChecks = countChecks;
    report.NotRunChecks = skippedChecks;
    report.ErrorChecks = errorChecks;

    report.PassPercent = (report.PassingChecks / (report.PassingChecks + report.FailingChecks)) * 100;
  }
}
