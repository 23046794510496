////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import { FileDestinationNamingType } from "../clients/Classes";
import { Constants } from "../Constants";

export class ConvertFileDestinationNamingType {
    public static Convert(value: FileDestinationNamingType | undefined): string {
        switch (value) {
            case FileDestinationNamingType.None:
            case undefined:
                return Constants.NoneString;
            case FileDestinationNamingType.Overwrite:
                return 'Overwrite Existing Files';
            case FileDestinationNamingType.AppendTimestamp:
                return 'Append Time Stamp';
        }
    }
}