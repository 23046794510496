////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, { useState } from 'react';
import { ListToString, StringToList } from "../Utility";
import TextInput from "@adsk/alloy-react-text-input";

const IdInput = ({ ids, onChange }: { ids: string[], onChange: (newIds: string[]) => void }) => {
    const [idString, setIdString] = useState(ids.length === 0 ? '' : ListToString(ids));

    function sendUpdate(): void {
        if (onChange) {
            onChange(idString == null ? [] : StringToList(idString));
        }
    }

    return (
        <TextInput placeholder={'Enter comma delimited ids...'}
                   value={idString}
                   onChange={v => setIdString(v.target.value)}
                   onBlur={sendUpdate} />
    );
};

export default IdInput;
