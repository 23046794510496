////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React from 'react';
import Selector from "./Selector";
import {DefaultItem} from "@adsk/alloy-react-dropdown";
import {FilterItemData} from "../dataModel/FilterItemData";

const FilterItem = (
  {
    item,
    selected,
    onSelectionChange
  }: {
    item: FilterItemData,
    selected: DefaultItem | null | undefined,
    onSelectionChange?: (selected: DefaultItem | null | undefined) => void
  }) => {
  function filterChanged(newSelected: DefaultItem | null | undefined) {
    item.selected = newSelected;
    if (onSelectionChange) {
      onSelectionChange(newSelected);
    }
  }

  return (
    <div>
      <p>{item.title}</p>
      <Selector
        selected={selected}
        items={item.options}
        onSelectionChange={s => filterChanged(s)}/>
    </div>
  );
};

export default FilterItem;