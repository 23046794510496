////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import { WeekdaySetting } from './WeekdaySetting';
import {
    Days,
    RecurrenceDayTypes,
    RecurrenceEndType,
    RecurrenceMonthTypes,
    RecurrencePeriod,
    WeekOfMonthOptions
} from './Enums';

export class RecurrenceSettings {
    Recurrence: RecurrencePeriod | undefined;
    StartDate: Date;
    EndDate: Date | undefined;
    MaxRuns: number;
    RepeatPeriod: number;
    EndType: RecurrenceEndType;
    DayType: RecurrenceDayTypes;
    WeekdaySettings: WeekdaySetting[];
    DayOfMonth: number;
    MonthlyRecurrence: RecurrenceMonthTypes;
    WeekOfMonth: WeekOfMonthOptions;
    Day: Days;

    constructor(start: Date, maxRuns: number) {
        this.StartDate = start;
        this.MaxRuns = maxRuns;
        this.EndType = 'Occurrences';
        this.Recurrence = 'Daily';
        this.RepeatPeriod = 1;
        this.DayType = 'Numbered';
        this.DayOfMonth = 1;
        this.MonthlyRecurrence = 'NumberedDay';
        this.WeekOfMonth = 'first';
        this.Day = 'Monday';
        this.WeekdaySettings = [
            { Name: 'Sunday', Checked: false, Number: 7 },
            { Name: 'Monday', Checked: true, Number: 1 },
            { Name: 'Tuesday', Checked: false, Number: 2 },
            { Name: 'Wednesday', Checked: false, Number: 3 },
            { Name: 'Thursday', Checked: false, Number: 4 },
            { Name: 'Friday', Checked: false, Number: 5 },
            { Name: 'Saturday', Checked: false, Number: 6 },
        ];
    }
}
