////////////////////////////////////////////////////////////////////////////////
//                                                                             /
//                                                                             /
//  (C) Copyright 2024 Autodesk, Inc. All rights reserved.                     /
//                                                                             /
//                       ****  CONFIDENTIAL MATERIAL  ****                     /
//                                                                             /
//  The information contained herein is confidential, proprietary to           /
//  Autodesk, Inc., and considered a trade secret.  Use of this information    /
//  by anyone other than authorized employees of Autodesk, Inc. is granted     /
//  only under a written nondisclosure agreement, expressly prescribing        /
//  the scope and manner of such use.                                          /
//                                                                             /
////////////////////////////////////////////////////////////////////////////////

import {ServiceBase} from "./ServiceBase";
import {ProjectUI} from "../dataModel/ProjectUI";
import {ProjectNameLoadBase} from "../dataModel/ProjectNameLoadBase";
import {StorageType} from "../clients/Classes";
import {GetErrorMessage} from "../Utility";
import {ProjectService} from "./ProjectService";
import {ProjectWiseService} from "./ProjectWiseService";
import {ReportUI} from "../dataModel/ReportUI";
import {Task} from "../dataModel/Task";

export class NameRetrievalService extends ServiceBase {
  private cachedProjects: (ProjectUI)[] = [];

  private readonly projectService: ProjectService;
  private readonly projectWiseService: ProjectWiseService;

  constructor(projectService: ProjectService, projectWiseService: ProjectWiseService) {
    super();

    this.projectService = projectService;
    this.projectWiseService = projectWiseService;
  }

  GetProjectAndHubNames(baseObjects: ProjectNameLoadBase[]): Promise<void> {
    const projectIds: { storage: StorageType, hubId: string | undefined, projectId: string }[] = [];
    let needsFullLoad = false;
    baseObjects.forEach(baseObject => {
      if (baseObject instanceof Task && baseObject.ProjectName != null && baseObject.HubName != null) {
        return;
      }

      baseObject.LoadingProjectName = true;
      if (baseObject.ProjectId == null) {
        needsFullLoad = true;
        return;
      }

      const existing = projectIds
        .find(p => p.projectId === baseObject.ProjectId);

      if (existing == null) {
        projectIds.push({
          storage: baseObject.HubId == null ? StorageType.ProjectWise : StorageType.Forge,
          hubId: baseObject.HubId,
          projectId: baseObject.ProjectId
        });
      }
    });

    if (needsFullLoad) {
      return this.TryPromiseWithCatch(() =>
        this.PopulateCacheFull()
          .then(() => {
            baseObjects.forEach(b => {
              const project = this.FindProjectInCache(b.ProjectId!);
              if (project == null) {
                b.LoadErrorDetail = `Could not get hub and project name, project was not found.`;
                b.HasLoadError = true;
                b.HubName = '-Unknown-';
                b.ProjectName = '-Unknown-';
                b.LoadingProjectName = false;
              } else {
                b.LoadingProjectName = false;
                b.HubName = project.HubName;
                b.ProjectName = project.Name;
              }
            });
          })
      );
    } else {
      const promises = projectIds.map(ids => {
        return this.TryPromiseWithCatch(() =>
          this.PopulateCacheIndividual(ids.hubId, ids.projectId)
            .then(() => {
              const project = this.FindProjectInCache(ids.projectId);
              baseObjects.forEach(b => {
                if (b.ProjectId !== project?.Id) {
                  return;
                }
                b.LoadingProjectName = false;
                b.HubName = project?.HubName;
                b.ProjectName = project?.Name;
              });
            })
            .catch(error => {
              console.error(error);
              baseObjects.forEach(b => {
                if (b.ProjectId !== ids.projectId) {
                  return;
                }
                b.LoadingProjectName = false;
                b.LoadErrorDetail = GetErrorMessage(error, 'Get Hub and Project Name');
                b.HasLoadError = true;
                b.HubName = '-Unknown-';
                b.ProjectName = '-Unknown-';
              });
            })
        );
      });

      return Promise.all(promises).then();
    }
  }

  CheckModelPaths(report: ReportUI): Promise<ProjectUI | undefined> {
    // Make sure we have models
    if (report.Models.length <= 0) {
      return Promise.resolve(undefined);
    }

    // If we have it cached use that
    const project = this.cachedProjects.find(p => p.Id === report.Models[0].ProjectId);
    if (project != null) {
      return Promise.resolve(project);
    }

    // Not cached, try loading an individual project
    const hubId = report.Models[0].HubId;
    const projectId = report.Models[0].ProjectId;

    if (hubId != null && projectId != null) {
      return this.PopulateCacheIndividual(hubId, projectId)
        .then(() => this.FindProjectInCache(projectId))
        .catch(() => undefined);
    }

    // Something was not set, try loading all projects and finding it in the list
    return this.PopulateCacheFull()
      .then(() => {
        const loadedProject = this.FindProjectInCache(projectId);

        // Populate if it was in the loaded set or unknown if not.
        if (loadedProject == null) {
          return undefined;
        } else {
          return loadedProject;
        }
      })
      .catch(() => undefined);
  }

  private PopulateCacheFull(): Promise<void> {
    const promises: Promise<void>[] = [];
    promises.push(this.projectService.GetProjects()
      .then(projects => {
          projects.forEach(p => {
            const existing = this.FindProjectInCache(p.Id);
            if (existing != null) {
              return;
            }
            this.cachedProjects.push(p);
          });
        }
      ));
    return this.TryPromiseWithCatch(() => Promise.all(promises).then());
  }

  private PopulateCacheIndividual(hubId: string | undefined, projectId: string): Promise<void> {
    const existing = this.FindProjectInCache(projectId);
    if (existing != null) {
      return Promise.resolve();
    }

    return this.TryPromiseWithCatch(() =>
      this.projectService.GetProject(hubId!, projectId)
        .then(p => {
          this.cachedProjects.push(p);
        })
    );

  }

  private FindProjectInCache(id: string): ProjectUI | undefined {
    return this.cachedProjects.find(p => p.Id === id);
  }
}