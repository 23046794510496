////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import { ReportSummarizable } from "../dataModel/ReportSummarizable";
import { GetResultClass } from "../UiUtility";
import {ReactElement} from "react";
import {OverflowTooltip} from "@adsk/alloy-react-tooltip";

export class ConvertSummary {
    public static Convert(value: ReportSummarizable): ReactElement {
        const hasPassFail = value.PassingChecks > 0 || value.FailingChecks > 0;
        const resultClass = GetResultClass(value.PassPercent);

        return (
            <OverflowTooltip>
                {value.TotalChecks} Checks
                {hasPassFail && <>, {value.PassingChecks} <span
                    className={resultClass}>({Math.round(value.PassPercent)}%)</span> Pass</>}
                {hasPassFail && `, ${value.FailingChecks} Fail`}
                {value.ReportingChecks > 0 && `, ${value.ReportingChecks} Count/List`}
                {value.NotRunChecks > 0 && `, ${value.NotRunChecks} Not Run`}
                {value.ErrorChecks > 0 && <>, <span className={'errorResult'}>{value.ErrorChecks} Error</span></>}
            </OverflowTooltip>
        );
    }
}