////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {Task} from '../../dataModel/Task';
import {FilterItemData} from '../../dataModel/FilterItemData';
import {JobDataEntry} from '../../clients/Classes';

export class TasksState {
  tasks: Task[];
  filterOpen: boolean;
  filterOptions: FilterItemData[];
  filteredTasks: Task[];

  loading = false;

  public loadingMoreData = false;
  public hasMoreData = false;

  loadingUsageData = false;
  usageDetail: JobDataEntry | undefined;
  showUsageDetail = false;
  public canCancelLoad = false;

  constructor() {
    this.tasks = [];
    this.filterOpen = false;
    this.filteredTasks = [];

    this.filterOptions = [];
  }
}
