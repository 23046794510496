////////////////////////////////////////////////////////////////////////////////
//                                                                             /
//                                                                             /
//  (C) Copyright 2024 Autodesk, Inc. All rights reserved.                     /
//                                                                             /
//                       ****  CONFIDENTIAL MATERIAL  ****                     /
//                                                                             /
//  The information contained herein is confidential, proprietary to           /
//  Autodesk, Inc., and considered a trade secret.  Use of this information    /
//  by anyone other than authorized employees of Autodesk, Inc. is granted     /
//  only under a written nondisclosure agreement, expressly prescribing        /
//  the scope and manner of such use.                                          /
//                                                                             /
////////////////////////////////////////////////////////////////////////////////

import {ProjectWiseCredential} from "../../clients/Classes";
import {ProjectWiseConfigurationUI} from "../../dataModel/ProjectWiseConfigurationUI";
import {DefaultItem} from "@adsk/alloy-react-dropdown";

export class ProjectWiseSettingsState {
  Credentials: ProjectWiseCredential[];
  Configurations: ProjectWiseConfigurationUI[];

  EditingCredential: ProjectWiseCredential | undefined;
  EditingConfiguration: ProjectWiseConfigurationUI | undefined;
  EditPassword: string | undefined;

  IsEditingCredential: boolean;
  IsEditingConfiguration: boolean;
  EditIsNew: boolean;

  LoadingCredentials: boolean;
  LoadingConfigurations: boolean;

  HasMoreCredentials: boolean;
  HasMoreConfigurations: boolean;

  CanCancelCredentials: boolean;
  CanCancelConfigurations: boolean;

  CredentialSelectOptions: DefaultItem[];
  SelectedCredential: DefaultItem | undefined;

  constructor() {
    this.LoadingCredentials = false;
    this.LoadingConfigurations = false;
    this.HasMoreConfigurations = false;
    this.HasMoreCredentials = false;
    this.CanCancelConfigurations = false;
    this.CanCancelCredentials = false;
    this.IsEditingCredential = false;
    this.IsEditingConfiguration = false;
    this.EditIsNew = true;
    this.Credentials = [];
    this.Configurations = [];
    this.CredentialSelectOptions = [];
  }
}