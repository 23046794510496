////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, {useState} from 'react';
import {
  ContentScroller,
  ContentWrapper,
  FlexColumn
} from '../CommonStyledComponents';
import {SETTINGS_TAB_IDS, SETTINGS_TAB_TITLES, SETTINGS_TABS} from "../Constants";
import Theme from "@adsk/alloy-react-theme";
import ProjectWiseSettings from "./ProjectWiseSettings";
import WebhookSettings from "./WebhookSettings";
import Tabs, {Tab} from "@adsk/alloy-react-tabs";
import {IsTabAvailableToUser} from "../Utility";
import {ClientProvider} from "../clients/ClientProvider";

const authService = ClientProvider.AuthService;

const SettingsDetail = () => {
  const [selectedTab, setSelectedTab] = useState(SETTINGS_TAB_IDS[SETTINGS_TABS.WEBHOOKS]);

  return (
    <ContentScroller>
      <ContentWrapper>
        <FlexColumn>
          <h1 style={Theme.typography.heading1}>Settings</h1>
          <Tabs active={selectedTab}
                style={{flex: 1, display: 'flex', flexDirection: "column", overflow: 'auto'}}
                onChange={tab => setSelectedTab(tab)}>
            {Object.keys(SETTINGS_TABS).map(k => {
              return !IsTabAvailableToUser(k, authService.CurrentUser) ? undefined : (
                <Tab label={SETTINGS_TAB_TITLES[k]}
                     tab={SETTINGS_TAB_IDS[k]}
                     key={SETTINGS_TAB_IDS[k]}
                     style={{flex: 1, display: 'flex', flexDirection: 'column', overflow: 'auto'}}>
                  <FlexColumn style={{paddingTop: '1em'}}>
                    {selectedTab === SETTINGS_TAB_IDS[SETTINGS_TABS.WEBHOOKS] && <WebhookSettings/>}
                    {selectedTab === SETTINGS_TAB_IDS[SETTINGS_TABS.PROJECTWISE] && <ProjectWiseSettings/>}
                  </FlexColumn>
                </Tab>)
            })}
          </Tabs>
        </FlexColumn>
      </ContentWrapper>
    </ContentScroller>
  );
};

export default SettingsDetail;
