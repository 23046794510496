////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {ReportDetailState} from '../states/ReportDetailState';
import {ReportDetailActions} from '../../Enums';

export function reducer(state: ReportDetailState, action: { type: ReportDetailActions, payload: any }): ReportDetailState {
    switch (action.type) {
        case ReportDetailActions.loadingModelPath:
            return {...state, LoadingModelPath: action.payload};
        case ReportDetailActions.multipleActions:
            return {...state, ...action.payload};
        case ReportDetailActions.loading:
            return {...state, Loading: action.payload};
        case ReportDetailActions.report:
            return {...state, Report: action.payload};
    }
}