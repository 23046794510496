////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {FlexRow} from "./CommonStyledComponents";
import {Constants} from "./Constants";
import React, {ReactElement} from "react";
import {InfoPopover, OverflowTooltip} from "@adsk/alloy-react-tooltip";
import ProgressRing from "@adsk/alloy-react-progress-ring";
import {ProjectNameLoadBase} from "./dataModel/ProjectNameLoadBase";

export function GetListCell(values: any[]): ReactElement {
  const finalValues = values.filter(v => v.Name != null && v.Name.toLowerCase() !== '');
  return (
    <OverflowTooltip>
      {finalValues.length === 0 && Constants.NoneString}
      {finalValues.length === 1 && finalValues[0].Name}
      {finalValues.length > 1 &&
          <FlexRow>
              <OverflowTooltip
                  style={{
                    fontStyle: 'italic',
                    flex: '1'
                  }}>{Constants.MultipleSelectionString}</OverflowTooltip>
              <InfoPopover message={finalValues.map(f => f.Name).join(', ')}/>
          </FlexRow>
      }
    </OverflowTooltip>
  )
}

export function getLoadingCell(object: ProjectNameLoadBase, value: any): ReactElement {
  return (
    <>
      {
        !object.LoadingProjectName &&
          <OverflowTooltip>{value}</OverflowTooltip>
      }
      {
        object.LoadingProjectName &&
          <ProgressRing size={'xsmall'}/>
      }
    </>
  )
}

export function GetResultClass(passPercent: number): string {
  return passPercent < 70
    ? 'failResult'
    : passPercent < 90
      ? 'warnResult'
      : 'passResult';
}