////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

export class UsageDataItem {
    Name: string;
    ID: string;
    ItemType: string;
    JobRuns: number;
    CheckCount: number;
    RunTimeSeconds: number;
    RevitVersion: string | undefined;
    Children: UsageDataItem[];

    constructor(
        id: string,
        name: string,
        itemType: string,
        jobRuns: number,
        checkCount: number,
        runTimeSeconds: number
    ) {
        this.ID = id;
        this.Name = name;
        this.ItemType = itemType;
        this.JobRuns = jobRuns;
        this.CheckCount = checkCount;
        this.RunTimeSeconds = runTimeSeconds;
        this.Children = [];
    }
}
