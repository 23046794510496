////////////////////////////////////////////////////////////////////////////////
//                                                                             /
//                                                                             /
//  (C) Copyright 2024 Autodesk, Inc. All rights reserved.                     /
//                                                                             /
//                       ****  CONFIDENTIAL MATERIAL  ****                     /
//                                                                             /
//  The information contained herein is confidential, proprietary to           /
//  Autodesk, Inc., and considered a trade secret.  Use of this information    /
//  by anyone other than authorized employees of Autodesk, Inc. is granted     /
//  only under a written nondisclosure agreement, expressly prescribing        /
//  the scope and manner of such use.                                          /
//                                                                             /
////////////////////////////////////////////////////////////////////////////////

import React, {ReactElement} from 'react';
import {ProjectWiseCredential} from "../clients/Classes";
import {
  CenteringContainer,
  EllipsisCell,
  FlexColumn,
  FlexColumnCentered, FlexRow,
  FlexRowCentered
} from "../CommonStyledComponents";
import ProgressRing from "@adsk/alloy-react-progress-ring";
import Table from "./Table";
import {BasicButton, LinkButton} from "@adsk/alloy-react-button";
import Theme from "@adsk/alloy-react-theme";
import {LoadMoreDataRow} from "@adsk/alloy-react-table";
import {ProjectWiseActionTypes} from "../Enums";
import Tooltip from "@adsk/alloy-react-tooltip";
import {DocumentTwoIcon, PencilIcon} from "@adsk/alloy-react-icon";
import {ActionButton} from "@adsk/alloy-react-menu";

const ProjectWiseCredentialsList = (
  {
    credentials,
    loading,
    hasMoreData,
    canCancelLoad,
    onLoadMore,
    onLoadCancel,
    onAction
  }: {
    credentials: ProjectWiseCredential[],
    loading: boolean,
    hasMoreData: boolean,
    canCancelLoad: boolean,
    onLoadMore?: (loadAll: boolean) => void,
    onLoadCancel?: () => void,
    onAction?: (action: ProjectWiseActionTypes, config: ProjectWiseCredential) => void
  }) => {

  function loadMoreClick(loadAll: boolean): void {
    if (onLoadMore) {
      onLoadMore(loadAll);
    }
  }

  function renderActionCell(credential: ProjectWiseCredential): ReactElement {
    return <FlexRow>
      <Tooltip content={'Edit this Credential'}>
        <BasicButton onClick={() => handleAction('edit', credential)}>
          <PencilIcon/>
        </BasicButton>
      </Tooltip>
      <Tooltip content={'Duplicate this Configuration'}>
        <BasicButton onClick={() => handleAction('duplicate', credential)}>
          <DocumentTwoIcon/>
        </BasicButton>
      </Tooltip>
      <ActionButton style={{borderWidth: 0}}
                    horizontal={false}
                    options={[
                      {key: 'delete', label: 'Remove'},
                    ]}
                    onChange={e => handleAction(e[0], credential)}/>
    </FlexRow>
  }

  function handleAction(action: ProjectWiseActionTypes, credential: ProjectWiseCredential): void {
    if (onAction) {
      onAction(action, credential);
    }
  }

  return loading
    ? (
      <CenteringContainer>
        <FlexColumnCentered>
          <ProgressRing size={'large'}/>
          {
            canCancelLoad &&
            <LinkButton onClick={() => {
              if (onLoadCancel) {
                onLoadCancel();
              }
            }}>Cancel</LinkButton>
          }
        </FlexColumnCentered>
      </CenteringContainer>
    ) : (
      <FlexColumn style={{overflow: 'auto'}}>
        <Table<ProjectWiseCredential>
          data={credentials}
          columns={[
            {
              id: 'Key',
              accessorFn: t => t.key,
              header: () => 'Key',
              cell: d => <EllipsisCell>{d.row.original.key}</EllipsisCell>
            },
            {
              id: 'Username',
              accessorFn: t => t.username,
              header: () => 'Username',
              cell: d => <EllipsisCell>{d.row.original.username}</EllipsisCell>
            },
            {
              id: 'actions',
              size: 100,
              header: () => 'Actions',
              cell: d => renderActionCell(d.row.original)
            },
          ]}
          renderLastRow={() => hasMoreData &&
            <LoadMoreDataRow
              isLoading={loading}
              onLoad={async () => loadMoreClick(false)}
              renderLoadMore={() =>
                <FlexRowCentered>
                  <LinkButton onClick={() => loadMoreClick(false)}>
                    <span style={Theme.typography.bodySmall}>Load more</span>
                  </LinkButton>
                  <LinkButton onClick={() => loadMoreClick(true)} style={{marginLeft: '1em'}}>
                    <span style={Theme.typography.bodySmall}>Load all</span>
                  </LinkButton>
                </FlexRowCentered>
              }/>
          }/>
      </FlexColumn>
    );
};

export default ProjectWiseCredentialsList;