////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

export abstract class ProjectNameLoadBase {
    LoadingProjectName = false;
    HubName: string | undefined;
    ProjectName: string | undefined;

    LoadErrorDetail: string | undefined;
    HasLoadError = false;

    abstract HubId: string | undefined;
    abstract ProjectId: string | undefined;
}
