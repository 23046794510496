////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {ProjectUI} from '../dataModel/ProjectUI';
import {FileStructureTranslator} from '../dataModel/translators/FileStructureTranslator';
import {GetDirectoryPathResult} from '../dataModel/GetDirectoryPathResult';
import {DirectoryUI} from '../dataModel/DirectoryUI';
import {FindProjectItemRecursive} from '../Utility';
import {FileUI} from '../dataModel/FileUI';
import {ClientProvider} from '../clients/ClientProvider';
import {ServiceBase} from "./ServiceBase";
import {IV2Client} from "../clients/V2Client";
import {Directory360, File360, StorageType} from "../clients/Classes";

export class ModelService extends ServiceBase {
  private _v2client: IV2Client = ClientProvider.V2Client;

  GetRootDirectories(project: ProjectUI): Promise<boolean> {
    if (project.RootFolderArray != null && project.RootFolderArray.length > 0) {
      return Promise.resolve(true);
    }

    return this.TryPromiseWithCatch(() =>
      this._v2client.listTopLevelDirectoriesAll(project.HubId, project.Id, false)
        .then(directories => {
          project.RootFolderArray = [];
          directories.forEach(directory => {
            const dir = FileStructureTranslator.GetDirectory(directory, true);
            dir.HubId = project.HubId;
            project.RootFolderArray.push(dir);
          });

          return true;
        })
    );
  }

  async PopulateToDirectories(project: ProjectUI, directoryIds: string[]): Promise<GetDirectoryPathResult[]> {
    const results: GetDirectoryPathResult[] = [];
    for (const id of directoryIds) {
      results.push(await this.PopulateToDirectory(project, id));
    }
    return results;
  }

  async PopulateToDirectory(project: ProjectUI, directoryId: string): Promise<GetDirectoryPathResult> {
    let currentDirectoryId: string = directoryId;
    let lastDirectory: DirectoryUI | null = null;
    const loaded: DirectoryUI[] = [];

    if (project.RootFolderArray.length === 0) {
      const gotRoot = await this.GetRootDirectories(project);
      if (!gotRoot) {
        throw Error('Failed to get root directories');
      }
    }

    while (true) {
      const existingLoaded = FindProjectItemRecursive(project, currentDirectoryId);
      let currentDirectory: DirectoryUI;
      if (existingLoaded != null && existingLoaded instanceof DirectoryUI) {
        currentDirectory = existingLoaded;
      } else {
        const currentDirectoryApi = await this._v2client.getDirectory(project.Id, currentDirectoryId);
        const isProjectFiles = currentDirectoryApi.parentId === project.RootDirectoryId;
        currentDirectory = FileStructureTranslator.GetDirectory(currentDirectoryApi, isProjectFiles);
      }

      currentDirectory.HubId = project.HubId;
      loaded.push(currentDirectory);
      if (lastDirectory != null) {
        lastDirectory.Parent = currentDirectory;
        if (currentDirectory.SubFolders.find(f => f.Id === lastDirectory?.Id) == null) {
          currentDirectory.SubFolders.push(lastDirectory);
        }
      }

      const existingRoot = project.RootFolderArray.find(f => f.Id === currentDirectory.Id);
      if (existingRoot != null) {
        return new GetDirectoryPathResult(true, loaded);
      }

      const rootParent = project.RootFolderArray.find(f => f.Id === currentDirectory.ParentId);
      if (rootParent != null || currentDirectory.ParentId === project.RootDirectoryId) {
        if (rootParent != null && rootParent.SubFolders.find(f => f.Id === currentDirectory.Id) == null) {
          rootParent?.SubFolders.push(currentDirectory);
        }
        currentDirectory.Parent = rootParent;
        return new GetDirectoryPathResult(true, loaded);
      }
      currentDirectoryId = currentDirectory.ParentId!;
      lastDirectory = currentDirectory;
    }
  }

  GetDirectoryContents(directory: DirectoryUI): Promise<boolean> {
    if (directory.AreItemsPopulated) {
      return Promise.resolve(true);
    }

    return this.TryPromiseWithCatch(() =>
      this.GetAllFileData(directory)
        .then(dto => {
          directory.SubFolders = [];
          directory.Models = [];
          dto.directories.forEach(d => {
            const dir = FileStructureTranslator.GetDirectory(d, false);
            dir.HubId = directory.HubId;
            dir.HubRegion = directory.HubRegion;
            const projectMatchId = d.projectId!.replace('b.', '');
            if (!dir.Name.includes(projectMatchId)) {
              dir.Parent = directory;
              directory.SubFolders.push(dir);
            }
          });
          directory.SubFolders.sort((x, y) => x.Name! > y.Name! ? 1 : -1)

          dto.files.forEach(f => {
            const file = FileStructureTranslator.GetFile(f);
            file.HubId = directory.HubId;
            file.HubRegion = directory.HubRegion;
            directory.Models.push(file);
          });
          directory.Models.sort((x, y) => x.Name! > y.Name! ? 1 : -1)

          directory.AreItemsPopulated = true;

          return true;
        })
    );
  }

  GetRecentModels(): Promise<FileUI[]> {
    return this.TryPromiseWithCatch(() =>
      this.GetRemainingDataFromPaginatedEndpointRecentFileResult(token => this._v2client.listRecentFiles(token, 30))
        .then(files => files.forgeFiles!.map(file => FileStructureTranslator.GetFile(file)))
    );
  }

  private async GetAllFileData(directory: DirectoryUI): Promise<{ files: File360[], directories: Directory360[] }> {
    const result = await this.GetRemainingDataFromPaginatedEndpointFileResult(t =>
      directory.StorageType === StorageType.Forge
        ? this._v2client.listFiles(directory.ProjectId, directory.Id, undefined, ['rvt'], false, undefined, t, 30)
        : this._v2client.listFiles2(directory.ProjectId, directory.Id, undefined, ['rvt'], false, undefined, t, 30, false, true, true)
    );

    return {files: result.files ?? [], directories: result.directories ?? []};
  }
}