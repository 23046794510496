////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {JobStatusType} from "../clients/Classes";

export class ConvertTaskStatus {
  public static Convert(value: JobStatusType, isOnPublish: boolean = false): string {
    const publishPassThroughs: JobStatusType[] = [JobStatusType.Paused, JobStatusType.Running, JobStatusType.PostProcessing];
    if (isOnPublish && !publishPassThroughs.includes(value)) {
      return 'Monitoring';
    }
    switch (value) {
      case JobStatusType.PartiallyCompleted:
        return 'Partially Complete';
      case JobStatusType.PostProcessing:
        return 'Post Processing';
      case JobStatusType.None:
        return 'None';
      case JobStatusType.Scheduled:
        return 'Scheduled';
      case JobStatusType.Running:
        return 'Running';
      case JobStatusType.Paused:
        return 'Paused';
      case JobStatusType.Completed:
        return 'Completed';
      case JobStatusType.Error:
        return 'Error';
    }

    return 'Unknown Status';
  }
}