////////////////////////////////////////////////////////////////////////////////
//                                                                             /
//                                                                             /
//  (C) Copyright 2024 Autodesk, Inc. All rights reserved.                     /
//                                                                             /
//                       ****  CONFIDENTIAL MATERIAL  ****                     /
//                                                                             /
//  The information contained herein is confidential, proprietary to           /
//  Autodesk, Inc., and considered a trade secret.  Use of this information    /
//  by anyone other than authorized employees of Autodesk, Inc. is granted     /
//  only under a written nondisclosure agreement, expressly prescribing        /
//  the scope and manner of such use.                                          /
//                                                                             /
////////////////////////////////////////////////////////////////////////////////

import {
  CreateProjectWiseConfigurationDto,
  CreateProjectWiseCredentialDto,
  ProjectWiseConfiguration,
  ProjectWiseConfigurationPaginatedResult,
  ProjectWiseCredential,
  ProjectWiseCredentialPaginatedResult,
  ProjectWiseCredentialWithSecret,
  UpdateProjectWiseConfigurationDto,
  UpdateProjectWiseCredentialDto
} from "../clients/Classes";
import {ClientProvider} from "../clients/ClientProvider";
import {ServiceBase} from "./ServiceBase";
import {CancellationToken} from "../dataModel/CancellationToken";
import {ProjectWiseConfigurationUI} from "../dataModel/ProjectWiseConfigurationUI";
import {ProjectWiseConfigurationTranslator} from "../dataModel/translators/ProjectWiseConfigurationTranslator";
import {IClient} from "../clients/Client";

export class ProjectWiseService extends ServiceBase {
  private _client: IClient = ClientProvider.Client;

  CreateCredential(key: string, username: string, password: string): Promise<ProjectWiseCredential> {
    const dto = new CreateProjectWiseCredentialDto({
      key: key,
      username: username,
      password: password
    });

    return this.TryPromiseWithCatch(() => this._client.createProjectWiseCredential(dto));
  }

  GetCredential(id: string): Promise<ProjectWiseCredential> {
    return this.TryPromiseWithCatch(() => this._client.getProjectWiseCredential(id));
  }

  GetCredentials(paginationToken?: string | null | undefined, limit?: number | undefined): Promise<ProjectWiseCredentialPaginatedResult> {
    return this.TryPromiseWithCatch(() =>
      this.GetResultsWithItemOrToEnd(
        t => this._client.listProjectWiseCredentials(t, limit),
        paginationToken)
        .then(r => new ProjectWiseCredentialPaginatedResult(r))
    );
  }

  GetRemainingCredentials(paginationToken?: string | null | undefined, limit?: number | undefined, cancelToken?: CancellationToken | undefined): Promise<ProjectWiseCredentialPaginatedResult> {
    return this.TryPromiseWithCatch(() =>
      this.GetRemainingDataFromPaginatedEndpoint(t =>
        this._client.listProjectWiseCredentials(t, limit), paginationToken, cancelToken)
        .then(r => new ProjectWiseCredentialPaginatedResult(r))
    );
  }

  GetCredentialWithSecret(credentialId: string): Promise<ProjectWiseCredentialWithSecret> {
    return this.TryPromiseWithCatch(() =>
      this._client.getProjectWiseCredentialSecret(credentialId)
    );
  }

  UpdateCredential(credentialId: string, key?: string, username?: string, password?: string): Promise<ProjectWiseCredential> {
    const dto = new UpdateProjectWiseCredentialDto({
      key: key,
      username: username,
      password: password
    });

    return this.TryPromiseWithCatch(() => this._client.updateProjectWiseCredential(credentialId, dto));
  }

  DeleteCredential(id: string): Promise<void> {
    return this.TryPromiseWithCatch(() => this._client.deleteProjectWiseCredential(id));
  }

  CreateConfiguration(
    repositoryId: string,
    serverAddress: string,
    repositoryName: string,
    credentialId: string
  ): Promise<ProjectWiseConfigurationUI> {
    const dto = new CreateProjectWiseConfigurationDto({
      repositoryId: repositoryId,
      repositoryName: repositoryName,
      credentialId: credentialId,
      serverAddress: serverAddress
    });

    return this.TryPromiseWithCatch(() =>
      this._client.createProjectWiseConfiguration(dto)
        .then(c => ProjectWiseConfigurationTranslator.TranslateConfiguration(c))
    );
  }

  GetConfiguration(id: string): Promise<ProjectWiseConfiguration> {
    return this.TryPromiseWithCatch(() =>
      this._client.getProjectWiseConfiguration(id)
    );
  }

  GetConfigurations(paginationToken?: string | null | undefined, limit?: number | undefined): Promise<ProjectWiseConfigurationPaginatedResult> {
    return this.TryPromiseWithCatch(() =>
      this.GetResultsWithItemOrToEnd(
        t => this._client.listProjectWiseConfigurations(t, limit),
        paginationToken)
        .then(r => new ProjectWiseConfigurationPaginatedResult(r))
    );
  }

  GetRemainingConfigurations(paginationToken?: string | null | undefined, limit?: number | undefined, cancelToken?: CancellationToken | undefined): Promise<ProjectWiseConfigurationPaginatedResult> {
    return this.TryPromiseWithCatch(() =>
      this.GetRemainingDataFromPaginatedEndpoint(t =>
        this._client.listProjectWiseConfigurations(t, limit), paginationToken, cancelToken)
        .then(r => new ProjectWiseConfigurationPaginatedResult(r))
    );
  }

  UpdateConfiguration(id: string, dto: UpdateProjectWiseConfigurationDto): Promise<ProjectWiseConfigurationUI> {
    return this.TryPromiseWithCatch(() =>
      this._client.updateProjectWiseConfiguration(id, dto)
        .then(c => ProjectWiseConfigurationTranslator.TranslateConfiguration(c))
    );
  }

  DeleteConfiguration(config: ProjectWiseConfigurationUI): Promise<void> {
    return this.TryPromiseWithCatch(() =>
      this._client.deleteProjectWiseConfiguration(config.ApiConfiguration.id!)
    );
  }
}