////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {RevitVersion} from '../clients/Classes';

export class ConvertRevitVersion {
  public static Convert(value: RevitVersion | undefined): string {
    switch (value) {
      case RevitVersion.Auto:
      case undefined:
        return 'Auto';
      case RevitVersion.V2018:
        return '2018';
      case RevitVersion.V2019:
        return '2019';
      case RevitVersion.V2020:
        return '2020';
      case RevitVersion.V2021:
        return '2021';
      case RevitVersion.V2022:
        return '2022';
      case RevitVersion.V2023:
        return '2023';
      case RevitVersion.V2024:
        return '2024';
      case RevitVersion.V2025:
        return '2025';
      default:
        return 'Auto';
    }
  }
}