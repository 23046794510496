////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, { useEffect, useState } from 'react';
import Selector from "./Selector";
import { DefaultItem } from "@adsk/alloy-react-dropdown";

const RunAtSelector = (
    { date, onTimeChange }
        :
        { date: Date, onTimeChange: (hours: number, minutes: number) => void }
) => {
    const items = [
        { value: '0', label: '12:00 AM' },
        { value: '0.5', label: '12:30 AM' },
        { value: '1', label: '1:00 AM' },
        { value: '1.5', label: '1:30 AM' },
        { value: '2', label: '2:00 AM' },
        { value: '2.5', label: '2:30 AM' },
        { value: '3', label: '3:00 AM' },
        { value: '3.5', label: '3:30 AM' },
        { value: '4', label: '4:00 AM' },
        { value: '4.5', label: '4:30 AM' },
        { value: '5', label: '5:00 AM' },
        { value: '5.5', label: '5:30 AM' },
        { value: '6', label: '6:00 AM' },
        { value: '6.5', label: '6:30 AM' },
        { value: '7', label: '7:00 AM' },
        { value: '7.5', label: '7:30 AM' },
        { value: '8', label: '8:00 AM' },
        { value: '8.5', label: '8:30 AM' },
        { value: '9', label: '9:00 AM' },
        { value: '9.5', label: '9:30 AM' },
        { value: '10', label: '10:00 AM' },
        { value: '10.5', label: '10:30 AM' },
        { value: '11', label: '11:00 AM' },
        { value: '11.5', label: '11:30 AM' },
        { value: '12', label: '12:00 PM' },
        { value: '12.5', label: '12:30 PM' },
        { value: '13', label: '1:00 PM' },
        { value: '13.5', label: '1:30 PM' },
        { value: '14', label: '2:00 PM' },
        { value: '14.5', label: '2:30 PM' },
        { value: '15', label: '3:00 PM' },
        { value: '15.5', label: '3:30 PM' },
        { value: '16', label: '4:00 PM' },
        { value: '16.5', label: '4:30 PM' },
        { value: '17', label: '5:00 PM' },
        { value: '17.5', label: '5:30 PM' },
        { value: '18', label: '6:00 PM' },
        { value: '18.5', label: '6:30 PM' },
        { value: '19', label: '7:00 PM' },
        { value: '19.5', label: '7:30 PM' },
        { value: '20', label: '8:00 PM' },
        { value: '20.5', label: '8:30 PM' },
        { value: '21', label: '9:00 PM' },
        { value: '21.5', label: '9:30 PM' },
        { value: '22', label: '10:00 PM' },
        { value: '22.5', label: '10:30 PM' },
        { value: '23', label: '11:00 PM' },
        { value: '23.5', label: '11:30 PM' },
    ];

    const [value, setValue] = useState('0');

    useEffect(() => {
        let selectedValue = '0';
        if (date != null) {
            let hours = date.getHours();
            const minutes = date.getMinutes();
            let roundMinutes = 0;
            if (minutes > 15) {
                if (minutes > 45) {
                    roundMinutes = 0;
                    hours++;
                } else {
                    roundMinutes = 30;
                }
            }
            selectedValue = `${hours}${roundMinutes === 0 ? '' : `.${roundMinutes}`}`;
        }

        setValue(selectedValue);
    }, [date]);

    function selectionChange(value: DefaultItem | null | undefined): void {
        if (value == null) {
            return;
        }
        setValue(value.value);

        if (onTimeChange) {
            if (value.value.includes('.')) {
                const valueSplit = value.value.split('.');
                onTimeChange(+valueSplit[0], 30);
            } else {
                onTimeChange(+value.value, 0);
            }
        }
    }

    return (
        <Selector
            selected={items.find(i => i.value === value) ?? items[0]}
            onSelectionChange={selectionChange}
            items={items} />
    );
};

export default RunAtSelector;