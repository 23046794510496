////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {
  CreateJobRequest,
  EditJobRequest,
  EditScheduleRequest,
  EmailPreferences,
  AvtV1ExportReportOptions,
  JobRun,
  JobScheduleType
} from "../clients/Classes";
import {Task} from "../dataModel/Task";
import {TaskTranslator} from "../dataModel/translators/TaskTranslator";
import {CronTranslator} from "../dataModel/translators/CronTranslator";
import {ClientProvider} from "../clients/ClientProvider";
import {ServiceBase} from "./ServiceBase";
import {JobPaginatedResult} from "../clients/V2Classes";
import {CancellationToken} from "../dataModel/CancellationToken";
import {IClient} from "../clients/Client";
import {IV2Client} from "../clients/V2Client";

export class TaskService extends ServiceBase {
  private _client: IClient = ClientProvider.Client;
  private _v2Client: IV2Client = ClientProvider.V2Client;

  GetTasks(
    paginationToken?: string | null | undefined,
    limit?: number | undefined,
    loadFilesAndFolders?: boolean,
    loadItems?: boolean
  ): Promise<JobPaginatedResult> {
    return this.TryPromiseWithCatch(() =>
      this.GetResultsWithItemOrToEnd(t => this._v2Client.listJobs(t, limit, loadFilesAndFolders, loadItems), paginationToken)
        .then(r => new JobPaginatedResult(r))
    );
  }

  GetRemainingTasks(
    paginationToken?: string | null | undefined,
    limit?: number | undefined,
    loadFilesAndFolders?: boolean,
    loadItems?: boolean,
    cancelToken?: CancellationToken | undefined
  ): Promise<JobPaginatedResult> {
    return this.TryPromiseWithCatch(() =>
      this.GetRemainingDataFromPaginatedEndpoint(
        token => this._v2Client.listJobs(token, limit, loadFilesAndFolders, loadItems), paginationToken, cancelToken)
        .then(jobs => new JobPaginatedResult(jobs))
    );
  }

  CreateTask(command: CreateJobRequest): Promise<Task> {
    return this.TryPromiseWithCatch(() =>
      this._client.createJob(command)
        .then(job => TaskTranslator.GetTask(job))
    );
  }

  GetTask(id: string): Promise<Task> {
    return this.TryPromiseWithCatch(() =>
      this._client.getJob(id)
        .then(job => TaskTranslator.GetTask(job))
    );
  }

  RunTask(id: string): Promise<JobRun> {
    return this.TryPromiseWithCatch(() =>
      this._client.startJob(id)
    );
  }

  GetTaskUpdate(original: Task, updated: Task): EditJobRequest {
    const scheduleNew = TaskTranslator.GetScheduleFromTask(updated);

    let scheduleEdit: EditScheduleRequest | undefined = undefined;

    let scheduleType: JobScheduleType = JobScheduleType.None;

    switch (updated.Trigger) {
      case 'OnceNow':
        scheduleType = JobScheduleType.OnceNow;
        break;
      case 'OnceLater':
        scheduleType = JobScheduleType.OnceLater;
        break;
      case 'Recurring':
        scheduleType = JobScheduleType.Recurring;
        break;
      case 'OnPublish':
        scheduleType = JobScheduleType.OnPublish;
        break;
    }

    if (scheduleNew != null) {
      scheduleEdit = new EditScheduleRequest({
        cronString: CronTranslator.GetCronString(updated.RecurrenceSettings!),
        startDate: scheduleNew.startDate,
        endDate: scheduleNew.endDate,
        clearEndDate: false,
        clearStartDate: false,
        maxRuns: scheduleNew.maxRuns
      });
    }

    let emailPreferences: EmailPreferences | undefined = undefined;
    let setEmailPreferences: boolean | undefined = undefined;
    if (original.EmailOnCompletion !== updated.EmailOnCompletion
      || original.AttachExportFiles !== updated.AttachExportFiles) {
      emailPreferences = TaskTranslator.GetEmailPreferences(updated);
      setEmailPreferences = emailPreferences.emailOnCompletion || emailPreferences.attachExportFiles;
    }

    let exportOptions: AvtV1ExportReportOptions | undefined = undefined;
    let setExportOptions: boolean | undefined = undefined;
    if (original.ExportExcel !== updated.ExportExcel
      || original.CombineExcel !== updated.CombineExcel
      || original.ExportHtml !== updated.ExportHtml
      || original.ExportLocation !== updated.ExportLocation
      || original.ExportLocationType !== updated.ExportLocationType
      || original.ExportDestinationNaming !== updated.ExportDestinationNaming
      || original.ExportLists !== updated.ExportLists) {
      exportOptions = TaskTranslator.GetExportOptions(updated);
      setExportOptions = exportOptions?.html || exportOptions?.excel;
    }

    const setRevitVersion = original.RevitVersion !== updated.RevitVersion;

    return new EditJobRequest({
      name: original.Name === updated.Name ? undefined : updated.Name,
      jobScheduleType: scheduleType,
      editSchedule: scheduleEdit,
      emailPreferences: setEmailPreferences === true ? emailPreferences : undefined,
      exportOptions: setExportOptions === true ? exportOptions : undefined,
      setEmailPreferences: setEmailPreferences,
      setExportOptions: setExportOptions,
      runOnceDateTime: scheduleType === JobScheduleType.OnceLater ? updated.StartDate! : undefined,
      revitVersion: setRevitVersion ? updated.RevitVersion : undefined
    });
  }

  UpdateTask(task: Task, update: EditJobRequest): Promise<Task> {
    return this.TryPromiseWithCatch(() =>
      this._client.editJob(task.Id!, update)
        .then(job => TaskTranslator.GetTask(job))
    );
  }

  DeleteTask(task: Task): Promise<boolean> {
    return this.TryPromiseWithCatch(() =>
      this._client.deleteJob(task.Id!).then(() => true)
    );
  }

  ToggleTaskPause(task: Task): Promise<boolean> {
    const command: EditJobRequest = new EditJobRequest({
      pauseJob: !task.IsPaused
    });

    return this.TryPromiseWithCatch(() =>
      this._client.editJob(task.Id!, command)
        .then(_job => {
          task.IsPaused = _job.schedule != null && _job.schedule.isPaused!;
          task.RawStatus = _job.status!;
          return true;
        })
    );
  }
}