////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {CustomerUI} from "../../dataModel/CustomerUI";

export class CustomersState {
  public customers: CustomerUI[];
  public filteredCustomers: CustomerUI[];
  public editingCustomer: CustomerUI | undefined;
  public showEditDialog = false;
  public showDeleteConfirm = false;
  public editHeader: string = 'Edit Customer';
  public loading: boolean = false;
  public loadingMoreData = false;
  public hasMoreData = false;
  public canCancelLoad = false;

  constructor() {
    this.customers = [];
    this.filteredCustomers = [];
  }
}