////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import { ExportReportLocationType } from "../clients/Classes";
import { Constants } from "../Constants";

export class ConvertExportLocationType {
    public static Convert(value: ExportReportLocationType | undefined): string {
        switch (value) {
            case ExportReportLocationType.None:
            case undefined:
                return Constants.NoneString;
            case ExportReportLocationType.ModelDirectory:
                return 'Same folder as model';
            case ExportReportLocationType.ModelSubdirectory:
                return 'Sub-folder in same folder as model';
            case ExportReportLocationType.OtherDirectory:
                return 'Single location for all exports';
        }
    }
}