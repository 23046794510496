////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, {useEffect, useState} from 'react';
import {TreeItem} from '../dataModel/TreeItem';
import {BIM360ItemBase} from '../dataModel/BIM360ItemBase';
import {ProjectUI} from '../dataModel/ProjectUI';
import {DirectoryUI} from '../dataModel/DirectoryUI';
import {IdType, TreeSelectedIds} from '@adsk/alloy-react-tree/es/types';
import {
  AreAnyBranchesUnloaded,
  FindProjectItemRecursive
} from '../Utility';
import ProgressNode from './ProgressNode';
import {FileUI} from '../dataModel/FileUI';
import TreeNodeFileContent from './TreeNodeFileContent';
import {BlueButton, CenteringContainer, FlexColumn, FlexRow, FlexRowCentered} from '../CommonStyledComponents';
import {Task} from '../dataModel/Task';
import {ModelService} from '../services/ModelService';
import {cascadeMultiSelection, ExpandButton, Tree, TREE_ACTIONS, TreeNode, useTree} from "@adsk/alloy-react-tree";
import ProgressRing from "@adsk/alloy-react-progress-ring";
import Theme from "@adsk/alloy-react-theme";
import Modal from "@adsk/alloy-react-modal";
import {CheckboxState} from "@adsk/alloy-react-checkbox";

const modelService = new ModelService();

let fullCheck = false;
let fullCheckFolder: DirectoryUI | undefined;

const FileStructureList = (
  {
    project,
    allowSelection = true,
    allowMultiSelection = true,
    allowAdd = true,
    cascadeSelection = false,
    foldersOnly = false,
    onSelectionChange,
    onExpandedChange,
    defaultSelection,
    defaultExpansion,
    task,
    onAdd,
    onError
  }: {
    project: ProjectUI | undefined,
    allowSelection?: boolean,
    allowMultiSelection?: boolean,
    allowAdd?: boolean,
    cascadeSelection?: boolean,
    foldersOnly?: boolean,
    onSelectionChange?: (currentSelection: { item: BIM360ItemBase, newIsSelected: boolean }[]) => void,
    onExpandedChange?: (expandedIds: string[]) => void,
    defaultSelection?: string[],
    defaultExpansion?: string[],
    task?: Task | undefined,
    onAdd?: (item: BIM360ItemBase, recursive: boolean) => void,
    onError?: (error: any, operation: string) => void
  }) => {
  const [selectedIds, setSelectedIds] = useState<TreeSelectedIds>({});
  const [expandedIds, setExpandedIds] = useState<string[]>(defaultExpansion ?? []);
  const [loadingIds, setLoadingIds] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [showLoadConfirm, setShowLoadConfirm] = useState(false);

  useEffect(() => {
    let isMounted = true;

    if (defaultSelection != null) {
      const newSelected = selectedIds;
      // Reset any existing keys based on if they are in the default selection
      for (let key in newSelected) {
        newSelected[key] = defaultSelection.includes(key);
      }
      // Make sure each selected is actually in the object
      defaultSelection.forEach(id => newSelected[id] = true);
      setSelectedIds(newSelected);
    }

    if (project == null) {
      dispatch({type: TREE_ACTIONS.regenerateTree, payload: {denormalizedTree: []}});
    } else {
      setLoading(true);
      modelService.GetRootDirectories(project)
        .then(() => {
          if (!isMounted) {
            return;
          }
          updateTaskItems(project.RootFolderArray);
          setLoading(false);
          const dta = project.RootFolderArray
            .map(i => new TreeItem<BIM360ItemBase>(i.Id, i));
          // @ts-ignore
          dispatch({type: TREE_ACTIONS.regenerateTree, payload: {denormalizedTree: dta}});
        })
        .catch(error => handleError(error, 'Get root directories'));
    }

    return () => {
      isMounted = false;
    };
  }, [project]);

  const expandNode = async (
    id: IdType,
    isExpanded: boolean,
    onExpand: (p: { isExpanded: boolean; id: IdType }) => void
  ): Promise<void> => {
    const treeItem = normalizedTree[id].original as TreeItem<BIM360ItemBase>;
    const item: BIM360ItemBase = treeItem.relatedObject;

    // This is the previous state so update state
    const newExpanded = !isExpanded;
    if (newExpanded) {
      loadNodeChildren(item)
        .then(() => {
          if (!(item instanceof DirectoryUI)) {
            return;
          }
          const items: BIM360ItemBase[] = foldersOnly ? item.SubFolders : item.SubItems;
          const newNodes = items.filter(i => normalizedTree[i.Id] == null);
          if (newNodes.length > 0) {
            dispatch({
              type: TREE_ACTIONS.addChildNodes,
              payload: {
                targetId: id,
                // @ts-ignore
                newChildNodes: newNodes.map(i => new TreeItem<BIM360ItemBase>(i.Id, i))
              }
            });
          }
        })
        .catch(er => handleError(er, 'Get sub-items'));
    }

    if (newExpanded) {
      if (!expandedIds.includes(item.Id)) {
        expandedIds.push(item.Id);
      }
    } else {
      const index = expandedIds.indexOf(item.Id);
      if (index > 0) {
        expandedIds.splice(index, 1);
      }
    }
    setExpandedIds(expandedIds);
    if (onExpandedChange) {
      onExpandedChange(expandedIds);
    }

    onExpand({
      isExpanded: !isExpanded,
      id,
    });
  };

  function updateTaskItems(newItems: BIM360ItemBase[]): void {
    if (task == null) {
      return;
    }
    newItems.forEach(i => {
      if (i instanceof DirectoryUI) {
        const taskItem = task.Directories.find(f => f.Id === i.Id);
        if (taskItem == null) {
          return;
        }
        task.Directories[task.Directories.indexOf(taskItem)] = i;
      } else if (i instanceof FileUI) {
        const taskItem = task.Models.find(f => f.Id === i.Id);
        if (taskItem == null) {
          return;
        }
        task.Models[task.Models.indexOf(taskItem)] = i;
      }
    });
  }

  async function loadNodeChildren(item: BIM360ItemBase, recursive: boolean = false): Promise<void> {
    if (item instanceof DirectoryUI && (!item.AreItemsPopulated || (recursive && AreAnyBranchesUnloaded(item)))) {
      loadingIds.push(item.Id);
      setLoadingIds(loadingIds);

      // Only need to actually populate down if we loaded new items but need to go through full tree for recursive.
      const needsPopulate = !item.AreItemsPopulated;
      await modelService.GetDirectoryContents(item)
        .then(d => {
          loadingIds.splice(loadingIds.indexOf(item.Id), 1);
          setLoadingIds(loadingIds);

          if (!d) {
            alert('Failed to get directory contents');
            return;
          }
          if (needsPopulate) {
            updateTaskItems(item.SubItems);
          }

          if (recursive) {
            const promises = item.SubFolders.map(f => loadNodeChildren(f, true));
            return Promise.all(promises);
          }
        })
        .catch(error => handleError(error, 'Get directory contents'));
    }

    return Promise.resolve();
  }

  const expanded = {};
  if (defaultExpansion) {
    // @ts-ignore
    defaultExpansion.forEach(id => expanded[id] = true);
  }
  const {orderedIds, normalizedTree, getTreeNodeProps, getTreeProps, dispatch} =
    useTree({
      selectedIds: selectedIds,
      onSelect: treeSelect,
      initialExpandedIds: expanded,
      // @ts-ignore
      denormalizedTree: [],
      getLabelFromNode: (node) => {
        const cast = node as TreeItem<BIM360ItemBase>;
        return cast.relatedObject.Name ?? '-nothing-';
      },
    });

  function treeSelect(changes: { id: IdType, isSelected: CheckboxState }): void {
    const treeItem = normalizedTree[changes.id].original as TreeItem<BIM360ItemBase>;
    if (allowMultiSelection && treeItem.relatedObject instanceof DirectoryUI) {
      return;
    }

    let updatedSelectedIds: TreeSelectedIds;
    if (cascadeSelection) {
      updatedSelectedIds = cascadeMultiSelection({
        changes,
        selectedIds,
        normalizedTree,
      });
    } else {
      updatedSelectedIds = selectedIds;
      if (!allowMultiSelection) {
        for (const key in updatedSelectedIds) {
          updatedSelectedIds[key] = false;
        }
      }
      updatedSelectedIds[changes.id] = changes.isSelected;
    }

    handleSelectionUpdate(updatedSelectedIds);
  }

  function isExpandable(id: IdType): boolean {
    const treeItem = normalizedTree[id].original as TreeItem<BIM360ItemBase>;
    const item: BIM360ItemBase = treeItem.relatedObject as BIM360ItemBase;
    if (item instanceof DirectoryUI) {
      const hasLoadedItems = foldersOnly ? item.SubFolders.length > 0 : item.SubItems.length > 0;
      return hasLoadedItems || !item.AreItemsPopulated;
    }
    return false;
  }

  function setChildrenCheckState(folder: DirectoryUI, checkedIds: TreeSelectedIds, checked: boolean, recursive: boolean): TreeSelectedIds {
    for (const file of folder.Models) {
      checkedIds[file.Id] = checked;
    }

    if (recursive) {
      for (const subDirectory of folder.SubFolders) {
        if (task != null) {
          if (checked && !task.Directories.includes(subDirectory)) {
            task.Directories.push(subDirectory);
          }
          if (!checked && task.Directories.includes(subDirectory)) {
            task.Directories.splice(task.Directories.indexOf(subDirectory), 1);
          }
        }
        setChildrenCheckState(subDirectory, checkedIds, checked, recursive);
      }
    }

    return checkedIds;
  }

  function finishFullCheck(): void {
    setShowLoadConfirm(false);
    loadNodeChildren(fullCheckFolder!, true)
      .then(() => {
        const newSelectedIds = setChildrenCheckState(fullCheckFolder!, selectedIds, fullCheck, true);
        handleSelectionUpdate(newSelectedIds);
      })
      .catch(error => handleError(error, 'Load node children'));
  }

  function handleSelectionUpdate(newSelection: TreeSelectedIds): void {
    setSelectedIds(newSelection);

    if (onSelectionChange) {
      const selection: { item: BIM360ItemBase, newIsSelected: boolean }[] = [];
      for (const id of Object.keys(newSelection)) {
        const object = FindProjectItemRecursive(project!, id);
        if (object == null) {
          continue;
        }

        selection.push({item: object, newIsSelected: newSelection[id] === true});
      }
      onSelectionChange(selection);
    }
  }

  function handleError(error: any, operation: string): void {
    if (onError) {
      onError(error, operation);
    }
  }

  return (
    <FlexColumn style={{flex: 1}}>
      {
        !loading &&
        <Tree {...getTreeProps()} normalizedTree={normalizedTree}>
          {orderedIds
            .map((id) => normalizedTree[id])
            .map(getTreeNodeProps)
            .map((treeNodeProps) => {
              const treeItem = normalizedTree[treeNodeProps.id].original as TreeItem<BIM360ItemBase>;
              const item = treeItem.relatedObject;
              const display = !foldersOnly || item instanceof DirectoryUI;
              return (
                display &&
                <React.Fragment key={`wrap-${treeNodeProps.id}`}>
                  <TreeNode
                    key={treeNodeProps.id}
                    {...treeNodeProps}
                    isMultiSelectable={allowSelection && allowMultiSelection}
                    isSingleSelectable={allowSelection && !allowMultiSelection}
                    isExpandable={isExpandable(treeNodeProps.id)}
                    onExpand={() => expandNode(treeNodeProps.id, treeNodeProps.isExpanded, treeNodeProps.onExpand)}>
                    {
                      (isExpandable(treeNodeProps.id)) && (
                        <ExpandButton
                          style={{
                            margin: '0 3px',
                          }}
                          isExpanded={treeNodeProps.isExpanded}
                          onExpand={() => expandNode(treeNodeProps.id, treeNodeProps.isExpanded, treeNodeProps.onExpand)}
                        />
                      )
                    }

                    <TreeNodeFileContent
                      item={item as BIM360ItemBase}
                      treeNodeProps={treeNodeProps}
                      allowSelection={allowSelection}
                      allowMultiSelection={allowMultiSelection}
                      allowAdd={allowAdd}
                      onAdd={onAdd}/>
                  </TreeNode>
                  <ProgressNode
                    show={loadingIds.includes(treeNodeProps.id as string)}
                    depth={treeNodeProps.depth + 1}/>
                </React.Fragment>
              );
            })}
        </Tree>
      }
      {
        loading &&
        <CenteringContainer style={{flex: 1}}>
          <ProgressRing size={'large'} style={{margin: '2em'}}/>
        </CenteringContainer>
      }
      <Modal open={showLoadConfirm}>
        <Modal.Header>Load Branch?</Modal.Header>
        <Modal.Body>
          <p style={Theme.typography.bodyMediumBold}>In order to check all files the entire tree branch must
            be loaded.
            This can take a long time for large branches, do you want to proceed?</p>
        </Modal.Body>
        <Modal.Footer>
          <FlexRow style={{justifyContent: 'end'}}>
            <BlueButton onClick={() => finishFullCheck()}>
              <FlexRowCentered>
                <span style={Theme.typography.labelMedium}>Yes</span>
              </FlexRowCentered>
            </BlueButton>
            <BlueButton style={{marginLeft: '1em'}}
                        onClick={() => setShowLoadConfirm(false)}>
              <FlexRowCentered>
                <span style={Theme.typography.labelMedium}>No</span>
              </FlexRowCentered>
            </BlueButton>
          </FlexRow>
        </Modal.Footer>
      </Modal>
    </FlexColumn>
  );
};

export default FileStructureList;