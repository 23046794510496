////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {Check} from './Check';
import {ReportSummarizable} from './ReportSummarizable';
import {IReportStructureTreeItem} from './IReportStructureTreeItem';

export class Section extends ReportSummarizable implements IReportStructureTreeItem {
  Id: string;
  Title: string;
  Name: string;
  Description: string;
  SubItems: (Section | Check)[];

  IsCheck = false;
  IsHeading = false;
  IsSection = true;

  AreItemsPopulated = false;

  constructor(id: string, title: string, name: string, description: string) {
    super();
    this.Id = id;
    this.Title = title;
    this.Name = name;
    this.Description = description;
    this.SubItems = [];
  }
}
