////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import { CheckResultType } from "../dataModel/Check";
import Icon_Report_Pass from '../assets/Icon_Report_Pass.png';
import Icon_Report_Fail from '../assets/Icon_Report_Fail.png';
import Icon_Report_Report from '../assets/Icon_Report_Report.png';
import Icon_Report_NotRun from '../assets/Icon_Report_NotRun.png';
import Icon_Error from '../assets/Icon_Error.png';

export class ConvertResultImage {
    public static Convert(value: CheckResultType): string {
        switch (value) {
            case CheckResultType.Pass:
                return Icon_Report_Pass;
            case CheckResultType.Fail:
                return Icon_Report_Fail;
            case CheckResultType.Report:
                return Icon_Report_Report;
            case CheckResultType.NotRun:
                return Icon_Report_NotRun;
            case CheckResultType.Error:
                return Icon_Error;
        }
    }
}