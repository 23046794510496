////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, {useState} from 'react';
import {FlexFill, FlexRowCentered} from "../CommonStyledComponents";
import {ArrowRotateTwoIcon, ClipboardIcon, EyeIcon, EyeSlashIcon} from "@adsk/alloy-react-icon";
import {Constants} from "../Constants";
import {IconButton} from "@adsk/alloy-react-button";
import TextInput from "@adsk/alloy-react-text-input";

const MaskedText = ({value, allowChange = false, onRevealed, onBeforeCopy, onCopied, onRefreshRequested, onChange}: {
  value: string | undefined,
  allowChange?: boolean,
  onRevealed?: () => void,
  onBeforeCopy?: () => Promise<void>,
  onCopied?: () => void,
  onRefreshRequested?: () => void,
  onChange?: (value: string | undefined) => void
}) => {
  const [reveal, setReveal] = useState(false);
  const [valueInternal, setValueInternal] = useState(value);

  let placeholder = '';
  for (let i = 0; i < Constants.SecretKeyLength; i++) {
    placeholder += '●';
  }

  function toggleReveal(): void {
    const isRevealing = !reveal;
    setReveal(!reveal)
    if (isRevealing && onRevealed) {
      onRevealed();
    }
  }

  function copy(): void {
    const beforePromise = onBeforeCopy ?? (() => Promise.resolve());
    beforePromise()
      .then(() => {
        if (value == null) {
          alert('Could not copy null value');
          return;
        }
        navigator.clipboard.writeText(value)
          .then(() => {
            if (onCopied) {
              onCopied();
            }
          });
      });
  }

  function onValueChange(e: string | undefined): void {
    setValueInternal(e);
    if (onChange) {
      onChange(e);
    }
  }

  return (
    <FlexRowCentered>
      {allowChange &&
        <TextInput
          type={reveal ? 'text' : 'password'}
          value={valueInternal ?? ''}
          onChange={v => onValueChange(v.target.value)}>
        </TextInput>
      }
      {!allowChange &&
        <FlexFill>
          {reveal && value}
          {!reveal && placeholder}
        </FlexFill>
      }
      <IconButton
        style={{alignSelf: 'center', minWidth: 0}}
        onClick={toggleReveal}>
        {reveal && <EyeSlashIcon/>}
        {!reveal && <EyeIcon/>}
      </IconButton>
      {
        onCopied &&
        <IconButton
          style={{alignSelf: 'center', minWidth: 0, marginLeft: '0.5em'}}
          onClick={copy}>
          <ClipboardIcon/>
        </IconButton>
      }
      {
        onRefreshRequested &&
        <IconButton
          style={{alignSelf: 'center', minWidth: 0, marginLeft: '0.5em'}}
          onClick={() => {
            if (onRefreshRequested) {
              onRefreshRequested();
            }
          }}>
          <ArrowRotateTwoIcon/>
        </IconButton>
      }
    </FlexRowCentered>
  );
};

export default MaskedText;