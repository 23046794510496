////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

export class ConvertDate {
  public static Convert(value: Date | undefined): string {
    if (value == null) {
      return 'n/a';
    } else {
      return `${this.getDayOfWeek(value)}, ${this.getMonthName(value)} ${value.getDate()}, ${value.getFullYear()}`;
    }
  }

  public static ConvertToSortableString(value: Date | undefined): string {
    if (value == null) {
      return 'n/a';
    } else {
      // Month is 0 indexed so need to add 1 to get it correct.
      // Need getDate() instead of getDay() as getDay() returns the day of the week.
      return `${value.getFullYear()}-${this.twoDigit(value.getMonth() + 1)}-${this.twoDigit(value.getDate())}_${this.twoDigit(value.getHours())}-${this.twoDigit(value.getMinutes())}-${this.twoDigit(value.getSeconds())}`;
    }
  }

  static twoDigit(value: number): string {
    const int = value.toFixed(0).toString();
    return int.padStart(2, '0');
  }

  static getDayOfWeek(date: Date): string {
    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return weekday[date.getDay()];
  }

  static getMonthName(date: Date): string {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return months[date.getMonth()];
  }
}