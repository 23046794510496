////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {CheckSet} from "../../clients/Classes";

export class ChecksetState {
  LoadingPublic: boolean = false;
  LoadingPrivate: boolean = true;

  PublicCheckSets: CheckSet[];
  PrivateCheckSets: CheckSet[];

  SelectedCheckset: CheckSet | undefined;

  Uploading: boolean = false;

  LoadingMorePrivate = false;
  HasMorePrivate = false;

  constructor() {
    this.PublicCheckSets = [];
    this.PrivateCheckSets = [];
  }
}