////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {ReportUI} from "../../dataModel/ReportUI";
import {FilterItemData} from "../../dataModel/FilterItemData";
import {ReportsFunctions} from '../../Enums';
import {CheckboxState} from "@adsk/alloy-react-checkbox";

export class ReportsState {
  public reports: ReportUI[];
  public filterOpen: boolean;
  filterOptions: FilterItemData[];
  filteredReports: ReportUI[];
  loading = false;
  checkedReports: string[];
  globalCheck: CheckboxState | undefined;
  public loadingMoreData = false;
  public hasMoreData = false;
  public canCancelLoad = false;

  showMultiFunctionWarning = false;
  multiFunction: ReportsFunctions;

  constructor() {
    this.reports = [];
    this.filterOpen = false;
    this.filterOptions = [];
    this.filteredReports = [];
    this.checkedReports = [];
    this.multiFunction = 'open';
  }
}
