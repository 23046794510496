////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, { useState } from 'react';
import { Task } from "../dataModel/Task";
import TextInput from "@adsk/alloy-react-text-input";
import Theme from "@adsk/alloy-react-theme";

const SettingsName = ({ task, onChange }: { task: Task | undefined, onChange?: (value: string) => void }) => {
    const [internalValue, setInternalValue] = useState(task?.Name ?? '');

    function sendUpdate(): void {
        if (task == null) {
            return;
        }

        task.Name = internalValue;

        if (onChange) {
            onChange(internalValue);
        }
    }

    return (
        <div>
            <h2 style={Theme.typography.heading2}>Name</h2>
            <TextInput value={internalValue}
                       placeholder={'Enter a task name...'}
                       onKeyDown={sendUpdate}
                       onChange={v => setInternalValue(v.target.value)}
                       onBlur={sendUpdate} />
        </div>
    );
};

export default SettingsName;