////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {IClient} from '../clients/Client';
import {ProjectUI} from '../dataModel/ProjectUI';
import {ProjectTranslator} from '../dataModel/translators/ProjectTranslator';
import {ClientProvider} from '../clients/ClientProvider';
import {ServiceBase} from "./ServiceBase";
import {IV2Client} from "../clients/V2Client";
import {Project} from "../clients/Classes";

export class ProjectService extends ServiceBase {
  private _client: IClient = ClientProvider.Client;
  private _v2Client: IV2Client = ClientProvider.V2Client;

  GetProjects(): Promise<ProjectUI[]> {
    return this.TryPromiseWithCatch(() =>
      this._client.listHubs(false)
        .then(async (hubs) => {
          const promises: Promise<Project[]>[] = [];

          hubs.forEach(h => {
            promises.push(
              this.GetRemainingDataFromPaginatedEndpoint(t =>
                this._v2Client.listHubProjects(h.id!, t))
                .then(r => r.items!)
            );
          });

          const allProjects: ProjectUI[] = [];
          await Promise.all(promises)
            .then(projectSets => {
              projectSets.forEach(ps => ps
                .map(p => ProjectTranslator.TranslateProject(p))
                .forEach(p => allProjects.push(p)))
            });
          return allProjects;
        })
    );
  }

  GetProject(hubId: string, id: string): Promise<ProjectUI> {
    return this.TryPromiseWithCatch(() =>
      this._client.getProject(hubId, id)
        .then(project => ProjectTranslator.TranslateProject(project))
    );
  }
}