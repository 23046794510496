////////////////////////////////////////////////////////////////////////////////
//                                                                             /
//                                                                             /
//  (C) Copyright 2024 Autodesk, Inc. All rights reserved.                     /
//                                                                             /
//                       ****  CONFIDENTIAL MATERIAL  ****                     /
//                                                                             /
//  The information contained herein is confidential, proprietary to           /
//  Autodesk, Inc., and considered a trade secret.  Use of this information    /
//  by anyone other than authorized employees of Autodesk, Inc. is granted     /
//  only under a written nondisclosure agreement, expressly prescribing        /
//  the scope and manner of such use.                                          /
//                                                                             /
////////////////////////////////////////////////////////////////////////////////

import {ProjectWiseConfiguration} from "../../clients/Classes";
import {ProjectWiseConfigurationUI} from "../ProjectWiseConfigurationUI";

export class ProjectWiseConfigurationTranslator {
  static TranslateConfiguration(apiConfiguration: ProjectWiseConfiguration): ProjectWiseConfigurationUI {
    return new ProjectWiseConfigurationUI(apiConfiguration);
  }
}