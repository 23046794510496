////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import { TaskTrigger } from "../Enums";
import { Constants } from "../Constants";


export class ConvertTrigger {
    public static Convert(value: TaskTrigger | undefined): string {
        switch (value) {
            case "OnceNow":
                return 'Once Now';
            case "OnceLater":
                return 'Once Later';
            case "Recurring":
                return 'Recurring';
            case "OnPublish":
                return 'On Publish';
            case undefined:
                return Constants.NoneString;
        }
    }
}