////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import { UsageDataItem } from '../UsageDataItem';
import {
    CustomerUsageData,
    CustomerUsageDataBase,
    JobUsageData, JobUsageDataBase,
    OverallUsageData,
    UserUsageData, UserUsageDataBase
} from '../../clients/Classes';
import { UsageSummaryUI } from '../UsageSummaryUI';

export class UsageDataTranslator {
    static TranslateUsageSummary(summaryData: OverallUsageData): UsageSummaryUI {
        const summaryUI = new UsageSummaryUI(summaryData.jobRunCount!, summaryData.checkCount!, summaryData.runTimeSeconds!);
        summaryUI.CustomerData = [];
        summaryData.customerUsageDatas!.forEach(c => {
            const customerData = this.TranslateItem(c);
            summaryUI.CustomerData.push(customerData);
        });

        return summaryUI;
    }

    static TranslateItem(item: any): UsageDataItem {
        if (item instanceof CustomerUsageData || item instanceof CustomerUsageDataBase) {
            return this.TranslateCustomer(item);
        } else if (item instanceof UserUsageData || item instanceof UserUsageDataBase) {
            return this.TranslateUser(item);
        } else if (item instanceof JobUsageData || item instanceof JobUsageDataBase) {
            return this.TranslateJob(item);
        } else {
            throw Error('Unsupported object type sent for translation');
        }
    }

    private static TranslateCustomer(customer: CustomerUsageData): UsageDataItem {
        const customerItem = new UsageDataItem(
            customer.customerId!,
            customer.customerName!,
            customer.level!.toString(),
            customer.jobRunCount!,
            customer.checkCount!,
            customer.runTimeSeconds!
        );
        customerItem.Children = [];

        customer.userUsageDatas!.forEach(u => {
            const user = this.TranslateItem(u);
            customerItem.Children.push(user);
        });

        return customerItem;
    }

    private static TranslateUser(user: UserUsageData): UsageDataItem {
        const userItem = new UsageDataItem(
            user.userId!,
            user.username!,
            user.level!.toString(),
            user.jobRunCount!,
            user.checkCount!,
            user.runTimeSeconds!
        );

        user.jobUsageDatas!.forEach(j => {
            const jobItem = this.TranslateItem(j);
            userItem.Children.push(jobItem);
        });

        return userItem;
    }

    private static TranslateJob(job: JobUsageData): UsageDataItem {
        const jobItem = new UsageDataItem(
            job.jobId!,
            job.jobName!,
            job.level!.toString(),
            job.jobRunCount!,
            job.checkCount!,
            job.runTimeSeconds!
        );
        jobItem.RevitVersion = job.revitVersion;

        return jobItem;
    }
}
