////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, {useEffect, useReducer, useState} from 'react';
import {reducer} from './reducers/ReportDetailReducer';
import {ReportDetailState} from './states/ReportDetailState';
import {
  BlueButton,
  CenteringContainer,
  ContentWrapper,
  FlexColumn,
  FlexFill,
  FlexRowCentered
} from '../CommonStyledComponents';
import {IdType} from '@adsk/alloy-react-tree/es/types';
import {useParams} from 'react-router';
import styled from 'styled-components';
import {GetResultClass} from '../UiUtility';
import {ConvertRunDate} from '../converters/ConvertRunDate';
import {ConvertSummary} from '../converters/ConvertSummary';
import {ReportService} from '../services/ReportService';
import {ReportDetailActions, ReportFormats} from '../Enums';
import {
  FindDirectoryRecursive,
  GetErrorMessage,
  GetRecursiveFilePath,
  GetTreeItems
} from '../Utility';
import {IReportStructureTreeItem} from '../dataModel/IReportStructureTreeItem';
import {Section} from '../dataModel/Section';
import {Heading} from '../dataModel/Heading';
import StructureDetailHeading from './StructureDetailHeading';
import {Check} from '../dataModel/Check';
import StructureDetailCheck from './StructureDetailCheck';
import StructureDetailSection from './StructureDetailSection';
import {ProjectService} from '../services/ProjectService';
import {ModelService} from '../services/ModelService';
import {DirectoryUI} from '../dataModel/DirectoryUI';
import {ExpandButton, Tree, TREE_ACTIONS, TreeNode, useTree} from "@adsk/alloy-react-tree";
import Theme from "@adsk/alloy-react-theme";
import {ArrowRotateTwoIcon, DocumentSpreadsheetIcon, DocumentTextIcon} from "@adsk/alloy-react-icon";
import Tooltip from "@adsk/alloy-react-tooltip";
import {IconButton} from "@adsk/alloy-react-button";
import ProgressRing from "@adsk/alloy-react-progress-ring";
import Illustration from "@adsk/alloy-react-illustration";
import {TreeItem} from "../dataModel/TreeItem";
import {ProjectWiseService} from "../services/ProjectWiseService";
import {NameRetrievalService} from "../services/NameRetrievalService";

const OverallResultContainer = styled.div`
  padding: .35em;
  font-size: 5em;
  font-weight: bold;
`;

const service = new ReportService();
const projectService = new ProjectService();
const nameService = new NameRetrievalService(projectService, new ProjectWiseService());
const modelService = new ModelService();

const ReportDetail = () => {
  const {id} = useParams();
  const [state, standardDispatch] = useReducer(reducer, new ReportDetailState());
  const [expandedIds, setExpandedIds] = useState<string[]>([]);

  useEffect(() => {
    loadReport();
  }, [id]);

  function loadReport(): void {
    if (id == null) {
      return;
    }

    standardDispatch({type: ReportDetailActions.loading, payload: true});

    service.GetReportSecureDownloadLocation(id)
      .then(dto => {
        service.GetCompleteReport(dto)
          .then(report => {
            nameService.CheckModelPaths(report)
              .then(project => {
                report.Models.forEach(m => {
                  if (project == null) {
                    m.FullPath = `-Project Not Found-/${m.Name}.rvt`;
                    return;
                  }
                  modelService.PopulateToDirectory(project, m.DirectoryId)
                    .then(result => {
                      if (!result.Success) {
                        console.error('There was an error populating to directory');
                        m.FullPath = `-Populated Failed-/${m.Name}.rvt`;
                        return;
                      }

                      let mainDirectory: DirectoryUI | null = null;

                      project.RootFolderArray.forEach(f => {
                        if (mainDirectory != null) {
                          return;
                        }
                        mainDirectory = FindDirectoryRecursive(f, m.DirectoryId);
                      });

                      if (mainDirectory == null) {
                        console.error('Error populating to directory, main directory not found');
                        m.FullPath = `-Directory Not Found-/${m.Name}.rvt`;
                        return;
                      }

                      const filePath = GetRecursiveFilePath(mainDirectory, '/');
                      m.FullPath = `${project.HubName}->${project.Name}->${filePath}/${m.Name}`;

                      standardDispatch({
                        type: ReportDetailActions.multipleActions,
                        payload: {Report: {...report}, LoadingModelPath: false}
                      });
                    })
                    .catch(error => onError(error, 'Populate Paths'));
                });
              })
              .catch(error => onError(error, 'Get Model Paths'));

            standardDispatch({
              type: ReportDetailActions.multipleActions,
              payload: {Loading: false, Report: report, LoadingModelPath: true}
            });
            dispatch({type: TREE_ACTIONS.collapseAllNodes});
            setExpandedIds([]);
            const dta = GetTreeItems(report.Files[0].Headings);
            // @ts-ignore
            dispatch({type: TREE_ACTIONS.regenerateTree, payload: {denormalizedTree: dta}});
          });
      })
      .catch(error => {
        onError(error, 'Get report');
        standardDispatch({type: ReportDetailActions.loading, payload: false});
      });
  }

  function Export(format: ReportFormats, combine: boolean = false): void {
    if (state.Report == null) {
      return;
    }

    const name = state.Report.JobName;

    service.GetReportDownload(state.Report, format, combine)
      .then(result => {
        if (!result.isReady) {
          throw Error('Get Download returned with an incomplete result');
        }

        service.ProcessReportDownload(result, name);
        return;
      })
      .catch(er => onError(er, 'Download report'));
  }

  const {orderedIds, normalizedTree, getTreeNodeProps, getTreeProps, dispatch} = useTree({
    denormalizedTree: []
  });

  const expandNode = async (
    id: IdType,
    isExpanded: boolean,
    onExpand: (p: { isExpanded: boolean; id: IdType }) => void
  ): Promise<void> => {
    const treeItem = normalizedTree[id].original as TreeItem<IReportStructureTreeItem>;
    const item: IReportStructureTreeItem = treeItem.relatedObject;

    // This is the previous state so update state
    const newExpanded = !isExpanded;

    if (newExpanded) {
      item.AreItemsPopulated = true;
      const items: IReportStructureTreeItem[] = item instanceof Heading || item instanceof Section
        ? item.SubItems
        : [];
      const newNodes = items.filter(i => normalizedTree[i.Id] == null);
      if (newNodes.length > 0) {
        dispatch({
          type: TREE_ACTIONS.addChildNodes,
          // @ts-ignore
          payload: {targetId: id, newChildNodes: GetTreeItems(newNodes)}
        });
      }

      if (!expandedIds.includes(item.Id)) {
        expandedIds.push(item.Id);
      }
    } else {
      const index = expandedIds.indexOf(item.Id);
      if (index > 0) {
        expandedIds.splice(index, 1);
      }
    }
    setExpandedIds(expandedIds);

    onExpand({
      isExpanded: !isExpanded,
      id,
    });
  };

  function isExpandable(id: IdType): boolean {
    const treeItem = normalizedTree[id].original as TreeItem<IReportStructureTreeItem>;
    const item = treeItem.relatedObject as IReportStructureTreeItem;
    if (item instanceof Heading || item instanceof Section) {
      return item.SubItems.length > 0 || !item.AreItemsPopulated;
    }
    return false;
  }

  function onError(error: any, operation: string): void {
    alert(GetErrorMessage(error, operation));
  }

  return (
    <ContentWrapper>
      <h1 style={Theme.typography.heading1}>View Report</h1>
      <FlexRowCentered style={{padding: '0.5em 0', flex: 0}}>
        <BlueButton onClick={() => Export('Excel')}>
          <FlexRowCentered>
            <DocumentSpreadsheetIcon style={{marginRight: '0.5em'}}/>
            <span style={Theme.typography.labelMedium}>Download Excel (Model Only)</span>
          </FlexRowCentered>
        </BlueButton>
        <BlueButton style={{marginLeft: '1em'}} onClick={() => Export('Excel', true)}>
          <FlexRowCentered>
            <DocumentSpreadsheetIcon style={{marginRight: '0.5em'}}/>
            <span style={Theme.typography.labelMedium}>Download Excel (Full Task)</span>
          </FlexRowCentered>
        </BlueButton>
        <BlueButton style={{marginLeft: '1em'}} onClick={() => Export('Html')}>
          <FlexRowCentered>
            <DocumentTextIcon style={{marginRight: '0.5em'}}/>
            <span style={Theme.typography.labelMedium}>Download HTML</span>
          </FlexRowCentered>
        </BlueButton>
        <BlueButton style={{marginLeft: '1em'}} onClick={() => Export('AVT')}>
          <FlexRowCentered>
            <DocumentTextIcon style={{marginRight: '0.5em'}}/>
            <span style={Theme.typography.labelMedium}>Download AVT File</span>
          </FlexRowCentered>
        </BlueButton>
        <FlexFill/>
        <Tooltip content={'Refresh Report'}>
          <IconButton
            onClick={loadReport}
            renderIcon={() => <ArrowRotateTwoIcon/>}
            style={{marginRight: '1em'}}/>
        </Tooltip>
      </FlexRowCentered>
      {
        state.Loading &&
        <CenteringContainer>
          <ProgressRing size={'large'}/>
        </CenteringContainer>
      }
      {
        !state.Loading && state.Report == null &&
        <CenteringContainer style={{flexDirection: 'column'}}>
          <Illustration type={'pagesTextGrey'} height={200} width={200}/>
          <p style={Theme.typography.bodyLarge}>Report was not found</p>
        </CenteringContainer>
      }
      {
        !state.Loading && state.Report != null &&
        <FlexColumn>
          <FlexRowCentered style={{margin: '2em 0'}}>
            {
              (state.Report.PassingChecks > 0 || state.Report.FailingChecks > 0) &&
              <OverallResultContainer className={GetResultClass(state.Report.PassPercent)}>
                {Math.round(state.Report.PassPercent)}%
              </OverallResultContainer>
            }
            <FlexFill>
              <table style={{width: '100%'}}>
                <tbody>
                <tr>
                  <td>Check Summary</td>
                  <td>{ConvertSummary.Convert(state.Report)}</td>
                </tr>
                <tr>
                  <td>Report Date</td>
                  <td>{ConvertRunDate.Convert(state.Report.ReportDate)}</td>
                </tr>
                <tr>
                  <td>Revit Filepath</td>
                  <td>
                    {
                      state.LoadingModelPath && <ProgressRing size={'xsmall'}/>
                    }
                    {
                      !state.LoadingModelPath && (state.Report.Models == null || state.Report.Models.length === 0 ? 'Model path not set' : state.Report.Models[0].FullPath)
                    }
                  </td>
                </tr>
                <tr>
                  <td>Checkset File</td>
                  <td>{state.Report.ChecksetPath}</td>
                </tr>
                </tbody>
              </table>
            </FlexFill>
          </FlexRowCentered>
          <Tree {...getTreeProps()} normalizedTree={normalizedTree}>
            {orderedIds
              .map(id => normalizedTree[id])
              .map(getTreeNodeProps)
              .map(treeNodeProps => {
                const treeItem = normalizedTree[treeNodeProps.id].original as TreeItem<IReportStructureTreeItem>;
                const item = treeItem.relatedObject;
                return (
                  <React.Fragment key={`wrap-${treeNodeProps.id}`}>
                    <TreeNode
                      {...treeNodeProps}
                      key={treeNodeProps.id}
                      id={treeNodeProps.id}
                      isMultiSelectable={false}
                      isSingleSelectable={false}
                      isExpandable={isExpandable(treeNodeProps.id)}
                      style={{height: 'inherit', padding: '0.5em 0'}}
                      show={treeNodeProps.show}>
                      {
                        isExpandable(treeNodeProps.id) && (
                          <ExpandButton
                            style={{
                              margin: '0 3px',
                            }}
                            isExpanded={treeNodeProps.isExpanded}
                            onExpand={() => expandNode(treeNodeProps.id, treeNodeProps.isExpanded, treeNodeProps.onExpand)}
                          />
                        )
                      }
                      {
                        item instanceof Heading &&
                        <StructureDetailHeading item={item}/>
                      }
                      {
                        item instanceof Section &&
                        <StructureDetailSection item={item}/>
                      }
                      {
                        item instanceof Check &&
                        <StructureDetailCheck item={item}/>
                      }
                    </TreeNode>
                  </React.Fragment>
                );
              })
            }
          </Tree>
        </FlexColumn>
      }
    </ContentWrapper>
  );
};

export default ReportDetail;
