////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React from 'react';
import {Outlet} from 'react-router';
import HeaderBar from "./HeaderBar";
import Nav from "./Nav";
import styled from "styled-components";
import {FlexRow} from "../CommonStyledComponents";
import Theme from "@adsk/alloy-react-theme";
import Divider from "@adsk/alloy-react-divider";

const AppContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const MainContainer = styled(FlexRow)`
  flex: 1;
  min-width: 0;
  min-height: 0;
`;

const ContentContainer = styled.div`
  flex: 1;
  min-width: 0;
  display: flex;
`;

const MainContent = () => {
  return (
    <AppContainer style={Theme.typography.bodyMedium}>
      <HeaderBar/>
      <Divider/>
      <MainContainer>
        <Nav/>
        <ContentContainer>
          <Outlet />
        </ContentContainer>
      </MainContainer>
    </AppContainer>
  )
    ;
};

export default MainContent;