////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React from 'react';
import {
    CenteringContainer,
    CheckSetIconLarge,
    FlexColumn,
    FlexFill,
    FlexRow,
    LineWrapper,
    ValueWrapper
} from '../CommonStyledComponents';
import { CheckSet } from "../clients/Classes";
import { ConvertDate } from "../converters/ConvertDate";
import Default_Checkset from "../assets/Default_Checkset.png";
import Theme from "@adsk/alloy-react-theme";

const ChecksetDetail = ({ checkset }: { checkset: CheckSet | undefined }) => {
    return (
        <>
            {
                checkset == null &&
                <CenteringContainer style={Theme.typography.caption}>No Checkset Selected</CenteringContainer>
            }
            {
                checkset != null &&
                <FlexRow>
                    <CheckSetIconLarge src={checkset.imagePath == null || checkset.imagePath.trim() === '' ? Default_Checkset : checkset.imagePath} alt={'Checkset Avatar'} />
                    <FlexFill>
                        <FlexColumn>
                            <ValueWrapper>
                                <LineWrapper style={Theme.typography.bodyMediumBold}>Title</LineWrapper>
                                <LineWrapper style={Theme.typography.bodyMedium}>{checkset?.name}</LineWrapper>
                            </ValueWrapper>
                            <ValueWrapper>
                                <LineWrapper style={Theme.typography.bodyMediumBold}>Date</LineWrapper>
                                <LineWrapper
                                    style={Theme.typography.bodyMedium}>{ConvertDate.Convert(checkset.lastUpdate)}</LineWrapper>
                            </ValueWrapper>
                            <ValueWrapper>
                                <LineWrapper style={Theme.typography.bodyMediumBold}>Author</LineWrapper>
                                <LineWrapper style={Theme.typography.bodyMedium}>{checkset?.author}</LineWrapper>
                            </ValueWrapper>
                            <ValueWrapper>
                                <LineWrapper style={Theme.typography.bodyMediumBold}>Description</LineWrapper>
                                <LineWrapper style={Theme.typography.bodyMedium}>{checkset?.description}</LineWrapper>
                            </ValueWrapper>
                        </FlexColumn>
                    </FlexFill>
                </FlexRow>
            }
        </>
    );
};

export default ChecksetDetail;