////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////
// noinspection SuspiciousTypeOfGuard,SpellCheckingInspection

import {MetricType} from 'web-vitals';

const reportWebVitals = (onPerfEntry?: (metric: MetricType) => void) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({onCLS, onINP, onFCP, onLCP, onTTFB}) => {
      onCLS(onPerfEntry);
      onINP(onPerfEntry);
      onFCP(onPerfEntry);
      onLCP(onPerfEntry);
      onTTFB(onPerfEntry);
    });
  }
};

export default reportWebVitals;
