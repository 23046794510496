////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import { Task } from "../dataModel/Task";

export class ConvertRunDate {
    public static Convert(value: Date | undefined, args?: any, includeTime?: boolean): string {
        if (args != null && args instanceof Task && args.Trigger === 'OnPublish') {
            return 'On File Update';
        }

        if (value == null) {
            return 'n/a';
        } else {
            const formatter = new Intl.DateTimeFormat('un-US', {
                dateStyle: 'medium',
                timeStyle: includeTime ? 'medium' : undefined
            });
            return formatter.format(value);
        }
    }
}