////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {
  DirectoryDto,
  File360,
  FileDto,
  JobDirectory,
  JobFile,
  HubType,
  ProjectType,
  Directory360,
  BitDirectory,
  StorageType,
  BitFile,
  ProjectWiseFile,
  FileType, AvtV1JobFileDto
} from '../../clients/Classes';
import {DirectoryUI} from '../DirectoryUI';
import {FileUI} from '../FileUI';

export class FileStructureTranslator {
  static GetDirectory(apiDirectory: BitDirectory | Directory360 | DirectoryDto | JobDirectory, isProjectFiles: boolean): DirectoryUI {
    const storageType = apiDirectory instanceof DirectoryDto
      ? apiDirectory.storageType!
      : apiDirectory instanceof JobDirectory || apiDirectory instanceof Directory360
        ? StorageType.Forge
        : StorageType.ProjectWise;
    const directory = new DirectoryUI(apiDirectory.id!, '', apiDirectory.projectId!, storageType);

    if (Object.hasOwn(apiDirectory, 'hubId')) {
      // @ts-ignore
      directory.HubId = apiDirectory.hubId;
    }

    if (Object.hasOwn(apiDirectory, 'parentId')) {
      // @ts-ignore
      directory.ParentId = apiDirectory.parentId;
    }

    if (Object.hasOwn(apiDirectory, 'name')) {
      // @ts-ignore
      directory.Name = apiDirectory.name;
    }

    if (Object.hasOwn(apiDirectory, 'lastUpdated')) {
      // @ts-ignore
      directory.LastChange = apiDirectory.lastUpdated;
    }

    if (Object.hasOwn(apiDirectory, 'region')) {
      // @ts-ignore
      directory.HubRegion = apiDirectory.region;
    }

    if (Object.hasOwn(apiDirectory, 'hubType')) {
      // @ts-ignore
      directory.HubType = apiDirectory.hubType ?? HubType.Unknown;
    }

    if (Object.hasOwn(apiDirectory, 'projectType')) {
      // @ts-ignore
      directory.ProjectType = apiDirectory.projectType ?? ProjectType.Unknown;
    }

    if (isProjectFiles) {
      directory.Icon = 'assets/images/Icon_ProjectFiles.png';
    }

    return directory;
  }

  static GetApiDirectoryDto(directory: DirectoryUI): DirectoryDto {
    return new DirectoryDto({
      projectId: directory.ProjectId,
      id: directory.Id,
      hubId: directory.HubId,
      storageType: directory.StorageType,
      recursive: directory.Recursive
    });
  }

  static GetFile(apiFile: File360 | BitFile | AvtV1JobFileDto): FileUI {
    if (apiFile.isDirectory) {
      throw Error('Tried to convert directory in file translator');
    }
    const storageType = apiFile instanceof JobFile || apiFile instanceof File360
      ? StorageType.Forge
      : StorageType.ProjectWise;
    const fileName = apiFile instanceof AvtV1JobFileDto
      ? apiFile.name!
      : apiFile.fileNameWithExtension
      ?? `${apiFile.name!}${apiFile.fileType == null
        ? ''
        : `.${apiFile.fileType?.fileExtension}`}`;
    const file = new FileUI(apiFile.id!, fileName, apiFile.projectId!, apiFile.directoryId!, apiFile.fileType!, storageType);
    file.ParentId = apiFile.parentId;
    file.FileSize = apiFile.size;
    file.LastChange = apiFile.lastUpdated;
    file.IsProcessed = apiFile instanceof ProjectWiseFile || ((apiFile instanceof File360 || apiFile instanceof JobFile) && apiFile.isProcessed !== false);
    file.StorageType = storageType;

    if (!apiFile.isDirectory) {
      file.Icon = apiFile.thumbnail ?? 'assets/images/Default_File.png';
      file.FileType = new FileType(apiFile.fileType);
      file.LastUpdate = apiFile.lastUpdated!;
      file.UpdateBy = apiFile.lastUpdatedBy!;
      file.Version = (apiFile instanceof File360 || apiFile instanceof JobFile) ? apiFile.versionNumber?.toString() : undefined;
    }

    if (Object.hasOwn(apiFile, 'hubId')) {
      // @ts-ignore
      file.HubId = apiFile.hubId;
    }

    if (Object.hasOwn(apiFile, 'hubName')) {
      // @ts-ignore
      file.HubName = apiFile.hubName;
    }

    if (Object.hasOwn(apiFile, 'projectName')) {
      // @ts-ignore
      file.ProjectName = apiFile.projectName;
    }

    if (Object.hasOwn(apiFile, 'region')) {
      // @ts-ignore
      file.HubRegion = apiFile.region;
    }

    if (Object.hasOwn(apiFile, 'hubType')) {
      // @ts-ignore
      file.HubType = apiFile.hubType ?? HubType.Unknown;
    }

    if (Object.hasOwn(apiFile, 'projectType')) {
      // @ts-ignore
      file.ProjectType = apiFile.projectType ?? ProjectType.Unknown;
    }

    return file;
  }

  static GetApiFileDto(file: FileUI): FileDto {
    return new FileDto({
      id: file.Id,
      projectId: file.ProjectId,
      hubId: file.HubId,
      storageType: file.StorageType,
    });
  }
}
