////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import { OptionsState } from "../states/OptionsState";
import { OptionsActions } from "../../Enums";

export function reducer(state: OptionsState, action: { type: OptionsActions, payload: any }): OptionsState {
    switch (action.type) {
        case OptionsActions.revitVersion:
            return { ...state, revitVersion: action.payload };
        case OptionsActions.selectedProject:
            return { ...state, selectedProject: action.payload };
        case OptionsActions.selectedDirectory:
            return { ...state, selectedDirectory: action.payload };
        case OptionsActions.showLocationDialog:
            return { ...state, showLocationDialog: action.payload };
        case OptionsActions.multipleActions:
            return { ...state, ...action.payload };
        case OptionsActions.exportExcel:
            return { ...state, exportExcel: action.payload };
        case OptionsActions.includeList:
            return { ...state, includeList: action.payload };
        case OptionsActions.combineReports:
            return { ...state, combineReports: action.payload };
        case OptionsActions.exportHtml:
            return { ...state, exportHtml: action.payload };
        case OptionsActions.location:
            return { ...state, location: action.payload };
        case OptionsActions.naming:
            return { ...state, naming: action.payload };
        case OptionsActions.email:
            return { ...state, email: action.payload };
        case OptionsActions.attachExports:
            return { ...state, attachExports: action.payload };
    }
}