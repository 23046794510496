////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, {ReactElement} from 'react';
import {useLocation} from "react-router";
import {PAGES, PATHS} from "../Constants";
import {IsPageAvailableToUser} from "../Utility";
import {ClientProvider} from "../clients/ClientProvider";
import {Navigate} from "react-router-dom";
import {pages} from "../pages/Routes";

const authService = ClientProvider.AuthService;

const RequireAuth = ({children}: { children: ReactElement | null }) => {
  const location = useLocation();

  const page = pages.find(p => `${PATHS[PAGES.ROOT]}/${p.path}` === location.pathname);
  const hasAccess = authService.IsLoggedIn
    && (page == null || IsPageAvailableToUser(page, authService.CurrentUser));

  return hasAccess
    ? children
    : <Navigate to={{pathname: PATHS[PAGES.DOOR]}}
                state={{from: authService.IsLoggedIn ? undefined : location}} replace/>;
};

export default RequireAuth;