////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

export class RevitElement {
    Category: string | undefined;
    Family: string | undefined;
    Type: string | undefined;
    Name: string;
    ID: string;
    Value: string | undefined;
    Count: number = 1;

    constructor(id: string, name: string) {
        this.ID = id;
        this.Name = name;
    }
}
