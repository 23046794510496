////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React from 'react';
import { Section } from "../dataModel/Section";
import { FlexColumn, FlexFill, FlexRow, StructureTitles } from "../CommonStyledComponents";
import { ConvertSummary } from "../converters/ConvertSummary";

const StructureDetailSection = ({ item }: { item: Section }) => {
    return (
        <FlexColumn>
            <FlexRow>
                <StructureTitles>
                    {item.Name}
                    {item.Name != null && item.Name !== '' && item.Title != null && item.Title !== '' && ` - ${item.Title}`}
                </StructureTitles>
                <FlexFill />
                <div>{ConvertSummary.Convert(item)}</div>
            </FlexRow>
            <div>{item.Description}</div>
        </FlexColumn>
    );
};

export default StructureDetailSection;