////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React from 'react';
import {CenteringContainer, FlexColumnCentered} from "../CommonStyledComponents";
import Illustration from "@adsk/alloy-react-illustration";
import Theme from "@adsk/alloy-react-theme";
import {Link} from "react-router-dom";
import styled from "styled-components";

const Explanation = styled.div`
  margin: 1em 3em;
  text-align: center;
`

const NotFound = () => {
  return (
    <CenteringContainer>
      <FlexColumnCentered>
        <Illustration type={"systemError"}/>
        <h3 style={Theme.typography.heading3}>Sorry, that page was not found</h3>
        <Explanation style={Theme.typography.bodyMedium}>If you came here from a bookmark the bookmark may have moved,
          please go back to the <Link to={'/'}>main application</Link> and update your bookmarks. If you came here from
          a link in the main application please contact support.
        </Explanation>
      </FlexColumnCentered>
    </CenteringContainer>
  );
};

export default NotFound;