////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {
  ExportReportLocationType,
  AvtV1ExportReportOptions,
  AvtV1ExportReportRequest,
  AvtV1ExportResultDto,
  FileDestinationNamingType,
  ReportDownloadDto,
  AvtV1ReportDto
} from '../clients/Classes';
import {ReportUI} from '../dataModel/ReportUI';
import {ReportTranslator} from '../dataModel/translators/ReportTranslator';
import {ClientProvider} from '../clients/ClientProvider';
import {DownloadUrl, PollForResult} from '../Utility';
import {ReportFormats} from '../Enums';
import {ServiceBase} from "./ServiceBase";
import {AvtItemReportSummaryResult} from "../clients/V2Classes";
import {CancellationToken} from "../dataModel/CancellationToken";
import {IClient} from "../clients/Client";
import {IV2Client} from "../clients/V2Client";
import {SampleDataProvider} from "../_mock/SampleDataProvider";

export class ReportService extends ServiceBase {
  private _client: IClient = ClientProvider.Client;
  private _v2Client: IV2Client = ClientProvider.V2Client;

  public GetReports(paginationToken?: string | null | undefined, limit?: number | null | undefined): Promise<AvtItemReportSummaryResult> {
    return this.TryPromiseWithCatch(() =>
      this.GetResultsWithItemOrToEnd(t => this._v2Client.listItemReportHistory(undefined, t, limit), paginationToken)
        .then(r => new AvtItemReportSummaryResult(r))
    );
  }

  public GetRemainingReports(
    paginationToken?: string | null | undefined,
    limit?: number | null | undefined,
    cancelToken?: CancellationToken | undefined
  ): Promise<AvtItemReportSummaryResult> {
    return this.TryPromiseWithCatch(() =>
      this.GetRemainingDataFromPaginatedEndpoint(token =>
        this._v2Client.listItemReportHistory(undefined, token, limit), paginationToken, cancelToken)
        .then(reports => new AvtItemReportSummaryResult(reports))
    );
  }

  GetReportSecureDownloadLocation(id: string): Promise<ReportDownloadDto> {
    return this.TryPromiseWithCatch(() =>
      this._client.getFullReport(id)
    );
  }

  GetCompleteReport(downloadDto: ReportDownloadDto): Promise<ReportUI> {
    if (process.env.REACT_APP_USE_MOCK_DATA === '1') {
      return Promise.resolve(ReportTranslator.GetFullReport(SampleDataProvider.SampleDataV1.sampleReports[0]));
    }
    return fetch(downloadDto.downloadUrl!)
      .then(response => response.json())
      .then(data => {
        const r = AvtV1ReportDto.fromJS(data);
        return ReportTranslator.GetFullReport(r);
      });
  }

  GetReportDownload(report: ReportUI, format: ReportFormats, combine: boolean): Promise<AvtV1ExportResultDto> {
    return this.TryPromiseWithCatch(() =>
      this.StartReportDownload(report, format, combine)
        .then(result => {
          if (result.isReady) {
            return result;
          }

          return PollForResult(() => this._client.getExportDownloadStatus(result.statusId!),
            (result) => !result.isReady, 2000);
        })
    );
  }

  ProcessReportDownload(result: AvtV1ExportResultDto, reportName: string): void {
    const formatExport = result.excelExport ?? result.htmlExport ?? result.offlineExport;

    if (formatExport == null) {
      alert('Error processing download result: all exports were null');
      return;
    }

    if (formatExport.failureMessage != null && formatExport.failureMessage.trim() !== '') {
      alert(`Error processing download result: ${formatExport.failureMessage}`);
      return;
    }

    DownloadUrl(formatExport.exportUri!, reportName);
  }

  DeleteReport(reportId: string): Promise<boolean> {
    return this.TryPromiseWithCatch(() =>
      this._client.deleteReport(reportId)
        .then(() => true)
    );
  }

  private StartReportDownload(report: ReportUI, format: ReportFormats, combine: boolean): Promise<AvtV1ExportResultDto> {
    const options = new AvtV1ExportReportOptions({
      excel: format === 'Excel',
      html: format === 'Html',
      offline: format === 'AVT',
      locationType: ExportReportLocationType.None,
      exportLists: format === 'Excel',
      destinationNamingType: FileDestinationNamingType.None,
      combineExcel: combine
    });
    const command = new AvtV1ExportReportRequest({
      reportId: report.Id,
      jobRunId: report.JobRunId,
      options: options
    });

    return this.TryPromiseWithCatch(() =>
      this._client.exportReport(command)
    );
  }
}
