////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React from 'react';
import {FlexColumn, FlexRow, StructureTitles} from '../CommonStyledComponents';
import {Check, CheckResultType} from "../dataModel/Check";
import {ConvertResultImage} from "../converters/ConvertResultImage";
import {RevitElement} from "../dataModel/RevitElement";
import {OverflowTooltip} from "@adsk/alloy-react-tooltip";
import Table from "./Table";
import {ColumnDef} from "@tanstack/react-table";

const StructureDetailCheck = ({item}: {
  item: Check
}) => {
  const columns: ColumnDef<RevitElement>[] = [];

  if (item.ShowCountColumn) {
    columns.push({
      id: 'Count',
      accessorFn: e => e.Count,
      header: () => 'Count',
      size: 100,
      cell: d => <OverflowTooltip>{d.row.original.Count}</OverflowTooltip>
    });
  }

  if (item.Elements.find(e => e.Category != null && e.Category !== '') != null) {
    columns.push({
      id: 'Category',
      accessorFn: e => e.Category,
      header: () => 'Category',
      cell: d => <OverflowTooltip>{d.row.original.Category}</OverflowTooltip>
    });
  }

  if (item.Elements.find(e => e.Family != null && e.Family !== '') != null) {
    columns.push({
      id: 'Family',
      accessorFn: e => e.Family,
      header: () => 'Family',
      cell: d => <OverflowTooltip>{d.row.original.Family}</OverflowTooltip>
    });
  }

  if (item.Elements.find(e => e.Type != null && e.Type !== '') != null) {
    columns.push({
      id: 'Type',
      accessorFn: e => e.Type,
      header: () => 'Type',
      cell: d => <OverflowTooltip>{d.row.original.Type}</OverflowTooltip>
    });
  }

  if (item.Elements.find(e => e.Name != null && e.Name !== '') != null) {
    columns.push({
      id: 'Name',
      accessorFn: e => e.Name,
      header: () => 'Name',
      cell: d => <OverflowTooltip>{d.row.original.Name}</OverflowTooltip>
    });
  }

  if (item.Result !== CheckResultType.Report) {
    columns.push({
      id: 'ID',
      accessorFn: e => e.ID,
      header: () => 'Element ID',
      cell: d => <OverflowTooltip>{d.row.original.ID}</OverflowTooltip>
    });
  }

  if (item.ShowValues) {
    columns.push({
      id: 'Value',
      accessorFn: e => e.Value,
      header: () => 'Value',
      cell: d => <OverflowTooltip>{d.row.original.Value}</OverflowTooltip>
    });
  }

  return (
    <FlexRow>
      <div>
        <img src={ConvertResultImage.Convert(item.Result!)}
             style={{width: '26px', height: '26px', objectFit: 'contain', margin: '1em'}}
             alt={'Check Result'}/>
      </div>
      <FlexColumn>
        <StructureTitles>{item.Title}</StructureTitles>
        {item.Result === CheckResultType.Fail && <StructureTitles>{item.FailureMessage}</StructureTitles>}
        {item.Result === CheckResultType.Error && <StructureTitles>{item.ErrorMessage}</StructureTitles>}
        {item.ShowTotalCount && <StructureTitles>Count: {item.Count}</StructureTitles>}
        {
          item.ResultMessage != null && item.ResultMessage !== '' &&
            <StructureTitles>{item.ResultMessage}</StructureTitles>
        }
        {
          item.ShowList &&
            <Table<RevitElement>
                data={item.Elements}
                columns={columns}/>
        }
      </FlexColumn>
    </FlexRow>
  );
};

export default StructureDetailCheck;