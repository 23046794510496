////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, {useEffect, useState} from 'react';
import {Task} from '../dataModel/Task';
import {ProjectUI} from '../dataModel/ProjectUI';
import {GetDirectoryPathResult} from '../dataModel/GetDirectoryPathResult';
import {ModelService} from '../services/ModelService';
import {
  FindProjectItemRecursive,
  GetErrorMessage,
  GetRecursiveFilePath,
  GetUniqueProjectStructureData
} from '../Utility';
import {DirectoryUI} from '../dataModel/DirectoryUI';
import {CenteringContainer, LineWrapper, ValueWrapper} from '../CommonStyledComponents';
import {ConvertAutodeskSchedule} from '../converters/ConvertAutodeskSchedule';
import {ExportReportLocationType} from '../clients/Classes';
import {ConvertTrigger} from '../converters/ConvertTrigger';
import {ConvertRunDate} from '../converters/ConvertRunDate';
import {ConvertExportLocationType} from '../converters/ConvertExportLocationType';
import {ConvertFileDestinationNamingType} from '../converters/ConvertFileDestinationNamingType';
import {ConvertRevitVersion} from '../converters/ConvertRevitVersion';
import Theme from "@adsk/alloy-react-theme";
import ProgressRing from "@adsk/alloy-react-progress-ring";
import {FileUI} from "../dataModel/FileUI";

const fileService = new ModelService();

const SettingsSummary = ({task, projects, projectsLoading, onError}: {
  task: Task | undefined,
  projects: ProjectUI[],
  projectsLoading: boolean,
  onError?: (error: any, operation: string) => void
}) => {
  const [sourcePaths, setSourcePaths] = useState<string[]>([]);
  const [loadingPaths, setLoadingPaths] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (task == null) {
      return;
    }

    if (projectsLoading) {
      setLoadingPaths(true);
      return;
    }

    const allItems: (DirectoryUI | FileUI)[] = [];
    task.Directories.forEach(d => allItems.push(d));
    task.Models.forEach(d => allItems.push(d));

    const projectData: { projectId: string, directoryIds: string[] }[] = GetUniqueProjectStructureData(allItems);

    if (task.ExportProjectId != null) {
      const existing = projectData.find(d => d.projectId === task.ExportProjectId);
      if (existing == null) {
        projectData.push({
          projectId: task.ExportProjectId,
          directoryIds: [task.ExportDirectoryId!]
        });
      } else {
        if (!existing.directoryIds.includes(task.ExportDirectoryId!)) {
          existing.directoryIds.push(task.ExportDirectoryId!);
        }
      }
    }

    setLoadingPaths(true);
    const promises: Promise<GetDirectoryPathResult[]>[] = [];
    projectData.forEach(d => {
      const project = projects.find(p => p.Id === d.projectId);
      if (project == null) {
        return;
      }
      promises.push(fileService.PopulateToDirectories(project, d.directoryIds));
    });

    Promise.all(promises)
      .then(results => {
        if (!isMounted) {
          return;
        }

        const allResults: GetDirectoryPathResult[] = [];
        results.forEach(rs => {
          rs.forEach(r => allResults.push(r));
        });

        if (allResults.find(r => !r.Success) != null) {
          alert('There were errors loading paths, some paths may not display correctly');
        }

        const sources: string[] = [];

        task.Directories.forEach(d => {
          const project = projects.find(p => p.Id === d.ProjectId);
          const directory = project == null ? null : FindProjectItemRecursive(project, d.Id) as DirectoryUI;
          const path = directory == null ? null : GetRecursiveFilePath(directory, '/');
          sources.push(`${project?.Name}/${path}`);
        });

        task.Models.forEach(m => {
          const project = projects.find(p => p.Id === m.ProjectId);
          const directory = project == null ? null : FindProjectItemRecursive(project, m.DirectoryId) as DirectoryUI;
          const directoryPath = directory == null ? '' : GetRecursiveFilePath(directory, '/');
          sources.push(`${project?.Name}/${directoryPath}/${m.Name}`);
        });

        setSourcePaths(sources);

        allResults.forEach(r => {
          task.Directories.forEach(d => {
            const loaded = r.LoadedDirectories.find(l => l.Id === d.Id);
            if (loaded != null) {
              d.Name = loaded.Name;
            }
          });
        });

        const destinationProject = projects.find(p => p.Id === task?.ExportProjectId);
        const populatedDirectory = destinationProject == null || task.ExportDirectoryId == null
          ? undefined
          : FindProjectItemRecursive(destinationProject, task.ExportDirectoryId) as DirectoryUI;
        task.ExportDirectoryId = populatedDirectory?.Id;
        task.ExportProjectId = populatedDirectory?.ProjectId;

        setLoadingPaths(false);
      })
      .catch(e => {
        handleError(e, 'Get file paths');
        setLoadingPaths(false);
        const sources: string[] = [];
        task.Models.forEach(m => {
          sources.push(`${m.ProjectName}/[Error]/${m.Name}`);
        });
        setSourcePaths(sources);
      });

    return () => {
      isMounted = false;
    };
  }, [projects, task, projectsLoading]);

  function handleError(error: any, operation: string): void {
    if (onError) {
      onError(error, operation);
    }
  }

  return (
    <>
      {(task == null || projects == null || projects.length <= 0) && (
        <CenteringContainer style={{flex: 1}}>
          <ProgressRing size={'large'}/>
        </CenteringContainer>
      )}
      {task != null && projects != null && projects.length > 0 && (
        <div>
          <h2 style={Theme.typography.heading2}>Summary</h2>
          <ValueWrapper>
            <LineWrapper style={Theme.typography.bodyMediumBold}>Task Name</LineWrapper>
            <LineWrapper style={Theme.typography.bodyMedium}>{task?.Name}</LineWrapper>
          </ValueWrapper>
          <ValueWrapper>
            <LineWrapper style={Theme.typography.bodyMediumBold}>Revit Version</LineWrapper>
            <LineWrapper
              style={Theme.typography.bodyMedium}>{ConvertRevitVersion.Convert(task?.RevitVersion)}</LineWrapper>
          </ValueWrapper>
          <ValueWrapper>
            <LineWrapper style={Theme.typography.bodyMediumBold}>Source Files/Folders</LineWrapper>
            <LineWrapper style={Theme.typography.bodyMedium}>
              {loadingPaths && <ProgressRing/>}
              {!loadingPaths && sourcePaths.map(p => <LineWrapper key={p}>{p}</LineWrapper>)}
            </LineWrapper>
          </ValueWrapper>
          <ValueWrapper>
            <LineWrapper style={Theme.typography.bodyMediumBold}>Checkset</LineWrapper>
            <LineWrapper style={Theme.typography.bodyMedium}>{task?.Checkset?.name}</LineWrapper>
          </ValueWrapper>
          <ValueWrapper>
            <LineWrapper style={Theme.typography.bodyMediumBold}>Trigger</LineWrapper>
            <LineWrapper style={Theme.typography.bodyMedium}>
              {ConvertTrigger.Convert(task?.Trigger)}
              {task.Trigger === 'OnceLater' && ` - ${ConvertRunDate.Convert(task?.StartDate, undefined, true)}`}
              {task.Trigger === 'Recurring' && ` - ${task?.RecurrenceSettings == null ? '' : ConvertAutodeskSchedule.GetSummary(task.RecurrenceSettings)}`}
            </LineWrapper>
          </ValueWrapper>
          <ValueWrapper>
            <LineWrapper style={Theme.typography.bodyMediumBold}>Options</LineWrapper>
            {task?.ExportExcel &&
              <LineWrapper style={Theme.typography.bodyMedium}>Export
                XLSX{task.ExportLists && ', include list elements'}{task.CombineExcel && ', combine all reports'}</LineWrapper>}
            {task?.ExportHtml &&
              <LineWrapper style={Theme.typography.bodyMedium}>Export HTML</LineWrapper>}
            <LineWrapper style={Theme.typography.bodyMedium}>
              {ConvertExportLocationType.Convert(task?.ExportLocationType)}
              {task.ExportLocationType === ExportReportLocationType.OtherDirectory && ` - ${task.ExportLocation}`}
            </LineWrapper>
            <LineWrapper style={Theme.typography.bodyMedium}>
              Destination file
              naming: {ConvertFileDestinationNamingType.Convert(task?.ExportDestinationNaming)}
            </LineWrapper>
            {task?.EmailOnCompletion &&
              <LineWrapper style={Theme.typography.bodyMedium}>
                Email me when complete{task?.AttachExportFiles && ' and attach reports to email'}
              </LineWrapper>}
          </ValueWrapper>
        </div>
      )}
    </>
  );
};

export default SettingsSummary;
