////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import { FileUI } from './FileUI';
import { Heading } from './Heading';
import { ReportSummarizable } from './ReportSummarizable';

export class FileReport extends ReportSummarizable {
    Headings: Heading[];
    Model: FileUI;

    constructor(model: FileUI) {
        super();
        this.Model = model;
        this.Headings = [];
    }
}
