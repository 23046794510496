////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, {ReactElement, useEffect, useReducer, useState} from 'react';
import {CustomerService} from '../services/CustomerService';
import {reducer} from '../components/reducers/CustomersReducer';
import {CustomersState} from '../components/states/CustomersState';
import {CustomersActions} from '../Enums';
import {
  BlueButton, CenteringContainer,
  ColumnLeft,
  ColumnRight,
  ContentWrapper,
  FlexColumn, FlexColumnCentered, FlexFill,
  FlexRow,
  FlexRowCentered
} from '../CommonStyledComponents';
import {CustomerUI} from '../dataModel/CustomerUI';
import {GetErrorMessage, ListToString, StringToList} from '../Utility';
import {EditCustomerRequest} from '../clients/Classes';
import {CustomerTranslator} from "../dataModel/translators/CustomerTranslator";
import {CancellationToken} from "../dataModel/CancellationToken";
import PartialLoadWarning from "../components/ParitalLoadWarning";
import {PageSizeService} from "../services/PageSizeService";
import {BasicButton, IconButton, LinkButton} from "@adsk/alloy-react-button";
import {ArrowRotateTwoIcon, PencilIcon, PlusIcon} from "@adsk/alloy-react-icon";
import Theme from "@adsk/alloy-react-theme";
import Modal from "@adsk/alloy-react-modal";
import TextInput from '@adsk/alloy-react-text-input';
import SearchField from "@adsk/alloy-react-search-field";
import ProgressRing from "@adsk/alloy-react-progress-ring";
import Illustration from "@adsk/alloy-react-illustration";
import Tooltip from "@adsk/alloy-react-tooltip";
import Checkbox from '@adsk/alloy-react-checkbox';
import {ActionButton} from "@adsk/alloy-react-menu";
import Textarea from "@adsk/alloy-react-textarea";
import Table from '../components/Table';
import {LoadMoreDataRow} from '@adsk/alloy-react-table';

const service = new CustomerService();

const pageSize = PageSizeService.GetPageSize('customers');
let paginationToken: string | undefined = undefined;
const cancelToken = new CancellationToken();

const Customers = () => {
  const [state, dispatch] = useReducer(reducer, new CustomersState());
  // Need to use 'useState' here because something is broken in the search field component with state binding
  const [search, setSearch] = useState('');

  useEffect(() => {
    loadCustomers(true, false);
  }, []);

  function loadCustomers(isFirstLoad: boolean, loadAll: boolean): void {
    dispatch({
      type: CustomersActions.multipleActions,
      payload: {
        loading: isFirstLoad || loadAll,
        loadingMoreData: !loadAll && !isFirstLoad,
        canCancelLoad: loadAll
      }
    });

    if (isFirstLoad) {
      paginationToken = undefined;
    }

    cancelToken.Cancel = false;

    const promise = loadAll
      ? service.GetRemainingCustomers(paginationToken, pageSize, cancelToken)
      : service.GetCustomers(paginationToken, pageSize);

    promise
      .then(paginationData => {
        paginationToken = paginationData.paginationData?.paginationToken;
        const customers = paginationData.items!.map(customer => CustomerTranslator.TranslateCustomer(customer));
        if (!isFirstLoad) {
          state.customers.forEach(c => customers.push(c));
        }
        dispatch({
          type: CustomersActions.multipleActions,
          payload: {
            customers: customers,
            filteredCustomers: customers,
            loading: false,
            loadingMoreData: false,
            hasMoreData: !paginationData.isDone
          }
        });
      })
      .catch(error => onError(error, 'Get Customers'));
  }

  function startAddCustomer(): void {
    dispatch({
      type: CustomersActions.multipleActions, payload: {
        editingCustomer: new CustomerUI('', '', '', false),
        showEditDialog: true,
        editHeader: 'Add Customer',
      }
    });
  }

  function startEditCustomer(customer: CustomerUI): void {
    dispatch({
      type: CustomersActions.multipleActions, payload: {
        editingCustomer: {...customer},
        showEditDialog: true,
        editHeader: 'Edit Customer',
      }
    });
  }

  function finishAddCustomer(customer: CustomerUI): void {
    dispatch({type: CustomersActions.showEdit, payload: false});

    service.CreateCustomer(
      customer.Name,
      customer.EmailDomain,
      customer.AllowAllUsers,
      customer.ValidEmails)
      .then(
        c => {
          if (c != null) {
            state.customers.push(c);
            dispatch({type: CustomersActions.customers, payload: [...state.customers]});
            updateFilteredData(undefined, state.customers);
          }
        })
      .catch(err => onError(err, 'Create customer'));
  }

  function finishEditCustomer(customer: CustomerUI): void {
    dispatch({type: CustomersActions.showEdit, payload: false});

    const existingCustomer = state.customers.find(c => c.ID === customer.ID);
    if (existingCustomer == null) {
      alert('Error: Existing Customer Not Found');
      return;
    }
    const dto = new EditCustomerRequest();
    if (existingCustomer.Name !== customer.Name.trim()) {
      dto.name = customer.Name.trim();
    }

    if (existingCustomer.AllowAllUsers !== customer.AllowAllUsers) {
      dto.allowAllUsers = customer.AllowAllUsers;
    }

    const addEmails: string[] = [];
    const removeEmails: string[] = [];

    for (const email of customer.ValidEmails) {
      if (email == null || email.trim() === '') {
        continue;
      }
      const found = existingCustomer.ValidEmails.find(e => e === email);
      if (found == null) {
        addEmails.push(email);
      }
    }

    for (const email of existingCustomer.ValidEmails) {
      const found = customer.ValidEmails.find(e => e === email);
      if (found == null) {
        removeEmails.push(email);
      }
    }

    if (addEmails.length > 0) {
      dto.addValidEmails = addEmails;
    }

    if (removeEmails.length > 0) {
      dto.removeValidEmails = removeEmails;
    }

    service.UpdateCustomer(customer.ID, dto)
      .then(
        () => {
          existingCustomer.Name = customer.Name.trim();
          existingCustomer.ValidEmails = customer.ValidEmails;
          existingCustomer.AllowAllUsers = customer.AllowAllUsers;
          dispatch({type: CustomersActions.customers, payload: [...state.customers]});
          updateFilteredData(undefined, state.customers);
        })
      .catch(err => onError(err, 'Edit customer'));
  }

  function finishDeleteCustomer(customer: CustomerUI): void {
    dispatch({type: CustomersActions.showDelete, payload: false});

    service.DeleteCustomer(customer.ID)
      .then(
        () => {
          const index = state.customers.indexOf(customer);
          if (index === -1) {
            return;
          }

          state.customers.splice(index, 1);
          dispatch({type: CustomersActions.customers, payload: [...state.customers]});
          updateFilteredData(undefined, state.customers);
        })
      .catch(err => onError(err, 'Delete customer'));
  }

  function handleAction(action: string, customer: CustomerUI): void {
    switch (action) {
      case 'delete':
        dispatch({
          type: CustomersActions.multipleActions, payload: {
            editingCustomer: customer,
            showDeleteConfirm: true,
          }
        });
        break;
    }
  }

  function customerEdited(f: () => void): void {
    f();
    dispatch({type: CustomersActions.editingCustomer, payload: state.editingCustomer});
  }

  function onError(error: any, operation: string): void {
    alert(GetErrorMessage(error, operation));
  }

  function renderActionCell(customer: CustomerUI): ReactElement {
    return (
      <FlexRow>
        <Tooltip content={'Edit this customer'}>
          <BasicButton onClick={() => startEditCustomer(customer)}>
            <PencilIcon/>
          </BasicButton>
        </Tooltip>
        <ActionButton style={{borderWidth: 0}}
                      horizontal={false}
                      options={[
                        {key: 'delete', label: 'Delete'},
                      ]} onChange={e => handleAction(e[0], customer)}/>
      </FlexRow>
    );
  }

  function searchChanged(e: any): void {
    setSearch(e);
    updateFilteredData(e);
  }

  function updateFilteredData(searchOverride?: string, customerOverride?: CustomerUI[]): void {
    const actualSearch = searchOverride ?? search;
    const actualCustomers = customerOverride ?? state.customers;

    const filteredCustomers = actualCustomers.filter(r => {
      return actualSearch == null
        || actualSearch === ''
        || r.Name?.toLowerCase().includes(actualSearch.toLowerCase());
    });

    dispatch({
      type: CustomersActions.filteredCustomers,
      payload: filteredCustomers
    });
  }

  return (
    <ContentWrapper>
      <FlexRowCentered>
        <h1 style={Theme.typography.heading1}>Customers</h1>
        {
          state.hasMoreData && state.customers.length > 0 &&
          <PartialLoadWarning pageSize={pageSize}
                              onLoadAll={() => loadCustomers(false, true)}/>
        }
      </FlexRowCentered>
      <FlexRowCentered style={{padding: '0.5em 0', flex: 0}}>
        <BlueButton style={{marginBottom: '2em'}} onClick={startAddCustomer}>
          <FlexRowCentered>
            <PlusIcon style={{marginRight: '0.5em'}}/>
            <span style={Theme.typography.labelMedium}>Add Customer</span>
          </FlexRowCentered>
        </BlueButton>
        <FlexFill/>
        <Tooltip content={'Refresh Customers'}>
          <IconButton
            onClick={() => loadCustomers(true, false)}
            renderIcon={() => <ArrowRotateTwoIcon/>}
            style={{marginRight: '1em'}}/>
        </Tooltip>
        <SearchField value={search} onChange={searchChanged} placeholder={'Search customers...'}
                     style={{width: '300px', marginRight: '1em'}}/>
      </FlexRowCentered>
      {
        !state.loading &&
        <FlexColumn style={{flex: 1}}>
          <Table<CustomerUI>
            columns={[
              {
                id: 'Name',
                accessorFn: c => c.Name.toLowerCase(),
                header: () => 'Name',
                cell: ({row}) => row.original.Name
              },
              {
                accessorKey: 'EmailDomain',
                header: () => 'Domain',
              },
              {
                accessorKey: 'ValidEmails',
                header: () => 'Valid Emails',
                cell: (d) => {
                  return d.row.original.AllowAllUsers ? '<Any>' : d.row.original.ValidEmails?.join(', ') ?? '';
                }
              },
              {
                id: 'actions',
                size: 30,
                header: () => 'Actions',
                cell: (d) => renderActionCell(d.row.original)
              },
            ]}
            data={state.filteredCustomers}
            renderLastRow={() => state.hasMoreData &&
              <LoadMoreDataRow
                isLoading={state.loadingMoreData}
                onLoad={async () => loadCustomers(false, false)}
                renderLoadMore={() =>
                  <FlexRowCentered>
                    <LinkButton onClick={() => loadCustomers(false, false)}>
                      <span style={Theme.typography.bodySmall}>Load more</span>
                    </LinkButton>
                    <LinkButton onClick={() => loadCustomers(false, true)} style={{marginLeft: '1em'}}>
                      <span style={Theme.typography.bodySmall}>Load all</span>
                    </LinkButton>
                  </FlexRowCentered>
                }/>
            }/>
        </FlexColumn>
      }
      {
        state.loading &&
        (<CenteringContainer>
          <FlexColumnCentered>
            <ProgressRing size={'large'}/>
            {
              state.canCancelLoad &&
              <LinkButton onClick={() => cancelToken.Cancel = true}>Cancel</LinkButton>
            }
          </FlexColumnCentered>
        </CenteringContainer>)
      }
      {
        !state.loading && state.customers.length === 0 &&
        <CenteringContainer style={{flexDirection: 'column'}}>
          <Illustration type={'pagesTextGrey'} height={200} width={200}/>
          <p style={Theme.typography.bodyLarge}>No Customers to Show</p>
        </CenteringContainer>
      }
      <Modal open={state.showEditDialog}>
        <Modal.Header>
          {state.editHeader}
        </Modal.Header>
        <Modal.Body>
          <FlexColumn style={{height: '100%'}}>
            <p style={Theme.typography.bodyMediumBold}>Edit the details of this customer.</p>
            <FlexRowCentered>
              <ColumnLeft>
                <Tooltip content={'The display name of the customer'}>
                  Organization
                </Tooltip>
              </ColumnLeft>
              <ColumnRight>
                <TextInput value={state.editingCustomer?.Name}
                           onChange={e => customerEdited(() => state.editingCustomer!.Name = e.target.value)}/>
              </ColumnRight>
            </FlexRowCentered>
            <FlexRowCentered>
              <ColumnLeft style={{alignSelf: 'baseline'}}>
                <Tooltip
                  content={'The email domain used to identify customers (i.e. somewhere.com).  Note that this needs to match the email domain on their BIM 360 / Autodesk Construction Cloud account.'}>
                  Domain
                </Tooltip>
              </ColumnLeft>
              <ColumnRight>
                <FlexColumn>
                  <TextInput value={state.editingCustomer?.EmailDomain}
                             disabled={state.editHeader.toLowerCase().includes('edit')}
                             onChange={v => customerEdited(() => state.editingCustomer!.EmailDomain = v.target.value)}/>
                  <FlexRowCentered style={{marginTop: '0.5em'}}>
                    <Checkbox
                      checked={state.editingCustomer?.AllowAllUsers}
                      onChange={e => customerEdited(() => state.editingCustomer!.AllowAllUsers = e === true)}/>
                    <label style={{marginLeft: '0.5em'}}>Allow all users in this domain</label>
                  </FlexRowCentered>
                </FlexColumn>
              </ColumnRight>
            </FlexRowCentered>
            <FlexRow>
              <ColumnLeft style={{alignSelf: 'auto'}}>
                <Tooltip content={'The whitelisted emails for this domain.  Enter in comma delimited format'}>
                  Valid Emails
                </Tooltip>
              </ColumnLeft>
              <ColumnRight>
                <Textarea
                  defaultValue={state.editingCustomer == null ? '' : ListToString(state.editingCustomer.ValidEmails)}
                  disabled={state.editingCustomer?.AllowAllUsers}
                  onBlur={v => customerEdited(() => state.editingCustomer!.ValidEmails = StringToList(v.target.value))}
                  style={{width: '100%', minHeight: '100%'}}/>
              </ColumnRight>
            </FlexRow>
          </FlexColumn>
        </Modal.Body>
        <Modal.Footer>
          <FlexRow style={{justifyContent: 'end'}}>
            <BlueButton
              disabled={
                state.editingCustomer?.EmailDomain == null
                || state.editingCustomer?.EmailDomain === ''
                || state.editingCustomer?.Name == null
                || state.editingCustomer?.Name === ''
                || (!state.editingCustomer?.AllowAllUsers && (state.editingCustomer?.ValidEmails == null || state.editingCustomer?.ValidEmails.length === 0))
              }
              onClick={() =>
                state.editHeader.toLowerCase().includes('add')
                  ? finishAddCustomer(state.editingCustomer!)
                  : finishEditCustomer(state.editingCustomer!)
              }>
              <FlexRowCentered>
                <span style={Theme.typography.labelMedium}>OK</span>
              </FlexRowCentered>
            </BlueButton>
            <BlueButton style={{marginLeft: '1em'}}
                        onClick={() => dispatch({type: CustomersActions.showEdit, payload: false})}>
              <FlexRowCentered>
                <span style={Theme.typography.labelMedium}>Cancel</span>
              </FlexRowCentered>
            </BlueButton>
          </FlexRow>
        </Modal.Footer>
      </Modal>
      <Modal open={state.showDeleteConfirm}>
        <Modal.Header>Delete Customer?</Modal.Header>
        <Modal.Body>
          <p style={Theme.typography.bodyMediumBold}>Are you sure you want to permanently delete the customer?</p>
          <p style={Theme.typography.bodyMedium}>Customer will not have access to Validation Tool anymore without being
            re-added to the system.</p>
        </Modal.Body>
        <Modal.Footer>
          <FlexRow style={{justifyContent: 'end'}}>
            <BlueButton onClick={() => finishDeleteCustomer(state.editingCustomer!)}>
              <FlexRowCentered>
                <span style={Theme.typography.labelMedium}>Yes</span>
              </FlexRowCentered>
            </BlueButton>
            <BlueButton style={{marginLeft: '1em'}}
                        onClick={() => dispatch({type: CustomersActions.showDelete, payload: false})}>
              <FlexRowCentered>
                <span style={Theme.typography.labelMedium}>No</span>
              </FlexRowCentered>
            </BlueButton>
          </FlexRow>
        </Modal.Footer>
      </Modal>
    </ContentWrapper>
  );
};

export default Customers;