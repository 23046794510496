////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {WebhookSettingsState} from '../states/WebhookSettingsState';
import {WebhookSettingsActions} from '../../Enums';

export function reducer(state: WebhookSettingsState, action: { type: WebhookSettingsActions, payload: any }): WebhookSettingsState {
  switch (action.type) {
    case WebhookSettingsActions.multipleActions:
      return {...state, ...action.payload};
    case WebhookSettingsActions.loading:
      return {...state, Loading: action.payload};
    case WebhookSettingsActions.webHooks:
      return {...state, WebHooks: action.payload};
    case WebhookSettingsActions.editingHook:
      return {...state, EditingHook: action.payload};
    case WebhookSettingsActions.showEdit:
      return {...state, ShowEdit: action.payload};
    case WebhookSettingsActions.showDelete:
      return {...state, ShowDelete: action.payload};
    case WebhookSettingsActions.showDeleteAll:
      return {...state, ShowDeleteAll: action.payload};
    case WebhookSettingsActions.showResponseDetail:
      return {...state, ShowResponseDetail: action.payload};
    case WebhookSettingsActions.showSampleBody:
      return {...state, ShowSampleBody: action.payload};
    case WebhookSettingsActions.responseDetail:
      return {...state, ResponseDetail: action.payload};
    case WebhookSettingsActions.sampleBody:
      return {...state, SampleBody: action.payload};
    case WebhookSettingsActions.editIsNew:
      return {...state, EditIsNew: action.payload};
    case WebhookSettingsActions.editName:
      return {...state, EditName: action.payload};
    case WebhookSettingsActions.editUrl:
      return {...state, EditUrl: action.payload};
    case WebhookSettingsActions.editSecret:
      return {...state, EditSecret: action.payload};
    case WebhookSettingsActions.editEnabled:
      return {...state, EditEnabled: action.payload};
    case WebhookSettingsActions.loadingMoreWebhooks:
      return {...state, LoadingMoreWebhooks: action.payload};
    case WebhookSettingsActions.hasMoreWebhooks:
      return {...state, HasMoreWebhooks: action.payload};
    case WebhookSettingsActions.canCancelLoad:
      return {...state, canCancelLoad: action.payload};
  }
}
