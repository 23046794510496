////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {CheckSet, CheckSetSource} from '../../clients/Classes';

export class ChecksetTranslator {
  static TranslatePublicCheckset(baseObject: any): CheckSet | undefined {
    if (baseObject == null) {
      return undefined;
    }

    return new CheckSet({
      id: undefined,
      author: baseObject['Author'],
      lastUpdate: new Date(baseObject['Date']),
      description: baseObject['Description'],
      url: baseObject['FilePath'],
      imagePath: baseObject['ImagePath'],
      name: baseObject['Title'],
      source: CheckSetSource.Url,
    });
  }
}
