////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {BIM360ItemBase} from './BIM360ItemBase';
import {FileType, HubType, ProjectType, StorageType} from '../clients/Classes';
import {ProjectNameLoadBase} from './ProjectNameLoadBase';

export class FileUI extends ProjectNameLoadBase implements BIM360ItemBase {
  // Interface Implementation
  public IsFolder = false;
  Id: string;
  ParentId: string | undefined;
  ProjectId: string;
  Name: string;

  // Class Properties
  Icon: string | undefined;
  Version: string | undefined;
  LastUpdate: Date | undefined;
  UpdateBy: string | undefined;

  FileSize: number | undefined;

  DirectoryId: string;
  FileType: FileType;
  LastChange: Date | undefined;

  HubId: string | undefined;
  HubRegion: string | undefined;
  HubType: HubType;
  ProjectType: ProjectType;

  FullPath: string | undefined;

  IsProcessed: boolean = false;

  StorageType: StorageType;

  constructor(
    id: string,
    name: string,
    projectId: string,
    directoryId: string,
    fileType: FileType,
    storageType: StorageType
  ) {
    super();
    this.Id = id;
    this.Name = name;
    this.ProjectId = projectId;
    this.DirectoryId = directoryId;
    this.FileType = fileType;

    this.StorageType = storageType;

    this.HubType = HubType.Unknown;
    this.ProjectType = ProjectType.Unknown;
  }
}
