////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React from 'react';
import styled from "styled-components";
import { FlexRowCentered, FlexFill } from "../CommonStyledComponents";
import {OpenIcon, QuestionCircleFilledIcon} from "@adsk/alloy-react-icon";
import {Flyout} from "@adsk/alloy-react-tooltip";
import Divider from "@adsk/alloy-react-divider";
import {MenuItem, MenuList} from "@adsk/alloy-react-menu";

const ItemWrapper = styled(FlexRowCentered)`
  width: 100%;
  padding: 10px 16px;
  text-align: left;
`;

const StyledOpenIcon = styled(OpenIcon)`
  margin-left: 16px;
`;

const InfoMenu = () => {
    return (
        <Flyout
            trigger='click'
            offset={{mainAxis: 20, crossAxis: 0}}
            style={{ padding: 0 }}
            placement={Flyout.PLACEMENTS.BOTTOM}
            content={
                <MenuList style={{ width: 300 }}>
                    <MenuItem
                        as="a"
                        href={'https://help.autodesk.com/view/VTDOCS/ENU/'}
                        target="_blank"
                        rel="noreferrer noopener"
                        showSelection={false}
                        style={{
                            justifyContent: 'space-between',
                            padding: '0',
                        }}
                    >
                        <ItemWrapper>
                            <FlexFill>Help</FlexFill>
                            <StyledOpenIcon size={16} />
                        </ItemWrapper>
                    </MenuItem>
                    <MenuItem
                        as="a"
                        href={'https://www.youtube.com/playlist?list=PL0RZIBv0pCfvY_XVzWiKfQiOqCeY2F3Ei'}
                        target="_blank"
                        rel="noreferrer noopener"
                        showSelection={false}
                        style={{
                            justifyContent: 'space-between',
                            padding: '0',
                        }}
                    >
                        <ItemWrapper>
                            <FlexFill>Learning on YouTube</FlexFill>
                            <StyledOpenIcon size={16} />
                        </ItemWrapper>
                    </MenuItem>
                    <Divider />
                    <MenuItem
                        as="a"
                        href={'https://knowledge.autodesk.com/contact-support'}
                        target="_blank"
                        rel="noreferrer noopener"
                        showSelection={false}
                        style={{
                            justifyContent: 'space-between',
                            padding: '0',
                        }}
                    >
                        <ItemWrapper>
                            <FlexFill>Contact Support</FlexFill>
                            <StyledOpenIcon size={16} />
                        </ItemWrapper>
                    </MenuItem>
                    <Divider />
                    <MenuItem
                        as="a"
                        href={'https://www.autodesk.com/company/terms-of-use/en/general-terms'}
                        target="_blank"
                        rel="noreferrer noopener"
                        showSelection={false}
                        style={{
                            justifyContent: 'space-between',
                            padding: '0',
                        }}
                    >
                        <ItemWrapper>
                            <FlexFill>Legal</FlexFill>
                            <StyledOpenIcon size={16} />
                        </ItemWrapper>
                    </MenuItem>
                    <MenuItem
                        as="a"
                        href={'https://www.autodesk.com/company/legal-notices-trademarks/privacy-statement'}
                        target="_blank"
                        rel="noreferrer noopener"
                        showSelection={false}
                        style={{
                            justifyContent: 'space-between',
                            padding: '0',
                        }}
                    >
                        <ItemWrapper>
                            <FlexFill>Privacy</FlexFill>
                            <StyledOpenIcon size={16} />
                        </ItemWrapper>
                    </MenuItem>
                </MenuList>
            }>
            <QuestionCircleFilledIcon size={16} style={{ cursor: 'pointer' }} />
        </Flyout>
    );
};

export default InfoMenu;