////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {
  AvtItemReportSummaryDto,
  AvtReportDtoBase,
  AvtReportSummaryDto,
  ExportResultDto,
  ExportResultFormatDto,
} from "../clients/V2Classes";
import {GetDateWithOffset} from "../Utility";
import {
  Job,
  JobFile,
  JobRun,
  JobRunItem,
  JobRunItemStatusType,
  JobRunStatusType,
  JobScheduleType,
  JobStatusType,
  JobType
} from "../clients/Classes";
import {SampleDataProvider} from "./SampleDataProvider";

export class SampleReportData {
  private readonly defaultImage = 'https://revolutiondesign.biz/wp-content/uploads/2017/10/logo.png';

  private readonly _data = SampleDataProvider.SampleDataV1;

  public readonly sampleJobs: Job[];
  public readonly sampleJobRuns: JobRun[];
  public readonly sampleJobRunItems: JobRunItem[];
  public readonly sampleReportSummaries: AvtReportSummaryDto[];
  public readonly sampleItemReportSummaries: AvtItemReportSummaryDto[];
  public readonly sampleExportResultIncomplete: ExportResultDto;
  public readonly sampleExportResultComplete: ExportResultDto;
  public readonly sampleExportResultFailed: ExportResultDto;

  constructor() {
    this.sampleJobs = this.GetJobs(50, 3);
    this.sampleJobRuns = [];
    this.sampleJobRunItems = [];
    this.sampleItemReportSummaries = [];
    this.sampleReportSummaries = [];

    this.sampleExportResultComplete = new ExportResultDto({
      exports: {
        'csv': new ExportResultFormatDto({
          format: 'csv',
          fileName: 'sampleFile.csv',
          exportUri: this.defaultImage,
          bim360ItemId: 'b360Item123',
          bim360ProjectId: 'b360project123'
        })
      },
      reportId: 'rpt123',
      isReady: true,
      statusId: 'status123'
    });

    this.sampleExportResultIncomplete = new ExportResultDto({
      exports: {},
      reportId: 'rpt123',
      isReady: false,
      statusId: 'status123'
    });

    this.sampleExportResultFailed = new ExportResultDto({
      exports: {
        'csv': new ExportResultFormatDto({
          format: 'csv',
          bim360ItemId: 'b360Item123',
          bim360ProjectId: 'b360project123',
          failureMessage: 'something failed',
        })
      },
      reportId: 'rpt123',
      isReady: false,
      statusId: 'status123'
    });

    this.sampleJobs.forEach(j => {
      const runs = this.GetJobRuns(j, 3);
      runs.forEach(r => {
        this.sampleJobRuns.push(r);
        r.jobRunItems?.forEach(i => {
          this.sampleJobRunItems.push(i);
        });
        const itemSummaries = this.GetReportItemSummaries(r);
        itemSummaries.forEach(is => {
          this.sampleItemReportSummaries.push(is);
        });
      });

      const summaries = this.GetReportSummaries(j, runs);
      summaries.forEach(s => {
        this.sampleReportSummaries.push(s);
      })
    })
  }

  private GetReportSummaries(job: Job, jobRuns: JobRun[]): AvtReportSummaryDto[] {
    const reports: AvtReportSummaryDto[] = [];

    jobRuns.forEach(r => {
      reports.push(new AvtReportSummaryDto({
        reportId: this._data.GetId(),
        startTime: r.startTime,
        endTime: new Date(),
        jobName: job.name,
        jobId: job.id,
        jobRunId: r.id,
        status: r.status,
        userId: r.userId,
        email: `fake@email.com`,
        customerId: job.customerId,
        totalFiles: job.models?.length,
        successFiles: job.models?.length,
        failedFiles: 0,
      }))
    });

    return reports;
  }

  private GetReportItemSummaries(jobRun: JobRun): AvtItemReportSummaryDto[] {
    const items: AvtItemReportSummaryDto[] = [];

    jobRun.jobRunItems!.forEach(r => {
      items.push(new AvtItemReportSummaryDto({
        jobRunItemId: r.id,
        status: r.status,
        file: r.file,
        model: r.file,
        summary: undefined,
        avtSummary: new AvtReportSummaryDto({
          reportId: this._data.GetId(),
          reportKey: this._data.GetId(),
          totalFiles: 5,
          successFiles: 5,
          failedFiles: 1,
          pendingFiles: 1,
          startTime: GetDateWithOffset(-4),
          endTime: GetDateWithOffset(-3),
          jobName: `Job ${r.jobId}`,
          jobId: r.jobId,
          jobRunId: r.jobRunId,
          status: JobRunStatusType.Success,
          userId: r.userId,
          email: 'fake@email.com',
          customerId: r.customerId,
          files: [],
          reportUri: 'https://fake/uri',
          isCombined: false,
          runDate: GetDateWithOffset(-4),
          checkSetId: this._data.GetId(),
          summaryInfo: new AvtReportDtoBase({
            id: this._data.GetId(),
            passPercent: 100,
            failChecks: 0,
            countChecks: 20,
            passChecks: 0,
            totalChecks: 24,
            skippedChecks: 4,
            errorChecks: 0
          })
        })
      }))
    })

    return items;
  }

  private GetJobs(quantity: number, modelQuantity: number): Job[] {
    const jobs: Job[] = [];
    for (let i = 0; i < quantity; i++) {
      const models: JobFile[] = [];
      for (let m = 0; m < modelQuantity; m++) {
        models.push(this.GetSampleModel(`Sample Model ${i}.${m}`));
      }

      jobs.push(new Job({
        id: this._data.GetId(),
        name: `Sample Job ${i}`,
        userId: this._data.user.id,
        customerId: this._data.user.customer?.id,
        models: models,
        directories: [],
        scheduleType: JobScheduleType.OnceLater,
        schedule: undefined,
        lastRun: undefined,
        nextRun: undefined,
        status: JobStatusType.Scheduled,
        jobType: JobType.DesignAutomation,
      }));
    }

    return jobs;
  }

  private GetJobRuns(job: Job, quantity: number): JobRun[] {
    const runs: JobRun[] = [];
    for (let i = 0; i < quantity; i++) {
      const run = new JobRun({
        id: this._data.GetId(),
        jobId: job.id,
        userId: job.userId,
        startTime: new Date(),
        status: JobRunStatusType.Success
      });
      run.jobRunItems = this.GetJobRunItems(run, job.customerId!, job.models!);
      runs.push(run);
    }

    return runs;
  }

  private GetSampleModel(name: string): JobFile {
    return new JobFile({
      id: this._data.GetId(),
      name: name,
      thumbnail: 'some image path',
      lastUpdated: GetDateWithOffset(-1),
      lastUpdatedBy: 'sample user',
      versionNumber: 1,
    });
  }

  private GetJobRunItems(jobRun: JobRun, customerId: string, models: JobFile[]): JobRunItem[] {
    const items: JobRunItem[] = [];
    for (let i = 0; i < models.length; i++) {
      items.push(new JobRunItem({
        id: this._data.GetId(),
        jobRunId: jobRun.id,
        jobId: jobRun.jobId,
        customerId: customerId,
        userId: jobRun.userId,
        file: models[i],
        status: JobRunItemStatusType.Success,
        runStartTime: new Date().toString(),
        runStartTimeNum: new Date().getTime(),
        errorType: undefined
      }));
    }

    return items;
  }
}
