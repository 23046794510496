////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {CheckSet, EditCheckSetRequest} from "../clients/Classes";
import {ChecksetTranslator} from "../dataModel/translators/ChecksetTranslator";
import {ClientProvider} from "../clients/ClientProvider";
import {ServiceBase} from "./ServiceBase";
import {ChecksetPaginatedResult} from "../clients/V2Classes";
import {IClient} from "../clients/Client";
import {IV2Client} from "../clients/V2Client";

export class CheckSetService extends ServiceBase {
  private _client: IClient = ClientProvider.Client;
  private _v2Client: IV2Client = ClientProvider.V2Client;

  public GetPublicChecksets(): Promise<CheckSet[]> {
    const fetchData = {
      method: 'POST',
      headers: {
        Accept: 'application/x-www-form-urlencoded',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: 'f=get&sw=MC',
    }

    return fetch(process.env.REACT_APP_PUBLIC_CONFIG_PROVIDER!, fetchData).then(response => {
      if (response.status !== 200) {
        throw Error(response.statusText);
      }
      return response.json()
        .then(responseObject => {
          return responseObject.ResultContent.map((o: any) => ChecksetTranslator.TranslatePublicCheckset(o))
        });
    });
  }

  public DeleteCheckSet(id: string): Promise<void> {
    return this.TryPromiseWithCatch(() =>
      this._client.deleteCheckSet(id)
    );
  }

  public EditCheckSet(id: string, changes: EditCheckSetRequest): Promise<CheckSet> {
    return this.TryPromiseWithCatch(() =>
      this._client.updateCheckSet(id, changes)
    );
  }

  public GetPrivateChecksets(paginationToken?: string | null | undefined, limit?: number | undefined): Promise<ChecksetPaginatedResult> {
    return this.TryPromiseWithCatch(() =>
      this.GetResultsWithItemOrToEnd(t => this._v2Client.listCheckSets(false, t, limit), paginationToken)
        .then(r => new ChecksetPaginatedResult(r))
    );
  }

  public CreateCheckSet(file: File): Promise<CheckSet> {
    return this.TryPromiseWithCatch(() =>
      this._client.createCheckSetFromFile(undefined, undefined, undefined, [{
        fileName: file.name,
        data: file
      }])
    );
  }
}