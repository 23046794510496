////////////////////////////////////////////////////////////////////////////////
//                                                                             /
//                                                                             /
//  (C) Copyright 2024 Autodesk, Inc. All rights reserved.                     /
//                                                                             /
//                       ****  CONFIDENTIAL MATERIAL  ****                     /
//                                                                             /
//  The information contained herein is confidential, proprietary to           /
//  Autodesk, Inc., and considered a trade secret.  Use of this information    /
//  by anyone other than authorized employees of Autodesk, Inc. is granted     /
//  only under a written nondisclosure agreement, expressly prescribing        /
//  the scope and manner of such use.                                          /
//                                                                             /
////////////////////////////////////////////////////////////////////////////////

import {ProjectWiseConfiguration} from "../clients/Classes";

export class ProjectWiseConfigurationUI {
  ApiConfiguration: ProjectWiseConfiguration;
  CredentialKey: string | undefined;

  constructor(config: ProjectWiseConfiguration) {
    this.ApiConfiguration = config;
  }
}