////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {CustomersActions} from "../../Enums";
import {CustomersState} from "../states/CustomersState";

export function reducer(state: CustomersState, action: { type: CustomersActions, payload: any }): CustomersState {
  switch (action.type) {
    case CustomersActions.loading:
      return {...state, loading: action.payload};
    case CustomersActions.editHeader:
      return {...state, editHeader: action.payload};
    case CustomersActions.editingCustomer:
      return {...state, editingCustomer: action.payload};
    case CustomersActions.showEdit:
      return {...state, showEditDialog: action.payload};
    case CustomersActions.showDelete:
      return {...state, showDeleteConfirm: action.payload};
    case CustomersActions.multipleActions:
      return {...state, ...action.payload};
    case CustomersActions.customers:
      return {...state, customers: action.payload};
    case CustomersActions.loadingMoreData:
      return {...state, loadingMoreData: action.payload};
    case CustomersActions.hasMoreData:
      return {...state, hasMoreData: action.payload};
    case CustomersActions.filteredCustomers:
      return {...state, filteredCustomers: action.payload};
    case CustomersActions.canCancelLoad:
      return {...state, canCancelLoad: action.payload};
  }
}