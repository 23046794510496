////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

export enum PageTypes {
  protected,
  auth,
  customers,
  usage,
  settings,
}

export type TaskTrigger =
  'OnceNow' |
  'OnceLater' |
  'Recurring' |
  'OnPublish';

export type ReportsFunctions =
  'downloadHtml' |
  'downloadExcel' |
  'downloadExcelCombined' |
  'downloadAvt' |
  'delete' |
  'open';

export type ReportFormats =
  'Excel' |
  'Html' |
  'AVT';

export type PaginationPages =
  'reports'
  | 'webhooks'
  | 'customers'
  | 'jobs'
  | 'tasks'
  | 'usage'
  | 'checkset'
  | 'projectWiseCredentials'
  | 'projectWiseConfigurations';

export type ProjectWiseActionTypes = 'edit' | 'delete' | 'duplicate';

export enum TasksActions {
  'multipleActions',
  'tasks',
  'filterOpen',
  'filteredTasks',
  "loading",
  'loadingMoreData',
  'hasMoreData',
  'loadingDetail',
  'usageDetail',
  'showUsageDetail',
  'canCancelLoad'
}

export enum ReportsActions {
  'multipleActions',
  'reports',
  'filterOpen',
  'filterOptions',
  'filteredReports',
  'loading',
  'checkedReports',
  'globalCheck',
  'loadingMoreData',
  'hasMoreData',
  'showMultiFunctionWarning',
  'multiFunction',
  'canCancelLoad'
}

export enum CustomersActions {
  'multipleActions',
  'customers',
  'editingCustomer',
  'showEdit',
  'showDelete',
  'editHeader',
  'loading',
  'filteredCustomers',
  'loadingMoreData',
  'hasMoreData',
  'canCancelLoad'
}

export enum JobsActions {
  'multipleActions',
  'dataItems',
  'customerIds',
  'userIds',
  'jobIds',
  'startDate',
  'useStartDate',
  'endDate',
  'useEndDate',
  'sortingField',
  'sortAscending',
  'loading',
  'loadingCsv',
  'loadingMoreData',
  'hasMoreData',
  'showDetail',
  'detailObject',
}

export enum UsageActions {
  'multipleActions',
  'dataItems',
  'customerIds',
  'userIds',
  'jobIds',
  'startDate',
  'useStartDate',
  'endDate',
  'useEndDate',
  'loading',
  'result',
  'loadingMoreData',
  'hasMoreData'
}

export enum TaskEditActions {
  'multipleActions',
  'task',
  'originalTask',
  'loading',
  'new',
  'duplicating',
  'selectedTab',
  'projects',
  'loadingProjects',
  'Saving',
  'showCancelConfirm',
  'expandedSource',
  'expandedDestination',
  'selectedProjectSource',
  'selectedProjectDestination',
  'recent',
  'loadingRecent',
  'loadingExportPath',
  'exportPath',
  'canSave',
}

export enum ReportDetailActions {
  'multipleActions',
  'report',
  'loading',
  'loadingModelPath',
}

export enum CheckSetActions {
  'multipleActions',
  'loadingPublic',
  'loadingPrivate',
  'publicCheckSets',
  'privateCheckSets',
  'selected',
  'uploading',
  'loadingMorePrivate',
  'hasMorePrivate'
}

export enum OptionsActions {
  'multipleActions',
  'exportExcel',
  'includeList',
  'combineReports',
  'exportHtml',
  'location',
  'naming',
  'email',
  'attachExports',
  'showLocationDialog',
  'selectedProject',
  'selectedDirectory',
  'revitVersion',
}

export enum WebhookSettingsActions {
  'multipleActions',
  'loading',
  'webHooks',
  'editingHook',
  'showEdit',
  'showDelete',
  'showDeleteAll',
  'showResponseDetail',
  'showSampleBody',
  'sampleBody',
  'responseDetail',
  'editIsNew',
  'editName',
  'editUrl',
  'editSecret',
  'editEnabled',
  'loadingMoreWebhooks',
  'hasMoreWebhooks',
  'canCancelLoad'
}

export enum ProjectWiseSettingsActions {
  'multipleActions',
  'loadingCredentials',
  'loadingConfigurations',
  'hasMoreCredentials',
  'hasMoreConfigurations',
  'canCancelCredentials',
  'canCancelConfigurations',
  'credentials',
  'configurations',
  'editingCredential',
  'editingConfiguration',
  'isEditingCredential',
  'isEditingConfiguration',
  'editPassword',
  'editIsNew',
  'credentialSelectOptions',
  'selectedCredential'
}
