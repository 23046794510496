////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import { ChecksetState } from "../states/ChecksetState";
import { CheckSetActions } from "../../Enums";

export function reducer(state: ChecksetState, action: { type: CheckSetActions, payload: any }): ChecksetState {
    switch (action.type) {
        case CheckSetActions.loadingMorePrivate:
            return { ...state, LoadingMorePrivate: action.payload };
        case CheckSetActions.hasMorePrivate:
            return { ...state, HasMorePrivate: action.payload };
        case CheckSetActions.uploading:
            return { ...state, Uploading: action.payload };
        case CheckSetActions.selected:
            return { ...state, SelectedCheckset: action.payload };
        case CheckSetActions.multipleActions:
            return { ...state, ...action.payload };
        case CheckSetActions.loadingPublic:
            return { ...state, LoadingPublic: action.payload };
        case CheckSetActions.loadingPrivate:
            return { ...state, LoadingPrivate: action.payload };
        case CheckSetActions.publicCheckSets:
            return { ...state, PublicCheckSets: action.payload };
        case CheckSetActions.privateCheckSets:
            return { ...state, PrivateCheckSets: action.payload };
    }
}