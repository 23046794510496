////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React from 'react';
import {Control, DefaultItem, DropdownMenu, useDropdown} from "@adsk/alloy-react-dropdown";

interface SelectorArgs {
  items: DefaultItem[];
  selected?: DefaultItem | null;
  onSelectionChange?: (selected: DefaultItem | null | undefined) => void;

  [k: string]: any;
}

const Selector = ({items, selected, onSelectionChange, disabled, ...args}: SelectorArgs) => {
  const {controlProps, menuProps} =
    useDropdown({
      items: items,
      selectedItem: selected,
      onSelectedItemChange: c => {
        if (onSelectionChange) {
          onSelectionChange(c.selectedItem);
        }
      },
      ...args,
    });

  return (
    <div style={{width: 300}}>
      <Control disabled={disabled} {...controlProps}/>
      <DropdownMenu {...menuProps}/>
    </div>
  );
};

export default Selector;