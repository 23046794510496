////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {
  Directory360,
  File360, JobDirectory,
  JobFile,
  PaginationData,
  ProjectWiseDirectory,
  ProjectWiseFile
} from "../clients/Classes";
import {CancellationToken} from "../dataModel/CancellationToken";
import {ListFilesV2Result, ListProjectWiseFilesResult, ListRecentItemsResult} from "../clients/V2Classes";

export abstract class ServiceBase {
  TryPromiseWithCatch<T>(f: () => Promise<T>): Promise<T> {
    try {
      return f();
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async GetResultsWithItemOrToEnd<T>(f: (t: string | undefined) => Promise<{
    paginationData?: PaginationData | undefined,
    items?: T[],
    isDone?: boolean
  }>, currentToken: string | undefined | null): Promise<{
    paginationData?: PaginationData | undefined,
    items?: T[],
    isDone?: boolean
  }> {
    let token = currentToken ?? undefined;
    while (true) {
      const result = await f(token)
      if (result.isDone || result.items!.length > 0) {
        return result;
      }
      token = result.paginationData?.paginationToken;
    }
  }

  async GetRemainingDataFromPaginatedEndpoint<T>(
    f: (t: string | undefined) => Promise<{
      paginationData?: PaginationData | undefined,
      items?: T[],
      isDone?: boolean
    }>,
    currentToken?: string | null | undefined,
    cancelToken?: CancellationToken | undefined): Promise<{
    paginationData?: PaginationData | undefined,
    items?: T[],
    isDone?: boolean
  }> {
    const returnValue: T[] = [];
    let token = currentToken ?? undefined;
    let lastResult: {
      paginationData?: PaginationData | undefined,
      items?: T[],
      isDone?: boolean
    } | undefined;

    while (true) {
      let result: {
        paginationData?: PaginationData | undefined,
        items?: T[];
        isDone?: boolean
      };
      result = await f(token);
      lastResult = result;

      if (result.items != null && result.items.length > 0) {
        result.items.forEach(i => returnValue.push(i));
      }

      token = result.paginationData?.paginationToken;

      if (result.isDone || cancelToken?.Cancel) {
        break;
      }
    }

    return {...lastResult, items: returnValue};
  }

  async GetRemainingDataFromPaginatedEndpointFileResult(
    f: (t: string | undefined) => Promise<ListProjectWiseFilesResult | ListFilesV2Result>,
    currentToken?: string | null | undefined,
    cancelToken?: CancellationToken | undefined
  ): Promise<ListProjectWiseFilesResult | ListFilesV2Result> {
    const returnFiles: (ProjectWiseFile | File360)[] = [];
    const returnDirectories: (ProjectWiseDirectory | Directory360)[] = [];

    let token = currentToken ?? undefined;
    let lastResult: ListProjectWiseFilesResult | ListFilesV2Result | undefined;

    while (true) {
      let result: ListProjectWiseFilesResult | ListFilesV2Result;
      result = await f(token);
      lastResult = result;

      if (result.files != null && result.files.length > 0) {
        result.files.forEach(i => returnFiles.push(i));
      }

      if (result.directories != null && result.directories.length > 0) {
        result.directories.forEach(i => returnDirectories.push(i));
      }

      token = result.nextPage?.paginationToken;

      if (result.nextPage == null || cancelToken?.Cancel) {
        break;
      }
    }

    const resultData = {...lastResult, files: returnFiles, directories: returnDirectories};
    return lastResult != null && lastResult instanceof ListFilesV2Result
      ? new ListFilesV2Result(resultData)
      : new ListProjectWiseFilesResult({
        ...resultData,
        files: resultData.files
          .filter(f => f instanceof ProjectWiseFile)
          .map(f => f as ProjectWiseFile),
        directories: resultData.directories
          .filter(f => f instanceof ProjectWiseDirectory)
          .map(f => f as ProjectWiseDirectory)
      });
  }

  async GetRemainingDataFromPaginatedEndpointRecentFileResult(
    f: (t: string | undefined) => Promise<ListRecentItemsResult>,
    currentToken?: string | null | undefined,
    cancelToken?: CancellationToken | undefined
  ): Promise<ListRecentItemsResult> {
    const forgeFiles: JobFile[] = [];
    const forgeDirectories: JobDirectory[] = [];
    const projectWiseFiles: ProjectWiseFile[] = [];
    const projectWiseDirectories: ProjectWiseDirectory[] = [];

    let token = currentToken ?? undefined;
    let lastResult: ListRecentItemsResult | undefined;

    while (true) {
      let result: ListRecentItemsResult;
      result = await f(token);
      lastResult = result;

      if (result.forgeFiles != null && result.forgeFiles.length > 0) {
        result.forgeFiles.forEach(i => forgeFiles.push(i));
      }

      if (result.forgeDirectories != null && result.forgeDirectories.length > 0) {
        result.forgeDirectories.forEach(i => forgeDirectories.push(i));
      }

      if (result.projectWiseFiles != null && result.projectWiseFiles.length > 0) {
        result.projectWiseFiles.forEach(i => projectWiseFiles.push(i));
      }

      if (result.projectWiseDirectories != null && result.projectWiseDirectories.length > 0) {
        result.projectWiseDirectories.forEach(i => projectWiseDirectories.push(i));
      }

      token = result.paginationData?.paginationToken;

      if (result.isDone || cancelToken?.Cancel) {
        break;
      }
    }

    return new ListRecentItemsResult({
      ...lastResult,
      forgeFiles: forgeFiles,
      forgeDirectories: forgeDirectories,
      projectWiseFiles: projectWiseFiles,
      projectWiseDirectories: projectWiseDirectories
    });
  }
}