////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {ReportsState} from "../states/ReportsState";
import {ReportsActions} from "../../Enums";

export function reducer(state: ReportsState, action: { type: ReportsActions, payload: any }): ReportsState {
  switch (action.type) {
    case ReportsActions.reports:
      return {...state, reports: action.payload};
    case ReportsActions.loading:
      return {...state, loading: action.payload};
    case ReportsActions.loadingMoreData:
      return {...state, loadingMoreData: action.payload};
    case ReportsActions.hasMoreData:
      return {...state, hasMoreData: action.payload};
    case ReportsActions.showMultiFunctionWarning:
      return {...state, showMultiFunctionWarning: action.payload};
    case ReportsActions.multiFunction:
      return {...state, multiFunction: action.payload};
    case ReportsActions.checkedReports:
      return {...state, checkedReports: action.payload};
    case ReportsActions.globalCheck:
      return {...state, globalCheck: action.payload};
    case ReportsActions.filteredReports:
      return {...state, filteredReports: action.payload};
    case ReportsActions.filterOptions:
      return {...state, filterOptions: action.payload};
    case ReportsActions.multipleActions:
      return {...state, ...action.payload};
    case ReportsActions.filterOpen:
      return {...state, filterOpen: action.payload};
    case ReportsActions.canCancelLoad:
      return {...state, canCancelLoad: action.payload};
  }
}