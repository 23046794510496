////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {FileUI} from './FileUI';
import {BIM360ItemBase} from './BIM360ItemBase';
import {HubType, ProjectType, StorageType} from '../clients/Classes';

export class DirectoryUI implements BIM360ItemBase {
  // Interface Implementation
  public IsFolder = true;

  Id: string;
  ParentId: string | undefined;
  ProjectId: string;
  Name: string;
  LastChange: Date | undefined;

  // Class Properties
  SubFolders: DirectoryUI[];
  Models: FileUI[];
  Parent: DirectoryUI | undefined;

  HubId: string | undefined;
  HubRegion: string | undefined;
  HubType: HubType;
  ProjectType: ProjectType;

  Icon: string;

  AreItemsPopulated = false;

  Recursive = false;

  StorageType: StorageType;

  constructor(id: string, name: string, projectId: string, storageType: StorageType) {
    this.Id = id;
    this.Name = name;
    this.ProjectId = projectId;
    this.SubFolders = [];
    this.Models = [];
    this.HubType = HubType.Unknown;
    this.ProjectType = ProjectType.Unknown;
    this.Icon = 'assets/images/Icon_Folder.png';
    this.StorageType = storageType;
  }

  get SubItems(): (FileUI | DirectoryUI)[] {
    const newArray: (FileUI | DirectoryUI)[] = [];
    this.SubFolders.forEach(f => newArray.push(f));
    this.Models.forEach(m => newArray.push(m));

    return newArray;
  }
}
