////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {IReportStructureTreeItem} from './IReportStructureTreeItem';
import {RevitElement} from './RevitElement';

export enum CheckResultType {
  Pass,
  Fail,
  Report,
  NotRun,
  Error
}

export class Check implements IReportStructureTreeItem {
  Id: string;
  Title: string;
  Description: string;
  Result: CheckResultType | undefined;
  Count: number;

  ResultMessage: string | undefined;
  FailureMessage: string | undefined;
  ErrorMessage: string | undefined;

  ShowTotalCount: boolean = false;
  ShowList: boolean = false;
  ShowValues: boolean = false;
  ShowCountColumn: boolean = false;

  Elements: RevitElement[];

  IsCheck = true;
  IsHeading = false;
  IsSection = false;

  AreItemsPopulated = false;

  constructor(id: string, title: string, description: string) {
    this.Id = id;
    this.Title = title;
    this.Description = description;
    this.Count = 1;
    this.Elements = [];
  }
}
