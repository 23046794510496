////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

export class Constants {
  static get NoFilterString(): string {
    return '-Any-';
  }

  static get MultipleSelectionString(): string {
    return '-Multiple Selected-';
  }

  static get NoneString(): string {
    return '-None-';
  }

  static get MaxJobUrlCharacters(): number {
    return 150;
  }

  static get SecretKeyLength(): number {
    return 16;
  }
}

export const PAGES = {
  ROOT: 'ROOT',
  DOOR: 'DOOR',
  LANDING: 'LANDING',
  TASKS: 'TASKS',
  REPORT: 'REPORT',
  SETTINGS: 'SETTINGS',
  CUSTOMERS: 'CUSTOMERS',
  USAGE: 'USAGE',
  JOBS: 'JOBS',
};

export const PATHS = {
  [PAGES.ROOT]: '/app',
  [PAGES.DOOR]: '/door',
  [PAGES.LANDING]: '/landing',
  [PAGES.TASKS]: 'tasks',
  [PAGES.REPORT]: 'report',
  [PAGES.SETTINGS]: 'settings',
  [PAGES.CUSTOMERS]: 'customers',
  [PAGES.USAGE]: 'usage',
  [PAGES.JOBS]: 'jobs',
};

export const PAGE_TITLES = {
  [PAGES.ROOT]: 'Root',
  [PAGES.DOOR]: 'Door',
  [PAGES.LANDING]: 'Landing',
  [PAGES.TASKS]: 'Tasks',
  [PAGES.REPORT]: 'Reports',
  [PAGES.SETTINGS]: 'Settings',
  [PAGES.CUSTOMERS]: 'Customers',
  [PAGES.USAGE]: 'Usage',
  [PAGES.JOBS]: 'Jobs',
};

export const EDIT_TABS = {
  NAME: 'NAME',
  MODELS: 'MODELS',
  CHECKSET: 'CHECKSET',
  TRIGGER: 'TRIGGER',
  OPTIONS: 'OPTIONS',
  SUMMARY: 'SUMMARY',
};

export const EDIT_TAB_TITLES = {
  [EDIT_TABS.NAME]: 'Name',
  [EDIT_TABS.MODELS]: 'Models',
  [EDIT_TABS.CHECKSET]: 'Checkset',
  [EDIT_TABS.TRIGGER]: 'Trigger',
  [EDIT_TABS.OPTIONS]: 'Options',
  [EDIT_TABS.SUMMARY]: 'Summary',
};

export const EDIT_TAB_IDS = {
  [EDIT_TABS.NAME]: 'tbName',
  [EDIT_TABS.MODELS]: 'tbModels',
  [EDIT_TABS.CHECKSET]: 'tbCheckset',
  [EDIT_TABS.TRIGGER]: 'tbTrigger',
  [EDIT_TABS.OPTIONS]: 'tbOptions',
  [EDIT_TABS.SUMMARY]: 'tbSummary',
};

export const SETTINGS_TABS = {
  WEBHOOKS: "WEBHOOKS",
  PROJECTWISE: "PROJECTWISE",
}

export const SETTINGS_TAB_TITLES = {
  [SETTINGS_TABS.WEBHOOKS]: "Webhooks",
  [SETTINGS_TABS.PROJECTWISE]: "Project Wise",
}

export const SETTINGS_TAB_IDS = {
  [SETTINGS_TABS.WEBHOOKS]: "tbWebhooks",
  [SETTINGS_TABS.PROJECTWISE]: "tbProject Wise",
}

export const FILE_TABS = {
  FORGE: "FORGE",
  PROJECTWISE: "PROJECTWISE",
}

export const FILE_TAB_TITLES = {
  [FILE_TABS.FORGE]: "Docs",
  [FILE_TABS.PROJECTWISE]: "ProjectWise",
}

export const FILE_TAB_IDS = {
  [FILE_TABS.FORGE]: "tbDocs",
  [FILE_TABS.PROJECTWISE]: "tbProjectWise",
}

export const ROLE_ID_ADMIN = 'bit_admin';
export const ROLE_ID_USAGE = 'bit_usage';
export const ROLE_ID_CUSTOMER_READ_ONLY = 'bit_customer_read_only';
export const ROLE_ID_WEBHOOKS = 'bit_webhooks';
export const ROLE_ID_PROJECTWISE = 'bit_pw';
