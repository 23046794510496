////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React, {useEffect} from 'react';
import {UsageDataItem} from "../dataModel/UsageDataItem";
import {TreeItem} from "../dataModel/TreeItem";
import {BIM360ItemBase} from "../dataModel/BIM360ItemBase";
import {GetUsageTreeItems} from "../Utility";
import {ColumnLeft, ColumnRight, FlexColumn, FlexRow} from '../CommonStyledComponents';
import {ConvertDuration} from "../converters/ConvertDuration";
import {ExpandButton, Tree, TreeNode, TREE_ACTIONS, useTree} from "@adsk/alloy-react-tree";

const UsageDataTree = ({topItems}: { topItems: UsageDataItem[] }) => {

  useEffect(() => {
    const dta = GetUsageTreeItems(topItems);
    // @ts-ignore
    dispatch({type: TREE_ACTIONS.regenerateTree, payload: {denormalizedTree: dta}});
  }, [topItems]);

  const {orderedIds, normalizedTree, getTreeNodeProps, getTreeProps, dispatch} =
    useTree({
      denormalizedTree: [],
      getLabelFromNode: (node) => {
        const cast = node as TreeItem<BIM360ItemBase>;
        return cast.relatedObject.Name ?? '-nothing-';
      },
    });

  return (
    <Tree {...getTreeProps()} normalizedTree={normalizedTree}>
      {orderedIds
        .map((id) => normalizedTree[id])
        .map(getTreeNodeProps)
        .map((treeNodeProps) => {
          const treeItem = normalizedTree[treeNodeProps.id].original as TreeItem<UsageDataItem>;
          const item = treeItem.relatedObject;
          return (
            <TreeNode
              style={{height: 'initial', margin: '.2em 0'}}
              key={treeNodeProps.id}
              {...treeNodeProps}
              isMultiSelectable={false}
              isSingleSelectable={false}>
              {
                treeNodeProps.isExpandable &&
                <ExpandButton
                  style={{
                    margin: '0 3px',
                  }}
                  isExpanded={treeNodeProps.isExpanded}
                  onExpand={() => treeNodeProps.onExpand({
                    id: treeNodeProps.id,
                    isExpanded: !treeNodeProps.isExpanded
                  })}
                />
              }
              <FlexColumn style={{borderLeft: '1px solid blue', paddingLeft: '1em'}}>
                <FlexRow>
                  <ColumnLeft style={{marginRight: '1em'}}>{item.ItemType}:</ColumnLeft>
                  <ColumnRight>{item.Name}</ColumnRight>
                </FlexRow>
                <FlexRow>
                  <ColumnLeft style={{marginRight: '1em'}}>ID:</ColumnLeft>
                  <ColumnRight>{item.ID}</ColumnRight>
                </FlexRow>
                <FlexRow>
                  <ColumnLeft style={{marginRight: '1em'}}>Stats:</ColumnLeft>
                  <ColumnRight>{item.JobRuns} total runs / {ConvertDuration.Convert(item.RunTimeSeconds!)}</ColumnRight>
                </FlexRow>
                <FlexRow>
                  <a
                    href={`./jobs?${item.ItemType === 'Customer' ? 'customers' : item.ItemType === 'User' ? 'users' : 'jobs'}=${item.ID}`}>
                    See Details
                  </a>
                </FlexRow>
              </FlexColumn>
            </TreeNode>
          )
        })}
    </Tree>
  );
};

export default UsageDataTree;