////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import React from 'react';
import { Flyout } from "@adsk/alloy-react-tooltip";
import Theme from "@adsk/alloy-react-theme";
import {LinkButton} from "@adsk/alloy-react-button";
import {AlertWarningIcon} from "@adsk/alloy-react-icon";

const PartialLoadWarning = ({pageSize, onLoadAll}: { pageSize: number, onLoadAll?: () => void }) => {
  function loadAll(): void {
    if (onLoadAll) {
      onLoadAll();
    }
  }

  return (
    <div style={{cursor: 'pointer'}}>
      <Flyout trigger={'click'}
              offset={{mainAxis: 20, crossAxis: 0}}
              placement={Flyout.PLACEMENTS.BOTTOM}
              content={
                <div>
                  <p>Not all items are currently shown. Either "Load more" for another {pageSize} items, or "<LinkButton
                    onClick={loadAll}>Load all</LinkButton>" to see everything.</p>
                  <p>Please note that "Load all" may take some time, depending on the number of items.</p>
                  <p>Also note that sorting, searching, and filtering only works on the items you have loaded.</p>
                </div>
              }>
        <AlertWarningIcon style={{color: Theme.colors.yellowOrange500, marginLeft: '1em', verticalAlign: 'middle'}}/>
      </Flyout>
    </div>
  );
};

export default PartialLoadWarning;