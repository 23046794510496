////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

import {DirectoryUI} from './dataModel/DirectoryUI';
import {ProjectUI} from './dataModel/ProjectUI';
import {Task} from './dataModel/Task';
import {
  CheckSet, CustomerUser,
  ExportReportLocationType,
  JobStatusType,
  ProblemDetails,
  ProjectWiseCredential,
  User
} from './clients/Classes';
import {BIM360ItemBase} from './dataModel/BIM360ItemBase';
import {ConvertRunDate} from './converters/ConvertRunDate';
import {UsageDataItem} from './dataModel/UsageDataItem';
import {TreeItem} from './dataModel/TreeItem';
import {IReportStructureTreeItem} from './dataModel/IReportStructureTreeItem';
import {Heading} from './dataModel/Heading';
import {Section} from './dataModel/Section';
import {FilterItemData} from './dataModel/FilterItemData';
import {
  Constants,
  FILE_TABS,
  ROLE_ID_ADMIN,
  ROLE_ID_CUSTOMER_READ_ONLY,
  ROLE_ID_PROJECTWISE,
  ROLE_ID_USAGE,
  ROLE_ID_WEBHOOKS,
  SETTINGS_TABS
} from './Constants';
import {ConvertTaskStatus} from './converters/ConvertTaskStatus';
import {PageTypes} from "./Enums";
import {UsageSummaryUI} from "./dataModel/UsageSummaryUI";
import {ProjectWiseConfigurationUI} from "./dataModel/ProjectWiseConfigurationUI";
import {FileUI} from "./dataModel/FileUI";

export function GetDateWithOffset(modifyDays: number): Date {
  return OffsetDate(new Date(), modifyDays);
}

export function OffsetDate(date: Date, modifyDays?: number, modifyHours?: number, modifyMinutes?: number, modifySeconds?: number): Date {
  const currentTime = date.getTime();
  const newDate = new Date();

  let modify = 0;
  if (modifyDays != null) {
    modify += modifyDays * 24 * 60 * 60 * 1000;
  }
  if (modifyHours != null) {
    modify += modifyHours * 60 * 60 * 1000;
  }
  if (modifyMinutes != null) {
    modify += modifyMinutes * 60 * 1000;
  }
  if (modifySeconds != null) {
    modify += modifySeconds * 1000;
  }

  newDate.setTime(currentTime + modify);

  return newDate;
}

export function array_move(arr: any[], old_index: number, new_index: number): void {
  while (old_index < 0) {
    old_index += arr.length;
  }
  while (new_index < 0) {
    new_index += arr.length;
  }
  if (new_index >= arr.length) {
    let k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
}

export function DownloadUrl(url: string, fileName: string): void {
  const a: any = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.target = '_blank';
  document.body.appendChild(a);
  a.style = 'display: none';
  a.click();
  a.remove();
}

export function OpenUrlInNewTab(url: string): void {
  const a: any = document.createElement('a');
  a.href = url;
  a.target = '_blank';
  document.body.appendChild(a);
  a.style = 'display: none';
  a.click();
  a.remove();
}

export function ObjectToQueryString(object: any): string {
  const str = [];
  for (const p in object) {
    if (object.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(object[p]));
    }
  }

  return str.join('&');
}

export function GetErrorMessage(error: any, operation: string): string {
  let message = null;
  console.error(error);
  if (error instanceof ProblemDetails || error.hasOwnProperty('title')) {
    message = error.title;
  } else {
    if (error.hasOwnProperty('error') && error.error != null) {
      if (error.error instanceof ProblemDetails) {
        message = error.error.title;
      } else {
        // First priority - if error property is a non-blank string use that
        if (typeof error.error === 'string' && error.error !== '') {
          message = error.error;
        } else if (
          error.error.hasOwnProperty('Message')
          && error.error.Message != null
          && typeof (error.error.Message) === 'string'
          && error.error.Message !== ''
        ) {
          // Next - if error is an object with a message property
          message = error.error.Message;
        } else if (
          error.error.hasOwnProperty('message')
          && error.error.message != null
          && typeof (error.error.message) === 'string'
          && error.error.message !== ''
        ) {
          // Next - if error is an object with a message property
          message = error.error.message;
        }
      }
    }

    // If we don't have anything yet check for a message property directly on the error object
    if (
      message == null
      && error.hasOwnProperty('message')
      && error.message != null
      && typeof (error.message) === 'string'
      && error.message !== ''
    ) {
      message = error.message;
    } else if (
      message == null
      && error.hasOwnProperty('Message')
      && error.Message != null
      && typeof (error.Message) === 'string'
      && error.Message !== ''
    ) {
      message = error.Message;
    }

    if (message != null && error.hasOwnProperty('response')) {
      let responseObject: any = null;
      if (typeof error.response === 'string') {
        try {
          responseObject = JSON.parse(error.response);
        } catch (e) {
          message += ` - ${error.response}`;
        }
      } else if (typeof error.response === 'object') {
        responseObject = error.response;
      }

      if (responseObject != null && responseObject.hasOwnProperty('title') && typeof (responseObject.title) === 'string') {
        message += ` - ${responseObject.title}`;
      }
    }

    if (message == null) {
      if (
        error.hasOwnProperty('error')
        && error.error != null
        && typeof error.error === 'string'
        && error.error !== ''
      ) {
        // Last effort - check if it has an error object and use that
        message = error.error;
      } else {
        // Nothing was found, just tell them it's unknown
        message = 'Unknown Error';
      }
    }
  }

  return operation == null || operation === '' ? message : `${operation} Failed: ${message}`;
}

export function GetRecursiveFilePath(folder: DirectoryUI, pathDivider: string): string {
  if (folder.Parent == null) {
    return folder.Name;
  } else {
    const parentVal = GetRecursiveFilePath(folder.Parent, pathDivider);

    if (parentVal === '') {
      return folder.Name;
    } else {
      return `${parentVal}${pathDivider}${folder.Name}`;
    }
  }
}

export function FindDirectoryRecursive(parentDirectory: DirectoryUI, id: string): DirectoryUI | null {
  if (parentDirectory.Id === id) {
    return parentDirectory;
  }

  if (parentDirectory.SubFolders == null) {
    return null;
  }

  for (const child of parentDirectory.SubFolders) {
    const foundDirectory = FindDirectoryRecursive(child, id);
    if (foundDirectory != null) {
      return foundDirectory;
    }
  }

  return null;
}

export function ValidateExports(task: Task): string[] {
  const messages: string[] = [];
  if (task.ExportExcel && task.CombineExcel && task.Trigger === 'OnPublish') {
    messages.push('Combine Excel setting can not be used with publish triggers');
  }

  switch (task.ExportLocationType) {
    case ExportReportLocationType.ModelDirectory:
    case ExportReportLocationType.ModelSubdirectory:
      if (!task.ExportExcel || !task.CombineExcel) {
        return [];
      }

      // Check for models in multiple directories
      if (task.Directories != null && task.Directories.length > 1) {
        messages.push('Tasks using combined Excel export with multiple directories must use a single export location');
      }

      if (task.Directories != null && task.Directories.find(d => d.Recursive) != null) {
        messages.push('Tasks using combined Excel export with recursive directories must use a single export location');
      }

      const allDirectoryIds: string[] = [];
      if (task.Models != null) {
        task.Models.forEach(m => allDirectoryIds.push(m.DirectoryId));
      }
      if (task.Directories != null) {
        task.Directories.forEach(d => allDirectoryIds.push(d.Id));
      }
      const uniqueDirectoryIds = Array.from(new Set(allDirectoryIds));

      if (uniqueDirectoryIds.length > 1) {
        messages.push('Tasks using combined Excel export must have all models in the same folder or use a single export location');
      }
      break;
    case ExportReportLocationType.OtherDirectory:
      if (task.ExportDirectoryId == null || task.ExportDirectoryId === '') {
        messages.push('The export directory id was not set');
      }
      if (task.ExportProjectId == null || task.ExportProjectId === '') {
        messages.push('The export directory project id was not set');
      }
      break;
  }

  return messages;
}

export function ValidateTrigger(task: Task): string[] {
  switch (task.Trigger) {
    case null:
    case undefined:
      return ['No trigger is selected'];
    case 'OnceNow':
    case 'OnPublish':
      return [];
    case 'OnceLater':
      if (task.StartDate < new Date()) {
        return ['Start date can not be in the past'];
      }
      return [];
    case 'Recurring':
      if (task.RecurrenceSettings!.Recurrence !== 'Weekly') {
        return [];
      }

      for (const day of task.RecurrenceSettings!.WeekdaySettings) {
        if (day.Checked) {
          return [];
        }
      }
      return ['You must select at least one day to run checks'];
  }
}

export function FindProjectItemRecursive(project: ProjectUI, id: string): BIM360ItemBase | null {
  for (const directory of project.RootFolderArray) {
    const foundItem = FindBIM360ItemRecursive(directory, id, true, true);
    if (foundItem != null) {
      return foundItem;
    }
  }

  return null;
}

export function uniqueFilter(value: any, index: number, self: any[]) {
  return self.indexOf(value) === index;
}

export function uniqueDateDisplayFilter(value: Date, index: number, self: Date[]) {
  const dateDisplays = self.map(d => ConvertRunDate.Convert(d));
  return dateDisplays.indexOf(ConvertRunDate.Convert(value)) === index;
}

export function StringToList(value: string): string[] {
  const rawSplit = value.split(',');

  const list: string[] = [];
  rawSplit.forEach(e => {
    if (e == null || e.trim() === '') {
      return;
    }
    list.push(e.trim());
  });

  return list;
}

export function ListToString(values: string[]): string {
  return values.join(', ');
}

export function GetPropertyDisplayString(item: any, key: string): string | undefined {
  if (item == null) {
    return undefined;
  }
  const value: any = item[key];

  if (value == null) {
    return undefined;
  }

  if (value instanceof Object) {
    return JSON.stringify(value);
  }

  return value.toString();
}

export function GetFileNameFormattedDate(date: Date): string {
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDay().toString().padStart(2, '0');
  const hour = date.getHours().toString().padStart(2, '0');
  const minute = date.getMinutes().toString().padStart(2, '0');
  const second = date.getSeconds().toString().padStart(2, '0');

  return `${year}-${month}=${day} ${hour}:${minute}:${second}`;
}

export function GetTreeItems(items: IReportStructureTreeItem[]): TreeItem<IReportStructureTreeItem>[] {
  const allNodes: TreeItem<IReportStructureTreeItem>[] = [];

  for (const item of items) {
    const node = new TreeItem<IReportStructureTreeItem>(item.Id, item);
    if ((item instanceof Heading || item instanceof Section) && item.SubItems.length > 0 && item.AreItemsPopulated) {
      node.children = GetTreeItems(item.SubItems);
    }
    allNodes.push(node);
  }

  return allNodes;
}

export function AreAnyBranchesUnloaded(item: DirectoryUI): boolean {
  if (!item.AreItemsPopulated) {
    return true;
  }

  for (const folder of item.SubFolders) {
    if (AreAnyBranchesUnloaded(folder)) {
      return true;
    }
  }

  return false;
}

export function GetUsageTreeItems(items: UsageDataItem[]): TreeItem<UsageDataItem>[] {
  const allNodes: TreeItem<UsageDataItem>[] = [];

  for (const item of items) {
    const node = new TreeItem<UsageDataItem>(item.ID!, item);
    if (item.Children.length > 0) {
      node.children = GetUsageTreeItems(item.Children);
    }
    allNodes.push(node);
  }

  return allNodes;
}

export function CheckSetsMatch(a: CheckSet | undefined, b: CheckSet | undefined): boolean {
  const aIdentifier = a?.id ?? a?.url;
  const bIdentifier = b?.id ?? b?.url;

  return aIdentifier === bIdentifier;
}

export function TrimString(s: string, c: string): string {
  if (c === ']') {
    c = '\\]';
  }
  if (c === '^') {
    c = '\\^';
  }
  if (c === '\\') {
    c = '\\\\';
  }
  return s.replace(new RegExp(
    '^[' + c + ']+|[' + c + ']+$', 'g'
  ), '');
}

export function GetDefaultTaskFilterOptions(): FilterItemData[] {
  const statusOptions = [
    {value: '', label: Constants.NoFilterString},
    {value: JobStatusType.Scheduled, label: ConvertTaskStatus.Convert(JobStatusType.Scheduled)},
    {value: JobStatusType.Running, label: ConvertTaskStatus.Convert(JobStatusType.Running)},
    {value: JobStatusType.PostProcessing, label: ConvertTaskStatus.Convert(JobStatusType.PostProcessing)},
    {value: JobStatusType.Paused, label: ConvertTaskStatus.Convert(JobStatusType.Paused)},
    {value: JobStatusType.Completed, label: ConvertTaskStatus.Convert(JobStatusType.Completed)},
    {
      value: JobStatusType.PartiallyCompleted,
      label: ConvertTaskStatus.Convert(JobStatusType.PartiallyCompleted)
    },
    {
      value: ConvertTaskStatus.Convert(JobStatusType.Scheduled, true),
      label: ConvertTaskStatus.Convert(JobStatusType.Scheduled, true)
    },
    {value: JobStatusType.Error, label: ConvertTaskStatus.Convert(JobStatusType.Error)},
  ];

  return [
    {
      id: 'status',
      title: 'Status',
      selected: statusOptions[0],
      options: statusOptions,
    }
  ];
}

export async function PollForResult<T>(fn: () => Promise<T>, fnCondition: (result: T) => boolean, ms: number): Promise<T> {
  const wait = function (ms = 1000) {
    return new Promise(resolve => {
      setTimeout(resolve, ms);
    });
  };

  let result = await fn();
  while (fnCondition(result)) {
    await wait(ms);
    result = await fn();
  }
  return result;
}

function FindBIM360ItemRecursive(
  parentDirectory: DirectoryUI,
  id: string, includeFiles: boolean,
  includeDirectories: boolean
): BIM360ItemBase | null {
  if (parentDirectory.Id === id && includeDirectories) {
    return parentDirectory;
  }

  if (includeFiles && parentDirectory.Models != null) {
    const file = parentDirectory.Models.find(f => f.Id === id);
    if (file != null) {
      return file;
    }
  }

  if (includeDirectories && parentDirectory.SubFolders != null) {
    for (const child of parentDirectory.SubFolders) {
      const foundDirectory = FindBIM360ItemRecursive(child, id, includeFiles, includeDirectories);
      if (foundDirectory != null) {
        return foundDirectory;
      }
    }
  }

  return null;
}

export function StartOfDay(date: Date, makeUtc: boolean = false): Date {
  const baseDateNumber = date.setHours(0, 0, 0, 0);
  return makeUtc ? new Date(baseDateNumber - (date.getTimezoneOffset() * 60000)) : new Date(baseDateNumber);
}

export function EndOfDay(date: Date, makeUtc: boolean = false): Date {
  const baseDateNumber = date.setHours(23, 59, 59, 0);
  return makeUtc ? new Date(baseDateNumber - (date.getTimezoneOffset() * 60000)) : new Date(baseDateNumber);
}

export function GenerateRandomString(length: number, possibleCharacters?: string | undefined): string {
  // noinspection SpellCheckingInspection
  const chars = possibleCharacters ?? 'abcdefghijkmnopqrstuvwxyzABCDEFGHIJKLMNPQRSTUVWXYZ23456789';

  let retVal = '';
  for (let i = 0, n = chars.length; i < length; i++) {
    retVal += chars.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

export function IsPageAvailableToUser(
  page: { path: string, uiVisible: boolean, type: PageTypes },
  user: User | undefined
): boolean {
  switch (page.type) {
    case PageTypes.auth:
      return true;
    case PageTypes.protected:
      return user != null;
    case PageTypes.customers:
      return user?.roles != null
        && (user.roles?.includes(ROLE_ID_ADMIN)
          || user.roles?.includes(ROLE_ID_CUSTOMER_READ_ONLY));
    case PageTypes.usage:
      return user?.roles != null
        && (user.roles?.includes(ROLE_ID_ADMIN)
          || user.roles?.includes(ROLE_ID_USAGE));
    case PageTypes.settings:
      return user?.roles != null
        && (user.roles?.includes(ROLE_ID_ADMIN)
          || user.roles?.includes(ROLE_ID_WEBHOOKS)
          || user.roles?.includes(ROLE_ID_PROJECTWISE));
    default:
      return false;
  }
}

export function IsTabAvailableToUser(tab: string, user: CustomerUser | undefined): boolean {
  switch (tab) {
    case SETTINGS_TABS.PROJECTWISE:
    case FILE_TABS.PROJECTWISE:
      return user?.roles != null
        && (user.roles?.includes(ROLE_ID_ADMIN)
          || user.roles?.includes(ROLE_ID_PROJECTWISE));
    case SETTINGS_TABS.WEBHOOKS:
      return user?.roles != null
        && (user.roles?.includes(ROLE_ID_ADMIN)
          || user.roles?.includes(ROLE_ID_WEBHOOKS));
    default:
      return true;
  }
}

export function CombineUsageSummaries(summaries: UsageSummaryUI[]): UsageSummaryUI {
  const totalRuns = summaries.reduce((total, summary) => total + summary.TotalRunCount, 0);
  const totalChecks = summaries.reduce((total, summary) => total + summary.TotalCheckCount, 0);
  const totalRunSeconds = summaries.reduce((total, summary) => total + summary.TotalRunTimeSeconds, 0);
  const final = new UsageSummaryUI(totalRuns, totalChecks, totalRunSeconds);

  for (const summary of summaries) {
    summary.CustomerData.forEach(c => {
      let existing = final.CustomerData.findIndex(e => e.ID === c.ID);
      if (existing < 0) {
        final.CustomerData.push(c);
      } else {
        final.CustomerData[existing] = CombineDataItems(final.CustomerData[existing], c);
      }
    });
  }

  return final;
}

export function SetConfigurationCredentials(configs: ProjectWiseConfigurationUI[], credentials: ProjectWiseCredential[]): boolean {
  let hasUpdate = false;

  configs.forEach(config => {
    const credential = credentials.find(c => c.id === config.ApiConfiguration.credentialId);
    if (credential != null && config.CredentialKey !== credential.key) {
      config.CredentialKey = credential.key;
      hasUpdate = true;
    }
  });

  return hasUpdate;
}

export function GetUniqueProjectStructureData(allItems: (DirectoryUI | FileUI)[]): {
  projectId: string,
  directoryIds: string[]
}[] {
  const projectData: { projectId: string, directoryIds: string[] }[] = [];

  allItems.forEach(i => {
    const projectId = i.ProjectId;
    const directoryId = i instanceof DirectoryUI
      ? i.Id
      : i.DirectoryId;
    const existing = projectData.find(e => e.projectId === projectId);
    if (existing == null) {
      projectData.push({projectId: projectId, directoryIds: [directoryId]});
    } else {
      if (!existing.directoryIds.includes(directoryId)) {
        existing.directoryIds.push(directoryId);
      }
    }
  });

  return projectData;
}

function CombineDataItems(target: UsageDataItem, source: UsageDataItem): UsageDataItem {
  target.JobRuns += source.JobRuns;
  target.CheckCount += source.CheckCount;
  target.RunTimeSeconds += source.RunTimeSeconds;

  source.Children.forEach(c => {
    const existing = target.Children.findIndex(e => e.ID === c.ID);
    if (existing < 0) {
      target.Children.push(c);
    } else {
      target.Children[existing] = CombineDataItems(target.Children[existing], c);
    }
  });

  return {...target};
}
