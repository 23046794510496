////////////////////////////////////////////////////////////////////////////////
//
//
// (C) Copyright 2023 Autodesk, Inc. All rights reserved.
//
//                      ****  CONFIDENTIAL MATERIAL  ****
//
// The information contained herein is confidential, proprietary to
// Autodesk, Inc., and considered a trade secret.  Use of this information
// by anyone other than authorized employees of Autodesk, Inc. is granted
// only under a written nondisclosure agreement, expressly prescribing the
// the scope and manner of such use.
//
////////////////////////////////////////////////////////////////////////////////

export class ConvertFileSize{
    public static Convert(value: number): string{
        const kb = value / 1024;

        if (kb < 1000) {
            return kb < 10 ? `${kb.toFixed(1)} KB` : `${Math.round(kb)} KB`;
        }

        const mb = kb / 1024;

        if (mb < 1000) {
            return mb < 10 ? `${mb.toFixed(1)} MB` : `${Math.round(mb)} MB`;
        }

        const gb = mb / 1024;
        return gb < 10 ? `${gb.toFixed(1)} GB` : `${Math.round(gb)} GB`;
    }
}